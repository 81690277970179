import { Route, Routes } from "react-router-dom";
import { ROUTES } from "features/pages/Routes";
import { FilterHeader } from "./filter/FilterHeader";
import { QuestionnaireHeader } from "features/pages/quality-measurements/QuestionnaireHeader";

export const HeaderRouter = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.FLEET.LIST.buildPath({})}
        element={<FilterHeader path="fleet" />}
      />

      <Route
        path={ROUTES.QUALITY_MEASUREMENTS.path}
        element={<QuestionnaireHeader />}
      />

      <Route path="*" element={<></>} />
    </Routes>
  );
};
