import { QualityMeasurementsImageDeleteEndpoint } from "hooks/collectors/endpoints/quality-measurement/QualityMeasurementsImageDeleteEndpoint";

export namespace QualityMeasurementImageDeleteUtil {
  export const deleteImages = async (imageIds: string[] | undefined) => {
    if (!imageIds) {
      return;
    }

    const promises: ReturnType<
      typeof QualityMeasurementsImageDeleteEndpoint
    >[] = [];
    imageIds.forEach((imageId) =>
      promises.push(QualityMeasurementsImageDeleteEndpoint(imageId)),
    );

    await Promise.all(promises);
  };
}
