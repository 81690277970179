import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { EndpointStatusEnum } from "hooks/collectors/endpoints/EndpointHelper";
import {
  SupportStaffEndpoint,
  SupportStaffType,
} from "hooks/collectors/endpoints/support-staff/SupportStaffEndpoint";
import { Card } from "components/elements/card/Card";
import Phone from "assets/img/icons/svg/Phone.svg";
import styles from "./SupportStaff.module.scss";

const TIMOUT_1_MINUTE = 60000;

export const SupportStaff = () => {
  const { t } = useTranslation();
  const [supportStaff, setSupportStaff] = useState<SupportStaffType[]>([]);

  const fetchData = async () => {
    const { status, data } = await SupportStaffEndpoint();

    if (status === EndpointStatusEnum.SUCCESS && data != null) {
      setSupportStaff(data);
    }
  };

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      fetchData().catch(console.error);
    }, TIMOUT_1_MINUTE);

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  const formatMetersToKilometers = (meters: number) => {
    return Math.round(meters / 1000);
  };

  return (
    <>
      {supportStaff?.map((employee) => (
        <div key={employee.employeeId} className={styles.support_container}>
          <Card className={styles.support_staff_card}>
            <div className={styles.content}>
              <Button className={styles.call_button} type="primary">
                <img
                  src={Phone}
                  alt="Phone"
                  width={10}
                  className={styles.icon}
                />
                <a href={`tel:${employee.employee.phone2}`}>
                  {employee.employee.phone2}
                </a>
              </Button>
              <p className={styles.name}>
                <strong
                  className={
                    employee.currentJob ? styles.unavailable : styles.available
                  }
                >
                  {employee.employee.firstName} {employee.employee.lastName}
                </strong>
              </p>
              <div>
                <p>
                  {t("support_staff.duty")}: {employee.duty.dutyDescription}
                </p>
                {employee.currentJob ? (
                  <p>
                    {t("support_staff.task")}: {employee.currentJob}
                  </p>
                ) : null}

                <p>
                  {t("support_staff.closest_garage")}:{" "}
                  {employee.garage.description} (
                  {formatMetersToKilometers(employee.distance)}{" "}
                  {t("support_staff.km")})
                </p>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </>
  );
};
