import BackIcon from "assets/img/icons/svg/BackIcon.svg";
import BusIcon from "assets/img/icons/svg/BusIcon.svg";
import { Button } from "components/elements/button/Button";
import styles from "./MapButtons.module.scss";

interface IMapButtonsProps {
  firstButtonEvent?: () => void;
  secondButtonEvent?: () => void;
  firstButtonVisible?: boolean;
  secondButtonVisible?: boolean;
}

export const MapButtons = ({
  firstButtonEvent,
  secondButtonEvent,
  firstButtonVisible = false,
  secondButtonVisible = false,
}: IMapButtonsProps) => {
  return (
    <div className={styles.map_buttons}>
      {firstButtonVisible && (
        <Button className={styles.back} onClickEvent={firstButtonEvent}>
          <img src={BackIcon} alt="back" />
        </Button>
      )}

      {secondButtonVisible && (
        <Button className={styles.track_bus} onClickEvent={secondButtonEvent}>
          <img src={BusIcon} alt="bus" />
        </Button>
      )}
    </div>
  );
};
