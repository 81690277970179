import "./Loader.scss";

interface LoaderProps {
  color?: LoaderColor;
  size?: LoaderSize;
  centerInPage?: boolean;
}

enum LoaderSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

enum LoaderColor {
  Red = "red",
  Green = "green",
  Orange = "orange",
  Blue = "blue",
}

export const Loader = ({
  color = LoaderColor.Orange,
  size = LoaderSize.Small,
  centerInPage = true,
}: LoaderProps) => {
  return (
    <div
      className={`loader loader${size} loader${color} ${centerInPage ? "loader_center_in_page" : ""}`}
    />
  );
};
