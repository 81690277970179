import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/elements/button/Button";
import "./QuestionnaireComments.scss";
import { TextInput } from "features/pages/quality-measurements/items/questions/elements/TextInput";

interface QKCACommentsProps {
  onCancelClick: () => void;
  onSaveClick: (value: string) => void;
  visible: boolean;
  value: string;
  required?: boolean;
}

export const QuestionnaireComments = ({
  onCancelClick,
  onSaveClick,
  visible,
  value,
  required,
}: QKCACommentsProps) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState<string>(value);

  const onSubmit = (value: string) => {
    onSaveClick(value.trim());
  };

  const onChange = (value: string) => {
    setComment(value);
  };

  const onClearInput = async () => {
    setComment("");
    onSubmit("");
  };

  useEffect(() => {
    setComment(value ?? "");
  }, [value]);

  if (!visible) {
    return null;
  }

  return (
    <div className="qkca_comments">
      <div className="input">
        <TextInput
          type="TEXTAREA"
          placeholder={t("pages.quality_measurements.comment_placeholder")}
          onChange={onChange}
          value={comment}
          required={required}
          text={t("pages.quality_measurements.comment_placeholder")}
          onReset={onClearInput}
        />
      </div>

      <div className="buttons">
        <Button enabled={true} onClickEvent={onCancelClick} type="button">
          {t("functional.cancel")}
        </Button>
        <Button
          enabled={comment.length > 0}
          onClickEvent={() => onSubmit(comment)}
          type="button"
        >
          {t("functional.save")}
        </Button>
      </div>
    </div>
  );
};
