import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { Input, InputRef } from "antd";
import BackIcon from "assets/img/icons/svg/BackIcon.svg";
import FilterIcon from "assets/img/icons/svg/FilterIcon.svg";
import XMarkIcon from "assets/img/icons/svg/XMarkIcon.svg";
import { AutoSize } from "components/elements/autosize/AutoSize";
import { Button as CustomButton } from "components/elements/button/Button";
import { Header } from "features/layout/header/Header";
import { ROUTES } from "features/pages/Routes";
import { useUser } from "hooks/react-query/useUser";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { FilterElement } from "./FilterElement";
import styles from "./FilterHeader.module.scss";

export type AllowedFilterHeaderPaths = "fleet" | "map";

type FilterHeaderProps = {
  readonly path: AllowedFilterHeaderPaths;
  readonly floating?: boolean;
  readonly viewingOneVehicle?: boolean;
};

export const FilterHeader = ({
  path,
  viewingOneVehicle,
  floating = false,
}: FilterHeaderProps) => {
  const isFleet = path === "fleet";
  const [{ blockId, concessionId, vehicleId, vehicleModelId, origin }] =
    useTypedSearchParams(isFleet ? ROUTES.FLEET.$.LIST : ROUTES.MAP);
  const { title, elements } = useAppSelector(
    (state) => state.FilterHeaderReducer,
  );
  const { toggleFiltersListVisible } = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const filterRef = useRef<HTMLDivElement>(null);
  const filterContainerRef = useRef<HTMLDivElement>(null);

  const vehicleIdInputRef = useRef<InputRef>(null);
  const blockIdInputRef = useRef<InputRef>(null);
  const concessionIdRef = useRef<string>(concessionId || "");
  const vehicleModelIdRef = useRef<string>(vehicleModelId || "");

  const [vehicleIdInput, setVehicleIdInput] = useState<string>(vehicleId || "");
  const [blockIdInput, setBlockIdInput] = useState<string>(blockId || "");
  const [numberOfFilters, setNumberOfFilters] = useState<number>(0);
  const getUserConcessionIds = useUser().useGetUserConcessionIds();

  useEffect(() => {
    if (vehicleId === undefined && vehicleIdInput != "") {
      setVehicleIdInput("");
    }

    if (vehicleId && vehicleId != vehicleIdInput) {
      setVehicleIdInput(vehicleId);
    }
  }, [vehicleId]);

  const setSearchParams = (
    blockId?: string,
    concessionId?: string,
    vehicleId?: string,
    vehicleModelId?: string,
  ) => {
    const params = {
      blockId,
      // Using the spread operator to avoid sending the concessionId parameter if the user has only one concession, this is typically useful when somebody sends a link to a user with only one concession.
      ...(getUserConcessionIds.data &&
        getUserConcessionIds.data.length > 1 && { concessionId }),
      vehicleId,
      vehicleModelId,
      origin,
    };

    navigate(
      isFleet
        ? ROUTES.FLEET.LIST.buildPath({}, params)
        : ROUTES.MAP.buildPath({}, params),
    );
  };

  const handleOnBackClick = () => {
    navigate(-1);
  };

  const handleOnSearch = () => {
    toggleFiltersListVisible();
    setSearchParams(
      blockIdInputRef.current?.input?.value,
      concessionIdRef.current,
      vehicleIdInputRef.current?.input?.value,
      vehicleModelIdRef.current,
    );
  };

  const handleOnClearVehicleId = () => {
    setSearchParams(
      blockIdInput,
      concessionIdRef.current,
      undefined,
      vehicleModelIdRef.current,
    );
  };

  const handleOnClearBlockId = () => {
    setSearchParams(
      undefined,
      concessionIdRef.current,
      vehicleIdInput,
      vehicleModelIdRef.current,
    );
  };

  const handleOnChangeConcessionId = (newConcessionId: string) => {
    concessionIdRef.current = newConcessionId;
    handleOnSearch();
  };

  const handleOnChangeVehicleModelId = (newVehicleModelId: string) => {
    vehicleModelIdRef.current = newVehicleModelId;
    handleOnSearch();
  };

  const handleOnChangeBlockIdInput = (newBlockIdInput: string) => {
    setBlockIdInput(newBlockIdInput);
  };

  const handleOnChangeVehicleIdInput = (newVehicleIdInput: string) => {
    setVehicleIdInput(newVehicleIdInput);
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        !filterContainerRef.current?.classList.contains("filter_hidden") &&
        filterRef.current &&
        !filterRef.current.contains(event.target as HTMLElement)
      ) {
        handleOnSearch();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchend", handleClickOutside);
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("wheel", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("wheel", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let numberOfFilters = 0;

    if (blockId) {
      numberOfFilters++;
    }
    if (concessionId) {
      numberOfFilters++;
    }
    if (vehicleModelId) {
      numberOfFilters++;
    }
    if (vehicleId) {
      numberOfFilters++;
    }

    setNumberOfFilters(numberOfFilters);
  }, [blockId, concessionId, vehicleId, vehicleModelId]);

  if (viewingOneVehicle) {
    return;
  }

  return (
    <Header
      className={styles.filter_header}
      floating={floating}
      refObject={filterRef}
    >
      <div className={styles.content}>
        {elements.backArrow && (
          <>
            <CustomButton
              onClickEvent={handleOnBackClick}
              background="standard"
              className={`${styles.button} ${styles.button_left}`}
            >
              <img src={BackIcon} alt="Back" />
            </CustomButton>

            <div className={styles.spacer}></div>
          </>
        )}

        <div className={styles.container}>
          <div className={styles.title}>
            <AutoSize>{title}</AutoSize>
          </div>
        </div>

        <CustomButton
          onClickEvent={() => toggleFiltersListVisible()}
          background="standard"
          className={`${styles.button} ${styles.button_right} ${styles.filter_button} ${elements.filtersListVisible ? styles.filter_button_open : ""}`}
        >
          <span className={styles.filters_active}>{numberOfFilters}</span>

          <img src={FilterIcon} alt="Filter" />
        </CustomButton>
      </div>

      <div
        className={`${styles.filter} ${elements.filtersListVisible ? "" : `${styles.filter_hidden} filter_hidden`}`}
        ref={filterContainerRef}
      >
        <div className={styles.items}>
          <FilterElement
            concessionId={concessionId}
            vehicleModelId={vehicleModelId}
            onConcessionIdChange={handleOnChangeConcessionId}
            onVehicleModelIdChange={handleOnChangeVehicleModelId}
          />

          {elements.search.vehicleIdField && (
            <Input
              allowClear={{
                clearIcon: <img src={XMarkIcon} alt="X" />,
              }}
              className={styles.input}
              onChange={(event) =>
                handleOnChangeVehicleIdInput(event.target.value)
              }
              onClear={handleOnClearVehicleId}
              onKeyUp={(event) => event.key === "Enter" && handleOnSearch()}
              placeholder={t("filter_header.search_vehicle")}
              ref={vehicleIdInputRef}
              size="large"
              inputMode="numeric"
              value={vehicleIdInput}
            />
          )}

          {elements.search.blockIdField && (
            <Input
              allowClear={{
                clearIcon: <img src={XMarkIcon} alt="X" />,
              }}
              className={styles.input}
              onChange={(event) =>
                handleOnChangeBlockIdInput(event.target.value)
              }
              onClear={handleOnClearBlockId}
              onKeyUp={(event) => event.key === "Enter" && handleOnSearch()}
              placeholder={t("filter_header.search_block")}
              ref={blockIdInputRef}
              size="large"
              inputMode="numeric"
              value={blockIdInput}
            />
          )}
        </div>
      </div>
    </Header>
  );
};
