/* eslint-disable react/prop-types */
import { Severity, useToasts } from "@id/frontend.components";
import { QIconCamera, QIconComment } from "@id/frontend.styling";
import { AutoSize } from "components/elements/autosize/AutoSize";
import { Button } from "components/elements/button/Button";
import { Card } from "components/elements/card/Card";
import { Upload } from "components/upload/Upload";
import { ROUTES } from "features/pages/Routes";
import { Explanation } from "features/pages/quality-measurements/items/questions/elements/Explanation";
import { EndpointStatusEnum } from "hooks/collectors/endpoints/EndpointHelper";
import { QualityMeasurementsImageDeleteEndpoint } from "hooks/collectors/endpoints/quality-measurement/QualityMeasurementsImageDeleteEndpoint";
import { QualityMeasurementsImageUploadEndpoint } from "hooks/collectors/endpoints/quality-measurement/QualityMeasurementsImageUploadEndpoint";
import { QualityMeasurementsSaveAnswersEndpoint } from "hooks/collectors/endpoints/quality-measurement/QualityMeasurementsSaveAnswersEndpoint";
import { useQuestionnairesCollector } from "hooks/collectors/useQuestionnairesCollector";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { db } from "assets/database/Database";
import { QualityMeasurementConceptsTable } from "assets/database/tables/QualityMeasurementConceptsTable";
import { Loader } from "components/loader/Loader";
import {
  FormEvent,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import {
  IQuestion,
  IQuestionnaireAnswer,
  QUESTION_TYPE,
} from "state/reducers/QuestionnaireReducer";
import { v4 as uuidv4 } from "uuid";
import "./QuestionnaireQuestionsView.scss";
import { RadioButtons } from "./elements/RadioButtons";
import { TextInput } from "./elements/TextInput";
import { QuestionnaireComments } from "./items/QuestionnaireComments";
import { QuestionnaireImages } from "./items/QuestionnaireImages";
import { PageError } from "components/error/PageError";

interface IQuestionErrorDivrefs {
  questionId: string;
  ref: RefObject<HTMLButtonElement | undefined>;
}

interface QuestionnaireQuestionsViewProps {
  conceptAnswersListData: QualityMeasurementConceptsTable.IQualityMeasurementConcepts | null;
}

// TODO QID-7870: check if id exists in qdp.
export const QuestionnaireQuestionsView = ({
  conceptAnswersListData,
}: QuestionnaireQuestionsViewProps) => {
  const { id, conceptId } = useTypedParams(
    ROUTES.QUALITY_MEASUREMENTS.MEASUREMENT_ID.EDIT,
  );
  const [uploaderVisible, setUploaderVisible] = useState(false);
  const [uploaderQuestionId, setUploaderQuestionId] = useState("");
  const [commentQuestionId, setCommentQuestionId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    previousQuestionIndexes,
    questionIndex,
    questionList,
    previousButtonEnabled,
    nextButtonEnabled,
  } = useAppSelector((state) => state.QuestionnaireReducer.questionsView);
  const answerList = useAppSelector(
    (state) => state.QuestionnaireReducer.answerList,
  );
  const {
    setMenuVisible,
    SetQuestionnaireHeaderState,
    SetQuestionnaireViewState,
    SetQuestionnaireAnswersState,
    insertOrUpdateQuestionnaireAnswer,
    removeQuestionnaireAnswer,
  } = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const uploadButtonsRefs = useRef<IQuestionErrorDivrefs[]>([]);
  const commentButtonsRefs = useRef<IQuestionErrorDivrefs[]>([]);
  const {
    data: questionnaires,
    isLoading,
    error,
  } = useQuestionnairesCollector();
  const { addToast } = useToasts();

  const currentConceptId = useMemo(() => {
    if (!conceptId) {
      return uuidv4();
    }

    return conceptId;
  }, [conceptId, id]);

  const currentQuestionnaire = useMemo(() => {
    return (
      questionnaires?.find(
        (questionnaire) => questionnaire.questionnaireId == id,
      ) ?? null
    );
  }, [questionnaires]);

  const questions = useMemo(
    () => questionList?.questions ?? [],
    [questionList],
  );
  const secondButtonEnabled =
    questions.length > questionIndex && nextButtonEnabled;
  const nextQuestionIndex = useMemo(() => {
    return questions.findIndex((question, index) => {
      return (
        index > questionIndex &&
        ((question.questionId !== questions[questionIndex].questionId &&
          question.type === QUESTION_TYPE.DESCRIPTION) ||
          (question.type !== QUESTION_TYPE.DESCRIPTION &&
            question.parentQuestionId === undefined &&
            question.parentQuestionOptionIds === undefined))
      );
    });
  }, [questionIndex, questions]);

  const onPreviousClick = () => {
    if (questionIndex === 0) {
      // TODO QID-7869: Add a confirmation dialog.
      return navigate(ROUTES.QUALITY_MEASUREMENTS.buildPath({}));
    }

    const currentQuestion = questions[questionIndex];
    if (currentQuestion === undefined) {
      return;
    }

    const newPreviousQuestionIndexes = previousQuestionIndexes.filter(
      (index) => index !== questionIndex,
    );
    const newQuestionIndex =
      newPreviousQuestionIndexes[newPreviousQuestionIndexes.length - 1] ?? 0;
    SetQuestionnaireViewState({
      previousQuestionIndexes: newPreviousQuestionIndexes,
      questionIndex: newQuestionIndex,
    });
  };

  const validateQuestion = (question: IQuestion) => {
    const currentQuestion = questions[questionIndex];

    if (question.type === QUESTION_TYPE.DESCRIPTION) {
      return { validatedImages: true, validatedOptionIds: true };
    }
    if (question.parentQuestionId !== currentQuestion.questionId) {
      return { validatedImages: true, validatedOptionIds: true };
    }

    const currentAnswer = answerList.answers.find(
      (answer) => answer.questionId === question.questionId,
    );

    const validatedImages = (() => {
      if (question.type === QUESTION_TYPE.RADIO) {
        return (
          (!question.imageRequired ||
            (question.imageRequired &&
              currentAnswer?.imageIds &&
              currentAnswer?.imageIds.length > 0)) ??
          false
        );
      }

      if (question.type === QUESTION_TYPE.PICTURE) {
        return (
          (!question.required ||
            (question.required &&
              currentAnswer?.imageIds &&
              currentAnswer?.imageIds.length > 0)) ??
          false
        );
      }

      return true;
    })();

    const validatedOptionIds = (() => {
      if (question.type === QUESTION_TYPE.RADIO) {
        return (
          (!question.required ||
            (question.required &&
              currentAnswer?.optionIds &&
              currentAnswer?.optionIds.length === 1)) ??
          false
        );
      }

      return true;
    })();

    const validatedComments = (() => {
      if (
        question.type === QUESTION_TYPE.RADIO ||
        question.type === QUESTION_TYPE.PICTURE
      ) {
        return (
          (!question.commentRequired ||
            (question.commentRequired &&
              currentAnswer?.explanation &&
              currentAnswer?.explanation !== "")) ??
          false
        );
      }

      return true;
    })();

    return {
      validatedImages: validatedImages,
      validatedOptionIds: validatedOptionIds,
      validatedComments: validatedComments,
    };
  };

  const onNextClick = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentQuestion = questions[questionIndex];
    const currentQuestionWithChildren = questions.filter((question) => {
      return (
        (question.type !== QUESTION_TYPE.DESCRIPTION &&
          question.parentQuestionId === currentQuestion.questionId) ||
        (question.type !== QUESTION_TYPE.DESCRIPTION &&
          question.questionId === currentQuestion.questionId)
      );
    });

    currentQuestionWithChildren.forEach((child) => {
      const validated = validateQuestion(child);

      const { uploadButtonRef, commentButtonRef } = resetUploaderButtonsState({
        question: child,
        uploadButton: true,
        commentButton: true,
      });

      if (!validated.validatedImages) {
        uploadButtonRef?.ref.current?.classList.add("error");
        uploadButtonRef?.ref.current?.classList.add("flash");

        setTimeout(() => {
          uploadButtonRef?.ref.current?.classList.remove("flash");
        }, 2000);
      }

      if (!validated.validatedComments) {
        commentButtonRef?.ref.current?.classList.add("error");
        commentButtonRef?.ref.current?.classList.add("flash");

        setTimeout(() => {
          commentButtonRef?.ref.current?.classList.remove("flash");
        }, 2000);
      }
    });

    const allQuestionsValidated = currentQuestionWithChildren.every((child) => {
      const validated = validateQuestion(child);

      return (
        validated.validatedImages &&
        validated.validatedComments &&
        validated.validatedOptionIds
      );
    });

    // TODO: Notify the user that the question is not validated.
    if (!allQuestionsValidated && currentQuestionWithChildren.length > 0) {
      return;
    }

    if (nextQuestionIndex === -1) {
      if (answerList.answers.length === 0) {
        return;
      }
      if (answerList.questionnaireId === "") {
        return;
      }

      SetQuestionnaireViewState({
        nextButtonEnabled: false,
      });

      setIsSubmitting(true);
      const response = await QualityMeasurementsSaveAnswersEndpoint(answerList);

      if (response.status !== EndpointStatusEnum.SUCCESS) {
        SetQuestionnaireViewState({
          nextButtonEnabled: true,
        });
        return;
      }

      addToast(t("pages.quality_measurements.saved_success"), Severity.Success);
      await db.qualityMeasurementConceptsTable.delete(currentConceptId);
      setIsSubmitting(false);
      navigate(ROUTES.QUALITY_MEASUREMENTS.buildPath({}));
    }

    const currentQuestionAnswer = answerList.answers.find(
      (answer) => answer.questionId === questions[questionIndex].questionId,
    )?.optionIds;
    const childBasedOnParentOptionIds = questions.find((child, index) => {
      return (
        index > questionIndex &&
        child.type !== QUESTION_TYPE.DESCRIPTION &&
        child.questionId === questions[questionIndex].questionId &&
        child.parentQuestionOptionIds !== undefined &&
        child.parentQuestionOptionIds.find((optionId) => {
          return (
            currentQuestionAnswer?.find((answerOptionId) => {
              return answerOptionId === optionId;
            }) !== undefined
          );
        }) !== undefined
      );
    });

    if (childBasedOnParentOptionIds) {
      const childBasedOnParentOptionIdsIndex = questions.findIndex(
        (question) =>
          question.questionId === childBasedOnParentOptionIds?.questionId,
      );
      SetQuestionnaireViewState({
        questionIndex: childBasedOnParentOptionIdsIndex,
      });

      SetQuestionnaireViewState({
        previousQuestionIndexes: [...previousQuestionIndexes, questionIndex],
      });
      return;
    }

    // This should never happen, but just in case.
    if (nextQuestionIndex === -1) {
      return;
    }

    SetQuestionnaireViewState({
      previousQuestionIndexes: [...previousQuestionIndexes, questionIndex],
      questionIndex: nextQuestionIndex,
    });
  };

  const updateInputValue = ({
    questionId,
    value,
  }: {
    questionId: string;
    value: string;
  }) => {
    const question = questions.find(
      (question) => question.questionId === questionId,
    );
    const questionIndex = questions.findIndex(
      (question) => question.questionId === questionId,
    );
    if (!question || questionIndex === -1) {
      return;
    }

    const currentAnswer = answerList.answers.find(
      (answer) => answer.questionId === questionId,
    );

    insertOrUpdateQuestionnaireAnswer({
      questionId: question.questionId,
      optionIds: currentAnswer?.optionIds ?? [],
      ...(currentAnswer?.optionIds && {
        optionIds: currentAnswer?.optionIds,
      }),
      ...(value && { explanation: value }),
      ...(currentAnswer?.imageIds && {
        imageIds: currentAnswer?.imageIds,
      }),
      order: questionIndex,
    });
  };

  const updateRadioInputValue = ({
    questionId,
    optionId,
  }: {
    questionId: string;
    optionId: string;
  }) => {
    const question = questions.find(
      (question) => question.questionId === questionId,
    );
    const questionIndex = questions.findIndex(
      (question) => question.questionId === questionId,
    );
    if (!question || questionIndex === -1) {
      return;
    }

    const currentAnswer = answerList.answers.find(
      (answer) => answer.questionId === questionId,
    );

    insertOrUpdateQuestionnaireAnswer({
      questionId: question.questionId,
      optionIds: [optionId],
      ...(currentAnswer?.imageIds && {
        imageIds: currentAnswer?.imageIds,
      }),
      order: questionIndex,
    });
  };

  const updateImageInputValue = async ({
    questionId,
    images,
  }: {
    questionId: string;
    images: File[];
  }) => {
    const mergedImageIds: string[] = [];
    const question = questions.find(
      (question) => question.questionId === questionId,
    );
    const questionIndex = questions.findIndex(
      (question) => question.questionId === questionId,
    );
    if (!question || questionIndex === -1) {
      return;
    }

    const imagesFailedCount = images.map((image) => {
      return {
        image: image,
        retryCount: 0,
      };
    });

    const uploadImage = async (image: File): Promise<void | undefined> => {
      const formData = new FormData();
      formData.append("file", image);

      const { status, data } =
        await QualityMeasurementsImageUploadEndpoint(formData);

      if (status !== EndpointStatusEnum.SUCCESS) {
        const failedCount = imagesFailedCount.find(
          (imageFailedCount) => imageFailedCount.image === image,
        );
        if (!failedCount) {
          return;
        }

        if (failedCount.retryCount < 3) {
          failedCount.retryCount++;
          return await uploadImage(image);
        }

        // TODO: Notify user that the image failed to upload.
        return console.error(
          `error uploading image, retries: ${failedCount.retryCount}`,
        );
      }

      if (!data) {
        return console.error("error uploading image");
      }
      mergedImageIds.push(data.imageId);
    };

    await Promise.all(images.map(async (image) => await uploadImage(image)));

    const currentAnswer = answerList.answers.find(
      (answer) => answer.questionId === questionId,
    );
    const currentImageIds = currentAnswer?.imageIds ?? [];

    mergedImageIds.push(...currentImageIds);

    insertOrUpdateQuestionnaireAnswer({
      questionId: question.questionId,
      ...(currentAnswer?.optionIds && {
        optionIds: currentAnswer?.optionIds,
      }),
      ...(mergedImageIds.length > 0 && { imageIds: mergedImageIds }),
      order: questionIndex,
    });

    resetUploaderButtonsState({
      question,
      uploadButton: true,
      commentButton: false,
    });
  };

  const removeImage = async ({
    questionId,
    imageId,
  }: {
    questionId: string;
    imageId: string;
  }) => {
    const question = questions.find(
      (question) => question.questionId === questionId,
    );
    const questionIndex = questions.findIndex(
      (question) => question.questionId === questionId,
    );

    if (!question || questionIndex === -1) {
      return;
    }

    const currentAnswer = answerList.answers.find(
      (answer) => answer.questionId === questionId,
    );
    const newImageIds =
      currentAnswer?.imageIds?.filter((image) => image !== imageId) ?? [];

    if (imageId) {
      QualityMeasurementsImageDeleteEndpoint(imageId);
    }

    insertOrUpdateQuestionnaireAnswer({
      questionId: question.questionId,
      optionIds: currentAnswer?.optionIds ?? [],
      imageIds: newImageIds,
      order: questionIndex,
    });
  };

  const getDescriptionComponent = (question: IQuestion) => {
    return (
      <div className="description" style={{ marginBottom: "1rem" }}>
        <AutoSize mode="box">
          {question.text}
          {question.explanation != undefined ? (
            <Explanation text={question.explanation} />
          ) : null}
        </AutoSize>
      </div>
    );
  };

  const getUploadedImages = (question: IQuestion) => {
    const currentAnswer = answerList.answers.find(
      (answer) => answer.questionId === question.questionId,
    );
    if (!currentAnswer?.imageIds || currentAnswer.imageIds.length === 0) {
      return null;
    }

    return (
      <QuestionnaireImages
        question={question}
        currentAnswer={currentAnswer}
        removeImage={removeImage}
      />
    );
  };

  const resetUploaderButtonsState = ({
    question,
    uploadButton,
    commentButton,
  }: {
    question: IQuestion;
    uploadButton: boolean;
    commentButton: boolean;
  }) => {
    const uploadButtonRef = uploadButtonsRefs.current.find(
      (ref) => ref.questionId === question.questionId,
    );
    const commentButtonRef = commentButtonsRefs.current.find(
      (ref) => ref.questionId === question.questionId,
    );

    if (uploadButton) {
      uploadButtonRef?.ref.current?.classList.remove("error");
      uploadButtonRef?.ref.current?.classList.remove("flash");
    }

    if (commentButton) {
      commentButtonRef?.ref.current?.classList.remove("error");
      commentButtonRef?.ref.current?.classList.remove("flash");
    }

    return {
      uploadButtonRef,
      commentButtonRef,
    };
  };

  const getUploaderButtons = (question: IQuestion) => {
    return (
      <div className="buttons">
        <button
          type="button"
          onClick={() => {
            setUploaderVisible(true);
            setUploaderQuestionId(question.questionId);
            resetUploaderButtonsState({
              question,
              uploadButton: true,
              commentButton: false,
            });
          }}
          className="upload_button"
          ref={(el) => {
            uploadButtonsRefs.current = uploadButtonsRefs.current.filter(
              (ref) => {
                return ref.questionId !== question.questionId;
              },
            );

            return uploadButtonsRefs.current.push({
              questionId: question.questionId,
              ref: {
                current: el,
              },
            });
          }}
        >
          <QIconCamera />
        </button>
        <button
          type="button"
          onClick={() => {
            resetUploaderButtonsState({
              question,
              uploadButton: false,
              commentButton: true,
            });
            if (commentQuestionId === question.questionId) {
              return setCommentQuestionId("");
            }
            setCommentQuestionId(question.questionId);
          }}
          disabled={false}
          className="comment_button"
          ref={(el) => {
            commentButtonsRefs.current = commentButtonsRefs.current.filter(
              (ref) => {
                return ref.questionId !== question.questionId;
              },
            );

            return commentButtonsRefs.current.push({
              questionId: question.questionId,
              ref: {
                current: el,
              },
            });
          }}
        >
          <QIconComment />
        </button>
      </div>
    );
  };

  const onCommentSave = (comment: string) => {
    const questionIndex = questions.findIndex(
      (question) => question.questionId === commentQuestionId,
    );
    if (questionIndex === -1) {
      return;
    }

    insertOrUpdateQuestionnaireAnswer({
      questionId: commentQuestionId,
      explanation: comment,
      order: questionIndex,
    });

    setCommentQuestionId("");
  };

  const CommentsInputComponent = ({ question }: { question: IQuestion }) => {
    const comment = answerList.answers.find(
      (answer) => answer.questionId === question.questionId,
    )?.explanation;

    return (
      <QuestionnaireComments
        onCancelClick={() => {
          setCommentQuestionId("");
        }}
        onSaveClick={onCommentSave}
        visible={question.questionId === commentQuestionId}
        value={comment ?? ""}
        required={
          question.type === QUESTION_TYPE.DESCRIPTION
            ? false
            : question.required
        }
      />
    );
  };

  const CommentsMessageComponent = ({ question }: { question: IQuestion }) => {
    const comment = answerList.answers.find(
      (answer) => answer.questionId === question.questionId,
    )?.explanation;

    if (!comment || commentQuestionId === question.questionId) {
      return null;
    }

    return (
      <div className="comment">
        <div className="title">
          {t("pages.quality_measurements.comment_title")}:
        </div>
        <div className="comment_text">
          <AutoSize mode="box">{comment}</AutoSize>
        </div>
      </div>
    );
  };

  const getQuestionComponentByInputType = (question: IQuestion) => {
    const currentAnswer = answerList.answers.find(
      (answer) => answer.questionId === question.questionId,
    );

    if (question.type === QUESTION_TYPE.RADIO) {
      return (
        <Card className="radio_card">
          <div className="header">
            <div className="title">{question.text}</div>
            {getUploaderButtons(question)}
          </div>
          {question.explanation != undefined ? (
            <Explanation text={question.explanation} />
          ) : null}
          <CommentsInputComponent question={question} />
          <CommentsMessageComponent question={question} />
          {question.questionId !== commentQuestionId && (
            <>
              {getUploadedImages(question)}
              <RadioButtons
                name={`questionnaire-question-${question.questionId}`}
                options={question.options.map((option) => {
                  return {
                    label: option.text,
                    value: option.optionId,
                  };
                })}
                onChange={(option_id) => {
                  updateRadioInputValue({
                    questionId: question.questionId,
                    optionId: option_id,
                  });
                }}
                required={question.required}
                value={currentAnswer?.optionIds?.[0] ?? ""}
              />
            </>
          )}
        </Card>
      );
    }

    if (question.type === QUESTION_TYPE.DESCRIPTION) {
      return getDescriptionComponent(question);
    }

    if (question.type === QUESTION_TYPE.PICTURE) {
      return (
        <Card className="radio_card">
          <div className="header">
            <div className="title">{question.text}</div>
            {getUploaderButtons(question)}
          </div>
          {question.explanation != undefined ? (
            <Explanation text={question.explanation} />
          ) : null}
          <CommentsInputComponent question={question} />
          <CommentsMessageComponent question={question} />
          {getUploadedImages(question)}
        </Card>
      );
    }

    return (
      <div className="input">
        {question.type !== QUESTION_TYPE.INPUT && getUploadedImages(question)}
        <TextInput
          type={question.type}
          text={question.text}
          placeholder={question.inputPlaceholder ?? ""}
          onChange={(value) =>
            updateInputValue({
              questionId: question.questionId,
              value,
            })
          }
          onReset={() => removeQuestionnaireAnswer(question.questionId)}
          value={currentAnswer?.explanation ?? ""}
          min={question.inputLengths?.min}
          max={question.inputLengths?.max}
          required={question.required}
          regexFilter={
            question.zodRegex ? new RegExp(question.zodRegex, "g") : undefined
          }
          explanation={question.explanation}
        />
      </div>
    );
  };

  const getQuestionsComponents = () => {
    const question = questions[questionIndex];

    uploadButtonsRefs.current = [];
    commentButtonsRefs.current = [];

    if (!question) {
      return null;
    }

    const children = questions.filter((child) => {
      return (
        child.type !== "DESCRIPTION" &&
        question.questionId === child?.parentQuestionId &&
        child.parentQuestionOptionIds === undefined
      );
    });

    const childrenComponents = children.map((child) => {
      return (
        <div key={child.questionId} className="child">
          {getQuestionComponentByInputType(child)}
        </div>
      );
    });

    return (
      <Card className="questions">
        {getQuestionComponentByInputType(question)}
        {childrenComponents}
      </Card>
    );
  };

  // Resets the questions state when the component is mounted, so the user can start from the beginning.
  useEffect(() => {
    SetQuestionnaireViewState({
      previousQuestionIndexes:
        conceptAnswersListData?.previousQuestionIndexes ?? [],
      nextButtonEnabled: true,
      questionIndex: conceptAnswersListData?.questionIndex ?? 0,
      questionList: currentQuestionnaire ?? null,
    });

    SetQuestionnaireAnswersState({
      questionnaireId: conceptAnswersListData?.answerList.questionnaireId ?? "",
      version: conceptAnswersListData?.answerList.version ?? -1,
      answers: conceptAnswersListData?.answerList.answers ?? [],
    });

    SetQuestionnaireHeaderState({
      showBackArrow: false,
    });

    setMenuVisible(false);

    return () => {
      SetQuestionnaireHeaderState({
        showBackArrow: true,
      });
      setMenuVisible(true);
    };
  }, [currentQuestionnaire, conceptAnswersListData]);

  useEffect(() => {
    const answerState: Partial<IQuestionnaireAnswer> = {};

    if (id !== "") {
      answerState.questionnaireId = id;
    }
    if (currentQuestionnaire?.version) {
      answerState.version = currentQuestionnaire.version;
    }

    SetQuestionnaireAnswersState(answerState);
  }, [id, currentQuestionnaire]);

  useEffect(() => {
    if (!currentConceptId || !answerList || isSubmitting) {
      return;
    }

    // Only make a 'concept' if at least 2 questions have been answered, otherwise there will be alot of empty concepts.
    if (answerList.answers.length < 2) {
      return;
    }

    db.qualityMeasurementConceptsTable.put({
      conceptId: currentConceptId,
      previousQuestionIndexes: previousQuestionIndexes,
      questionIndex: questionIndex,
      answerList: answerList,
      timestamp: new Date().toISOString(),
    });
  }, [currentConceptId, answerList, questionIndex, isSubmitting]);

  if (isLoading || !questions) {
    return <Loader />;
  }
  if (error) {
    return <PageError onButtonClick={() => window.location.reload()} />;
  }

  return (
    <div className="quality_measurements_questions">
      <form onSubmit={onNextClick}>
        <div className="questions_list">{getQuestionsComponents()}</div>
        {questions.length > 0 ? (
          <div className="buttons">
            <Button
              enabled={previousButtonEnabled}
              onClickEvent={onPreviousClick}
              type="button"
            >
              {questionIndex === 0
                ? t("functional.cancel")
                : t("functional.previous")}
            </Button>
            <Button enabled={secondButtonEnabled} type="submit">
              {nextQuestionIndex === -1 ||
              questionIndex === questions.length - 1
                ? t("functional.save")
                : t("functional.next")}
            </Button>
          </div>
        ) : null}
      </form>
      <Upload
        identifier={uploaderQuestionId}
        visible={uploaderVisible}
        setVisibility={setUploaderVisible}
        onUploaded={({ id, images }) => {
          if (!uploaderVisible) {
            return;
          }

          setUploaderVisible(false);
          updateImageInputValue({ questionId: id, images });
        }}
      />
    </div>
  );
};
