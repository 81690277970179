import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { textSort } from "helpers/SortUtils";
import { useConcession, useUser, useVehicle } from "hooks/react-query";
import { Concession, VehicleModel } from "hooks/react-query/resources";
import { FilterButtonDropdown } from "./dropdown/FilterButtonDropdown";

export interface IListItems {
  key: string;
  text: string;
  value: string;
  active: boolean;
}

type FilterElementProps = {
  concessionId?: string;
  vehicleModelId?: string;
  onConcessionIdChange: (concessionId: string) => void;
  onVehicleModelIdChange: (vehicleModelId: string) => void;
};

export const FilterElement = ({
  concessionId,
  vehicleModelId,
  onConcessionIdChange,
  onVehicleModelIdChange,
}: FilterElementProps) => {
  const { t } = useTranslation();

  const [vehicleModelListItems, setVehicleModelListItems] = useState<
    IListItems[]
  >([]);
  const [concessionListItems, setConcessionListItems] = useState<IListItems[]>(
    [],
  );

  const [concessionInput, setConcessionInput] = useState<Concession>();
  const [vehicleModelInput, setVehicleModelInput] = useState<VehicleModel>();

  const getConcessions = useConcession().useGetAll();
  const getUserConcessionIds = useUser().useGetUserConcessionIds();
  const getVehicleModels = useVehicle().useGetAllVehicleModelsByConcessionIds(
    getUserConcessionIds?.data,
  );

  const onVehicleModelListItemClick = (id: string) => {
    const isCurrentSelectedVehicleModel = id === String(vehicleModelInput?.id);
    const selectedVehicleModel = getVehicleModels.data?.find(
      (vehicleModel) => String(vehicleModel.id) === id,
    );
    if (!selectedVehicleModel) {
      return;
    }

    setVehicleModelInput(
      !isCurrentSelectedVehicleModel ? selectedVehicleModel : undefined,
    );
    onVehicleModelIdChange(
      !isCurrentSelectedVehicleModel ? String(selectedVehicleModel.id) : "",
    );

    // Sets the active state of the list items, based on the id of the clicked item
    setVehicleModelListItems((previous) =>
      previous.map((item) => {
        return {
          ...item,
          active: item.key === id && !isCurrentSelectedVehicleModel,
        };
      }),
    );
  };

  const onConcessionListItemClick = (id: string) => {
    const isCurrentSelectedConcession = id === String(concessionInput?.id);
    const selectedConcession = getConcessions.data?.find(
      (concession) => String(concession.id) === id,
    );

    setConcessionInput(
      !isCurrentSelectedConcession ? selectedConcession : undefined,
    );
    onConcessionIdChange(!isCurrentSelectedConcession ? id : "");

    setConcessionListItems((previous) =>
      previous.map((item) => {
        return {
          ...item,
          active: item.key === id && !isCurrentSelectedConcession,
        };
      }),
    );
  };

  useEffect(() => {
    if (!getVehicleModels.data) {
      return;
    }

    const vehicleModels = [
      ...new Map(
        getVehicleModels.data.map((vehicleModel) => [
          vehicleModel.id,
          vehicleModel,
        ]),
      ).values(),
    ];

    setVehicleModelListItems(
      vehicleModels
        .toSorted((a, b) =>
          textSort(`${a.make} ${a.model}`, `${b.make} ${b.model}`),
        )
        .map((item) => {
          return {
            key: `${item.concession}-${item.id}`,
            text: `${item.make} ${item.model}`,
            value: String(item.id),
            active: String(item.id) === vehicleModelId,
          };
        }),
    );

    setVehicleModelInput(
      vehicleModels.find(
        (vehicleModel) => String(vehicleModel.id) === vehicleModelId,
      ),
    );
  }, [getVehicleModels.data, vehicleModelId]);

  useEffect(() => {
    if (!getConcessions.data || !getUserConcessionIds.data) {
      return;
    }

    const allUserConcessions = getConcessions.data?.filter((concession) =>
      getUserConcessionIds.data?.includes(concession.id),
    );

    setConcessionListItems(
      allUserConcessions.map((item) => ({
        key: String(item.id),
        text: item.shortCode,
        value: String(item.id),
        active: !!concessionId && String(item.id) === concessionId,
      })),
    );

    setConcessionInput(
      allUserConcessions.find(
        (concession) => String(concession.id) === concessionId,
      ),
    );
  }, [getConcessions.data, getUserConcessionIds.data, concessionId]);

  return (
    <>
      {concessionListItems && concessionListItems.length > 1 && (
        <FilterButtonDropdown
          title={t("filter_header.concession")}
          contentText={concessionInput?.name || "-"}
          onListItemClickEvent={onConcessionListItemClick}
          listItems={concessionListItems}
        />
      )}
      <FilterButtonDropdown
        title={t("filter_header.model")}
        contentText={
          vehicleModelInput
            ? `${vehicleModelInput.make} ${vehicleModelInput.model}`
            : "-"
        }
        onListItemClickEvent={onVehicleModelListItemClick}
        listItems={vehicleModelListItems}
      />
    </>
  );
};
