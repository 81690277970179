import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";
import { ScheduledRoute } from "./ScheduledRoute";

type ScheduledRouteModalProps = {
  readonly onCancel: () => void;
  readonly vehicleId: string;
};

export const ScheduledRouteModal = ({
  onCancel,
  vehicleId,
}: ScheduledRouteModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const { t } = useTranslation();

  const handleModalAction = () => {
    setIsModalOpen(false);
    onCancel();
  };

  return (
    <Modal
      cancelButtonProps={{ type: "primary" }}
      cancelText={t("functional.cancel")}
      centered={true}
      closable={true}
      footer={[
        <Button key="back" type="primary" onClick={handleModalAction}>
          {t("functional.back")}
        </Button>,
      ]}
      maskClosable={true}
      onCancel={handleModalAction}
      open={isModalOpen}
      title={t("scheduled_route.title")}
    >
      <ScheduledRoute vehicleId={vehicleId} />
    </Modal>
  );
};
