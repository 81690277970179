import { ReactNode, RefObject } from "react";
import styles from "./ButtonWithTimestamp.module.scss";

interface IButtonWithTimestampProps {
  className?: string;
  disabled: boolean;
  refItem?: RefObject<HTMLButtonElement>;
  onClickEvent?: () => void;
  timestampValue?: string;
  timestampBackgroundColor?: "green" | "normal";
  children?: ReactNode;
}

export const ButtonWithTimestamp = ({
  className,
  disabled = true,
  refItem,
  timestampValue,
  timestampBackgroundColor = "normal",
  onClickEvent,
  children,
}: IButtonWithTimestampProps) => {
  return (
    <button
      className={`${styles.button_with_timestamp} ${className}`}
      onClick={onClickEvent}
      ref={refItem}
      disabled={disabled}
    >
      {children}
      {timestampValue && (
        <div className={`${styles.timestamp} ${timestampBackgroundColor}`}>
          {timestampValue}
        </div>
      )}
    </button>
  );
};
