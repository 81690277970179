import QbuzzIcon from "assets/img/icons/svg/QbuzzIcon.svg";
import QontrolIcon from "assets/img/icons/svg/QontrolIcon.svg";
import { Loader } from "components/loader/Loader";
import styles from "./Splashscreen.module.scss";

interface ISplashscreenProps {
  isLoading?: boolean;
  children?: React.ReactNode;
}

export const Splashscreen = ({
  isLoading = true,
  children,
}: ISplashscreenProps) => {
  return (
    <div className={styles.loaderLayout}>
      <div className={styles.icons}>
        <img className={styles.qbuzz_icon} src={QbuzzIcon} width="120" />
        <img className={styles.qontrol_icon} src={QontrolIcon} width="90" />
      </div>

      <div
        className={`${styles.content} ${isLoading ? styles.top : styles.bottom}`}
      >
        {isLoading ? <Loader /> : children}
      </div>
    </div>
  );
};
