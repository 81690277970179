import { number, route, string } from "react-router-typesafe-routes/dom";

/**
 * The routes of the APP.
 * It is possible to use search parameters in the routes or hash parameters.
 */
export const ROUTES = {
  DEFAULT: route("", {}),
  FLEET: route(
    "fleet/*",
    {},
    {
      LIST: route("list", {
        searchParams: {
          blockId: string(),
          concessionId: string(),
          vehicleId: string(),
          vehicleModelId: string(),
          origin: string(),
        },
      }),
    },
  ),
  MAP: route("map", {
    searchParams: {
      blockId: string(),
      concessionId: string(),
      vehicleId: string(),
      vehicleModelId: string(),
      origin: string(),
    },
  }),
  QUALITY_MEASUREMENTS: route(
    "quality-measurements/*",
    {},
    {
      MEASUREMENT_ID: route(
        "measurement/:id",
        {
          searchParams: {
            id: number(),
          },
        },
        {
          EDIT: route("edit/:conceptId?", {
            searchParams: {
              conceptId: number(),
            },
          }),
        },
      ),
    },
  ),
  AUTHENTICATION: route(
    "authentication",
    {},
    {
      LOGIN: route("login", {}),
      ALLOCATE_DB: route("allocate", {}),
    },
  ),
  CONTACT: route("contact", {}),
};
