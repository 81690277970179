import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "features/pages/Routes";
import {
  AllocationStatusEnum,
  useAllocateDatabase,
} from "hooks/useAllocateDatabase";
import { Loader } from "components/loader/Loader";
import { Debug } from "helpers/Debug";
import { PageError } from "components/error/PageError";
import styles from "./AllocateDatabase.module.scss";
import { t } from "i18next";
import { useAppSelector } from "hooks/useRedux";
import { db } from "../../../assets/database/Database";

export const AllocateDatabase = () => {
  const { isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();
  const { allocationStatus } = useAllocateDatabase();
  const allocatePath = ROUTES.AUTHENTICATION.ALLOCATE_DB.path;
  const { returnTo } = useAppSelector((state) => state.Auth0Reducer);

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate(ROUTES.AUTHENTICATION.LOGIN.path);
    }

    if (
      isAuthenticated &&
      allocationStatus === AllocationStatusEnum.CACHED &&
      location.pathname === allocatePath
    ) {
      if (returnTo) {
        navigate(returnTo);
      } else {
        navigate(ROUTES.DEFAULT.path);
      }
      Debug.log("Allocating skipped, using cached database.");
    }

    if (
      isAuthenticated &&
      allocationStatus === AllocationStatusEnum.ALLOCATED &&
      location.pathname === ROUTES.AUTHENTICATION.ALLOCATE_DB.path
    ) {
      if (returnTo) {
        navigate(returnTo);
      } else {
        navigate(ROUTES.DEFAULT.path);
      }
      Debug.log("Allocating done, fetched data from api.");
    }
  }, [allocationStatus, returnTo]);

  const reloadAndForceLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    db.manuallyDeleteDatabase();
    setTimeout(() => window.location.reload(), 1000);
  };

  return allocationStatus === AllocationStatusEnum.ALLOCATING_FAILED ? (
    <PageError onButtonClick={reloadAndForceLogout} />
  ) : (
    <>
      <Loader />
      <span className={styles.allocate_db_loading_text}>
        {getLoadingText(allocationStatus)}
      </span>
    </>
  );
};

const getLoadingText = (allocationStatus: AllocationStatusEnum) => {
  const totalSteps = 4;
  const startText = t("functional.app_load_status.starting");
  const prepareText = t("functional.app_load_status.preparing");
  const loadingText = t("functional.app_load_status.loading");
  const readyText = t("functional.app_load_status.ready");

  switch (allocationStatus) {
    case AllocationStatusEnum.IDLE:
      return `${startText}... (1/${totalSteps})`;
    case AllocationStatusEnum.STARTED:
      return `${prepareText}... (2/${totalSteps})`;
    case AllocationStatusEnum.LOADING:
      return `${loadingText}... (3/${totalSteps})`;
    case AllocationStatusEnum.CACHED:
    case AllocationStatusEnum.ALLOCATED:
      return `${readyText}... (4/${totalSteps})`;
  }
};
