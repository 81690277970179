import { useEffect, useState } from "react";
import "./RadioButtons.scss";

interface RadioButtonsProps {
  name: string;
  options: { label: string; value: string }[];
  onChange: (value: string) => void;
  value: string;
  required?: boolean;
  disabled?: boolean;
}

export const RadioButtons = ({
  name,
  options,
  onChange,
  value,
  required = true,
  disabled = false,
}: RadioButtonsProps) => {
  const [inputValue, setInputValue] = useState(value);

  const onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={`radio_buttons`}>
      {options.map((option) => {
        return (
          <label key={option.value} className="label">
            <input
              type="radio"
              className="radio"
              name={name}
              value={option.value}
              required={required}
              onChange={onChangeEvent}
              checked={inputValue === option.value}
              disabled={disabled}
            />
            <span className="checkmark"></span>
            <span className="text">{option.label}</span>
          </label>
        );
      })}
    </div>
  );
};
