import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import mapboxgl, { MapMouseEvent } from "mapbox-gl";
import { featureCollection } from "@turf/helpers";
import { Incident, Vehicle, VehicleSoc } from "hooks/react-query/resources";
import { addImagesToMap } from "hooks/mapbox/helpers/Images";
import {
  addDefaultLayers,
  vehicleGPSLayerIds,
} from "hooks/mapbox/helpers/Layers";
import { ROUTES } from "features/pages/Routes";
import type { VehicleGps } from "hooks/react-query/resources/vehicleInfo";
import { vehicleToGeoJson } from "hooks/mapbox/helpers/GenericFunctions";
import { useMapFunctions } from "hooks/mapbox/useMapFunctions";
import { DEFAULT_ZOOM_ON_RETURN } from "constants/constants";

export const vehicleGPSSourceId = "vehicle-gps-source";

type FleetVehicleMapFilter = {
  readonly blockId?: string;
  readonly concessionId?: string;
  readonly vehicleId?: string;
  readonly vehicleModelId?: string;
};

export type VehicleWithExtraDetails = {
  currentBlockId?: string;
  incidents: Incident[];
  vehicle: Vehicle;
  vehiclePosition: VehicleGps | undefined;
  vehicleSoc: VehicleSoc | undefined;
};

type FleetVehicleMapGPSEventsProps = {
  readonly vehicles: VehicleWithExtraDetails[] | undefined;
  readonly filter?: FleetVehicleMapFilter;
};

export const useFleetVehicleMapGPSEvents = ({
  vehicles,
  filter,
}: FleetVehicleMapGPSEventsProps) => {
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const { flyTo, setMapZoom } = useMapFunctions(map);
  const [viewingVehicle, setViewingVehicle] = useState<string | null>(null);
  const [mapImagesIds, setMapImagesIds] = useState<string[]>([]);
  const navigate = useNavigate();
  const geojson = useMemo(() => {
    if (!vehicles || vehicles.length === 0) {
      return undefined;
    }

    return vehicleToGeoJson(vehicles);
  }, [vehicles]);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (vehicles && vehicles.length > 1 && viewingVehicle != null) {
      setMapZoom(DEFAULT_ZOOM_ON_RETURN);
      setViewingVehicle(null);
    }

    moveLayerPosition();
  }, [vehicles, viewingVehicle]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const source = map.getSource(vehicleGPSSourceId) as mapboxgl.GeoJSONSource;
    source?.setData(geojson || featureCollection([]));
  }, [map, geojson]);

  useEffect(() => {
    addLayers();
  }, [map]);

  useEffect(() => {
    if (!map) {
      return;
    }

    map.on("click", onMapClick);

    return () => {
      map.off("click", onMapClick);
    };
  }, [map, filter]);

  const addLayers = async () => {
    if (!map) {
      return;
    }

    removeLayers();

    setMapImagesIds(await addImagesToMap(map));
    map.addSource(vehicleGPSSourceId, {
      type: "geojson",
      data: geojson || featureCollection([]),
    });

    addDefaultLayers(map);
  };

  const removeLayers = () => {
    if (!map) {
      return;
    }

    mapImagesIds.forEach((mapImageId) => {
      if (map.hasImage(mapImageId)) {
        map.removeImage(mapImageId);
      }
    });

    Object.values(vehicleGPSLayerIds).forEach((layerId) => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    });

    if (map.getSource(vehicleGPSSourceId)) {
      map.removeSource(vehicleGPSSourceId);
    }
  };

  const moveLayerPosition = () => {
    if (!map || !geojson) {
      return;
    }

    const source = map.getSource(vehicleGPSSourceId) as mapboxgl.GeoJSONSource;
    source?.setData(geojson || featureCollection([]));

    if (
      vehicles?.[0]?.vehiclePosition &&
      vehicles.length === 1 &&
      viewingVehicle != vehicles?.[0].vehicle.id
    ) {
      flyTo({
        center: [
          vehicles[0].vehiclePosition.longitude,
          vehicles[0].vehiclePosition.latitude,
        ],
        zoom: 12.5,
        bearing: 0,
        speed: 10000,
        pitch: 0,
      });
      setViewingVehicle(vehicles?.[0].vehicle.id);
    }
  };

  const onMapClick = (e: MapMouseEvent) => {
    // QID-12470 Remove this check - If already on the vehicle map page, don't navigate to the same page
    if (vehicles && vehicles.length === 1) {
      return;
    }

    const layers = Object.values(vehicleGPSLayerIds);
    const existingLayers = layers.filter((value) => {
      return !!map?.getLayer(value);
    });
    const selectedFeatures = map?.queryRenderedFeatures(e.point, {
      layers: existingLayers,
    });

    if (selectedFeatures?.[0]?.properties) {
      const searchParams = {
        vehicleId: selectedFeatures[0].properties.vehicleId,
      };

      navigate(ROUTES.MAP.buildPath({}, searchParams));
    }
  };

  return { setMap };
};
