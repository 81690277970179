import { db } from "assets/database/Database";
import { chargePointTable } from "assets/database/tables/ChargePointTable";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiOptions, useAsyncApi } from "helpers/useAsyncApi";
import { EndpointStatusEnum } from "./EndpointHelper";

type dataInterface = chargePointTable.IResponseData;
type dataType = dataInterface;
type postDataType = chargePointTable.IPostData | undefined;
const table = db.chargePoint;

export const ChargePointEndpoint = async (data: postDataType) => {
  try {
    const options: IUseApiOptions = {
      method: "POST",
    };
    const endpointPath = ENDPOINTS.QONTROL.VEHICLE.INFO.SOC.path;
    const requestOptions = {
      endpoint: endpointPath,
      options: options,
      schema: chargePointTable.chargePointResponseSchema,
      data: data,
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await useAsyncApi<postDataType, dataType>(requestOptions);

    if (!response.data) {
      return { status: EndpointStatusEnum.NO_DATA, data: null };
    }

    const dataArray = chargePointTable.convertResponseObjectToDatabaseObject(
      response.data,
    );
    const existingData = await table.toArray();

    const mergedDataArray = dataArray.map((item) => {
      const existingItem = existingData.find(
        (existingItem) => existingItem.id === item.id,
      );
      return {
        value: { ...existingItem?.value, ...item.value },
        id: item.id,
      };
    });

    table.bulkPut(mergedDataArray);

    return { status: EndpointStatusEnum.SUCCESS, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: EndpointStatusEnum.ERROR, data: null };
  }
};
