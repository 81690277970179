import { Modal } from "antd";
import BackIcon from "assets/img/icons/svg/BackIcon.svg";
import { AutoSize } from "components/elements/autosize/AutoSize";
import { Button } from "components/elements/button/Button";
import { Header } from "features/layout/header/Header";
import { ROUTES } from "features/pages/Routes";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./QuestionnaireHeader.scss";

export const QuestionnaireHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { headerTitle, showBackArrow } = useAppSelector(
    (state) => state.QuestionnaireReducer.header,
  );
  const { SetQuestionnaireHeaderState } = useAppDispatch();
  const navigate = useNavigate();
  const isOnListPage =
    window.location.pathname === ROUTES.QUALITY_MEASUREMENTS.buildPath({});

  const onBackArrowClick = () => {
    navigate(ROUTES.QUALITY_MEASUREMENTS.buildPath({}));
    setIsModalOpen(false);
  };

  const onNavigateToDefault = () => {
    navigate(ROUTES.DEFAULT.path);
  };

  useEffect(() => {
    SetQuestionnaireHeaderState({
      headerTitle: t("pages.quality_measurements.title"),
    });
  }, [t]);

  return (
    <Header className="QKCA-header">
      <div className="content">
        <Button
          onClickEvent={() =>
            isOnListPage ? onNavigateToDefault() : setIsModalOpen(true)
          }
          background="standard"
          className="button button_left"
        >
          <img src={BackIcon} alt="Back" />
        </Button>

        {showBackArrow && <div className="spacer"></div>}

        <div className="container">
          <div className="title">
            <AutoSize>{headerTitle}</AutoSize>
          </div>
        </div>

        <div className="spacer"></div>
      </div>
      <Modal
        open={isModalOpen}
        title={t("pages.quality_measurements.close_modal.title")}
        onOk={onBackArrowClick}
        cancelText={t("default.cancel")}
        okText={t("default.ok")}
        onCancel={() => setIsModalOpen(false)}
      >
        <p>{t("pages.quality_measurements.close_modal.text")}</p>
      </Modal>
    </Header>
  );
};
