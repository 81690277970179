import { VehicleWithDetails } from "hooks/react-query/resources";

type FleetVehicleFilters = {
  blockId?: string;
  concessionId?: string;
  vehicleId?: string;
  vehicleModelId?: string;
};

// TODO QID-12653 Create unit tests for this logic
export function isMatchingVehicle(
  filters: FleetVehicleFilters,
  vehicleWithDetails: VehicleWithDetails,
) {
  if (!filters) {
    return true;
  }

  if (
    filters.blockId &&
    !vehicleWithDetails.currentBlockId?.startsWith(filters.blockId)
  ) {
    return false;
  }

  if (
    filters.concessionId &&
    String(vehicleWithDetails.vehicle.concession) !== filters.concessionId
  ) {
    return false;
  }

  if (
    filters.vehicleId &&
    !vehicleWithDetails.vehicle.id.startsWith(filters.vehicleId)
  ) {
    return false;
  }

  if (
    filters.vehicleModelId &&
    String(vehicleWithDetails.vehicle.vehicleModel.id) !==
      filters.vehicleModelId
  ) {
    return false;
  }

  return true;
}
