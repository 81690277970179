import BusIcon from "assets/img/mapbox/BusIcon.png";
import BoatIcon from "assets/img/mapbox/BootIcon.png";
import SocCardBackgroundBlue from "assets/img/mapbox/SocCardBackgroundBlue.png";
import SocCardBackgroundGreen from "assets/img/mapbox/SocCardBackgroundGreen.png";
import SocCardBackgroundOrange from "assets/img/mapbox/SocCardBackgroundOrange.png";
import SocCardBackgroundRed from "assets/img/mapbox/SocCardBackgroundRed.png";
import SocCardBackgroundGray from "assets/img/mapbox/SocCardBackgroundGray.png";
import SocCardBackgroundYellow from "assets/img/mapbox/SocCardBackgroundYellow.png";
import TrainIcon from "assets/img/mapbox/TrainIcon.png";
import TramIcon from "assets/img/mapbox/TramIcon.png";
import VehicleCardBackground from "assets/img/mapbox/VehicleCardBackground.png";
import VehicleCardBackgroundBlack from "assets/img/mapbox/VehicleCardBackgroundBlack.png";
import VehicleCardBackgroundSoc from "assets/img/mapbox/VehicleCardBackgroundSoc.png";
import VehicleCardBackgroundSocBlack from "assets/img/mapbox/VehicleCardBackgroundSocBlack.png";
import mapboxgl from "mapbox-gl";
import { vehicleGPSLayerIds } from "./Layers";
import routeArrow from "assets/img/mapbox/routeArrow.png";

const addImageToMap = (
  map: mapboxgl.Map,
  imageId: string,
  image: string,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    map?.loadImage(image, (error, image) => {
      if (error || !image) {
        return reject(new Error("Failed loading image"));
      }

      if (map.hasImage(imageId)) {
        return resolve(imageId);
      }
      map.addImage(imageId, image);
      resolve(imageId);
    });
  });
};

export const addImagesToMap = (map: mapboxgl.Map) => {
  const promises = [];

  promises.push(
    addImageToMap(
      map,
      vehicleGPSLayerIds.layer_vehicle_background,
      VehicleCardBackground,
    ),
  );
  promises.push(
    addImageToMap(
      map,

      vehicleGPSLayerIds.layer_vehicle_background_black,
      VehicleCardBackgroundBlack,
    ),
  );
  promises.push(
    addImageToMap(
      map,

      vehicleGPSLayerIds.layer_vehicle_background_soc,
      VehicleCardBackgroundSoc,
    ),
  );
  promises.push(
    addImageToMap(
      map,

      vehicleGPSLayerIds.layer_vehicle_background_soc_black,
      VehicleCardBackgroundSocBlack,
    ),
  );
  promises.push(
    addImageToMap(map, vehicleGPSLayerIds.vehicle_icon_bus, BusIcon),
  );
  promises.push(
    addImageToMap(map, vehicleGPSLayerIds.vehicle_icon_tram, TramIcon),
  );
  promises.push(
    addImageToMap(map, vehicleGPSLayerIds.vehicle_icon_train, TrainIcon),
  );
  promises.push(
    addImageToMap(map, vehicleGPSLayerIds.vehicle_icon_boat, BoatIcon),
  );
  promises.push(
    addImageToMap(
      map,
      vehicleGPSLayerIds.soc_background_blue,
      SocCardBackgroundBlue,
    ),
  );
  promises.push(
    addImageToMap(
      map,
      vehicleGPSLayerIds.soc_background_red,
      SocCardBackgroundRed,
    ),
  );
  promises.push(
    addImageToMap(
      map,
      vehicleGPSLayerIds.soc_background_gray,
      SocCardBackgroundGray,
    ),
  );
  promises.push(
    addImageToMap(
      map,
      vehicleGPSLayerIds.soc_background_orange,
      SocCardBackgroundOrange,
    ),
  );
  promises.push(
    addImageToMap(
      map,
      vehicleGPSLayerIds.soc_background_yellow,
      SocCardBackgroundYellow,
    ),
  );
  promises.push(
    addImageToMap(
      map,
      vehicleGPSLayerIds.soc_background_green,
      SocCardBackgroundGreen,
    ),
  );

  promises.push(
    addImageToMap(map, vehicleGPSLayerIds.route_arrow_image, routeArrow),
  );

  return Promise.all(promises);
};

export const addRouteImage = (map: mapboxgl.Map) => {
  const promises = [];

  promises.push(
    addImageToMap(map, vehicleGPSLayerIds.route_arrow_image, routeArrow),
  );

  return Promise.all(promises);
};
