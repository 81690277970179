import { useTranslation } from "react-i18next";
import {
  TimeCardPlaceType,
  TimeCardType,
} from "hooks/collectors/endpoints/TimeCardEndpoint";
import styles from "./TimeCard.module.scss";
import { Card } from "components/elements/card/Card";
import { DateUtils } from "helpers/DateUtils";

interface TimeCardProps {
  readonly timeCard: TimeCardType;
}

export const TimeCard = ({ timeCard }: TimeCardProps) => {
  const { t } = useTranslation();

  const createStartPlaceLabel = (
    places: TimeCardPlaceType[] | undefined,
    startPlaceId: string | undefined,
  ) => {
    const startPlace = places?.find((place) => place?.id === startPlaceId);

    if (startPlace) {
      return `${startPlace.city}, ${startPlace.stop} (${startPlaceId})`;
    }

    return startPlaceId;
  };

  const createTimeLabel = (date: string) => {
    const parsedDate = DateUtils.convertStringToDate(date);
    return DateUtils.getLocaleTimeFromDate(parsedDate);
  };

  const operatingDay = DateUtils.getLocaleDateFromDate(
    DateUtils.convertStringToDate(timeCard.operatingDay),
  );

  const startTimeCardItem = timeCard.items?.find(
    (timeCardItem) =>
      (timeCardItem.type === "PIECE" &&
        timeCardItem.status !== "other" &&
        timeCardItem.status !== "signoff") ||
      timeCardItem.status === "signon",
  );
  const endTimeCardItem = timeCard.items?.findLast(
    (timeCardItem) => timeCardItem.status === "signoff",
  );

  const startPlace = createStartPlaceLabel(
    timeCard.places,
    startTimeCardItem?.startPlaceId,
  );
  const startTime = startTimeCardItem?.start
    ? createTimeLabel(startTimeCardItem.start)
    : undefined;
  const endTime = endTimeCardItem?.end
    ? createTimeLabel(endTimeCardItem.end)
    : undefined;

  return (
    <div className={styles.contact_container}>
      <div className={styles.time_card_container}>
        <div className={styles.time_card_box}>
          <div className={styles.time_card_date}>
            {operatingDay} ({timeCard.dutyDescription})
          </div>

          {startTimeCardItem ? (
            <Card className={styles.time_card_card}>
              <div className={styles.content}>
                <p>
                  <strong className={styles.shift_header}>
                    {t("time_card.shift")}
                  </strong>
                  : {startTimeCardItem.dutyDescription}
                </p>

                <div>
                  <p>Start dienstlocatie: {startPlace}</p>
                  <p>Starttijd: {startTime}</p>
                  <p>Eindtijd: {endTime}</p>
                </div>
              </div>
            </Card>
          ) : null}
        </div>
      </div>
    </div>
  );
};
