import { ThemeConfig } from "antd";
import { createStyles } from "antd-style";
import { TabsConfig } from "antd/es/config-provider/context";

const tabsTokens: ThemeConfig["components"] = {
  Tabs: {
    inkBarColor: "#ff8c00",
    itemActiveColor: "#ff8c00",
    itemColor: "rgb(255 255 255 / 80%)",
    itemHoverColor: "rgb(255 255 255 / 80%)",
    itemSelectedColor: "#ff8c00",
  },
};

export const useTabsStyle = (): {
  tabsStyles: TabsConfig;
  tabsTokens: ThemeConfig["components"];
} => {
  const { styles } = createStyles(() => ({
    "qbuzz-tabs": {},
  }))();

  return {
    tabsTokens,
    tabsStyles: {
      className: styles["qbuzz-tabs"],
    },
  };
};
