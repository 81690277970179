import styles from "./Button.module.scss";
import { CSSProperties, ReactNode, RefObject } from "react";

type BackgroundColors = "dark" | "standard";
type ButtonSizes = "small" | "medium" | "large";

interface ButtonProps {
  type?: "button" | "submit" | "reset";
  className?: string;
  enabled?: boolean;
  refItem?: RefObject<HTMLButtonElement>;
  onClickEvent?: () => void;
  size?: ButtonSizes;
  style?: CSSProperties;
  background?: BackgroundColors;
  icon?: ReactNode;
  children?: ReactNode;
}

export const Button = ({
  type,
  className,
  enabled = true,
  refItem,
  onClickEvent,
  size = "small",
  style,
  background = "dark",
  children,
}: ButtonProps) => {
  const enabledClass = !enabled ? styles.default_button_disabled : "hoverItem";
  onClickEvent = !enabled ? undefined : onClickEvent;
  const backgroundClass =
    background === "dark"
      ? styles.default_button_dark
      : styles.default_button_standard;

  const sizeClass =
    size === "small"
      ? styles.default_button_small
      : size === "medium"
        ? styles.default_button_medium
        : styles.default_button_large;

  return (
    <button
      className={`${className || ""} ${styles.default_button} ${enabledClass || ""} ${sizeClass || ""} ${backgroundClass || ""}`}
      onClick={onClickEvent}
      ref={refItem}
      style={style}
      type={type}
    >
      {children}
    </button>
  );
};
