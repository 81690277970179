import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { UserResource, type User } from "./resources/user";

export const useUser = () => {
  const userApi = new UserResource();

  const useGetUser: () => UseQueryResult<User, Error> = () =>
    useQuery({
      queryKey: ["user"],
      queryFn: async () => {
        const response = await userApi.getUser();

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
    });

  const useGetUserConcessionIds: () => UseQueryResult<number[], Error> = () =>
    useQuery({
      queryKey: ["user"],
      queryFn: async () => {
        const response = await userApi.getUser();

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
      select: (data) => data?.concessions || [],
    });

  return { useGetUser, useGetUserConcessionIds };
};
