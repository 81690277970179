import { format } from "date-fns";

export namespace DateUtils {
  export interface ITimeElapsed {
    type: "s" | "m" | "h" | "d";
    value: number;
  }

  export const getDaysBetweenDates = (
    date1: Date | undefined,
    date2: Date | undefined,
  ): number | null => {
    if (!date1 || !date2) {
      return null;
    }

    return Math.abs(date1.getTime() - date2.getTime()) / 86400000;
  };

  export const convertStringToDate = (
    dateString: string | undefined,
  ): Date | undefined => {
    if (!dateString) {
      return undefined;
    }

    return new Date(dateString);
  };

  export const formatDate = (
    date: Date | undefined,
    formatString = "dd-MM-yyyy HH:mm",
  ): string | undefined => {
    if (!date) {
      return undefined;
    }

    return format(date, formatString);
  };

  export const convertUnixTimestampToDate = (
    timestamp?: number,
  ): Date | undefined => {
    if (!timestamp) {
      return undefined;
    }

    return new Date(timestamp);
  };

  // TODO QID-6184 - After this story remove this (api will return local date or utc date for all endpoints)
  export const convertUTCDatetoLocalDate = (
    date: Date | undefined,
  ): Date | undefined => {
    if (!date) {
      return undefined;
    }

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  };

  export const convertSecondsToTime = (
    seconds: number,
    formatString = "HH:mm",
  ): string => {
    return format(new Date(seconds * 1000), formatString);
  };

  export const getTimeElapsed = (
    date: Date | undefined,
  ): ITimeElapsed | null => {
    if (!date) {
      return null;
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    if (diffInSeconds < 60) {
      return { type: "s", value: diffInSeconds };
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return { type: "m", value: diffInMinutes };
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return { type: "h", value: diffInHours };
    }

    const diffInDays = Math.floor(diffInHours / 24);
    return { type: "d", value: diffInDays };
  };

  export const getLocaleDateFromDate = (
    date: Date | undefined,
  ): string | null => {
    if (!date) {
      return null;
    }

    return date.toLocaleDateString("nl-NL", {
      weekday: "long",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  export const getLocaleTimeFromDate = (
    date: Date | undefined,
  ): string | null => {
    if (!date) {
      return null;
    }

    return date.toLocaleTimeString("nl-NL", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };
}
