import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiOptions, useAsyncApi } from "helpers/useAsyncApi";
import { EndpointStatusEnum } from "./EndpointHelper";
import { format } from "date-fns";

const TimeCardItemSchema = z.object({
  driving: z.boolean().optional(),
  dutyDescription: z.string().optional(),
  dutyPieceSeq: z.number().optional(),
  employeeId: z.string().optional(),
  end: z.string().optional(),
  endPlaceId: z.string().optional(),
  order: z.number(),
  publicType: z.string().optional(),
  routeId: z.string().optional(),
  scheduleId: z.number().optional(),
  start: z.string().optional(),
  startPlaceId: z.string().optional(),
  status: z.string().optional(),
  type: z.string().optional(),
});

const TimeCardPlaceSchema = z.object({
  id: z.string().optional(),
  stop: z.string().optional(),
  city: z.string().optional(),
});

const TimeCardSchema = z.object({
  createdAt: z.string(),
  dutyDescription: z.string().optional(),
  employeeId: z.string().optional(),
  employees: z.array(z.object({})).optional(),
  items: z.array(TimeCardItemSchema).optional(),
  operatingDay: z.string(),
  places: z.array(TimeCardPlaceSchema).optional(),
});

export type TimeCardItemType = z.infer<typeof TimeCardItemSchema>;
export type TimeCardPlaceType = z.infer<typeof TimeCardPlaceSchema>;
export type TimeCardType = z.infer<typeof TimeCardSchema>;

export const TimeCardEndpoint = async (operationDay: Date) => {
  try {
    const options: IUseApiOptions = {
      method: "GET",
    };
    const endpoint = ENDPOINTS.QONTROL.TIMECARD.buildPath({
      operationDay: format(operationDay, "yyyy-MM-dd"),
    });
    const requestOptions = { endpoint, options, schema: TimeCardSchema };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await useAsyncApi<null, TimeCardType>(requestOptions);
    if (response.error) {
      return { status: EndpointStatusEnum.ERROR, data: null };
    }

    if (!response.data) {
      return { status: EndpointStatusEnum.NO_DATA, data: null };
    }

    if (response.status !== 200) {
      return { status: EndpointStatusEnum.ERROR, data: null };
    }

    return { status: EndpointStatusEnum.SUCCESS, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: EndpointStatusEnum.ERROR, data: null };
  }
};
