import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiOptions, IUseApiProps, useAsyncApi } from "helpers/useAsyncApi";
import { EndpointStatusEnum } from "../EndpointHelper";

const schema = z.object({
  imageId: z.string(),
});

type dataInterface = z.infer<typeof schema>;
type postDataType = FormData;

export const QualityMeasurementsImageUploadEndpoint = async (
  data: FormData,
) => {
  try {
    const options: IUseApiOptions = {
      method: "POST",
    };
    const endpointPath =
      ENDPOINTS.QONTROL.QUALITY_MEASUREMENTS.IMAGE_UPLOAD.path;
    const requestOptions: IUseApiProps<postDataType> = {
      endpoint: endpointPath,
      options: options,
      schema: schema,
      data: data,
      contentType: "multipart/form-data",
      acceptType: "application/json",
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await useAsyncApi<postDataType, dataInterface>(
      requestOptions,
    );

    if (!response.data) {
      return { status: EndpointStatusEnum.NO_DATA, data: null };
    }

    if (response.status !== 200) {
      return { status: EndpointStatusEnum.ERROR, data: null };
    }

    return { status: EndpointStatusEnum.SUCCESS, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: EndpointStatusEnum.ERROR, data: null };
  }
};
