import { z } from "zod";

export namespace chargePointTable {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const localFilterSchema = z.object({
    id: z.string(),
    types: z.array(z.string()),
  });

  export type ILocalFilter = z.infer<typeof localFilterSchema>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const chargePointPostSchema = z.record(z.array(z.string()));

  export type IPostData = z.infer<typeof chargePointPostSchema>;

  export const chargePointResponseSchema = z.record(
    z.record(
      z.object({
        value: z.number(),
        time: z.number().int(),
      }),
    ),
  );

  export type IResponseData = z.infer<typeof chargePointResponseSchema>;

  export const databaseSchema = z.object({
    id: z.string(),
    value: z.record(
      z.object({
        value: z.number(),
        time: z.number().int(),
      }),
    ),
  });

  export type IDatabase = z.infer<typeof databaseSchema>;

  /**
   * This is the array of the properties of the chargePoint table.
   * This is used to create the table in the database, with type checking. (It will be converted to a comma-separated string in the Database.ts file)
   * @type {Array<keyof IchargePoint>}
   */
  export const tableKeys: Array<keyof IDatabase> = ["id", "value"];

  export const convertDatabaseObjectToPostObject = (
    inputArray: ILocalFilter[],
  ) => {
    return inputArray.reduce<IPostData>((accumulator, current) => {
      accumulator[current.id] = current.types;
      return accumulator;
    }, {});
  };

  export const convertResponseObjectToDatabaseObject = (
    inputObject: IResponseData,
  ) => {
    return Object.entries(inputObject).map(([id, value]) => ({
      id,
      value,
    }));
  };
}
