import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiOptions, useAsyncApi } from "helpers/useAsyncApi";
import { EndpointStatusEnum } from "./EndpointHelper";

const schema = z.object({
  time: z.number(),
  value: z.number(),
});

export type VehicleHistorySocType = z.infer<typeof schema>;

export const VehicleHistorySocEndpoint = async (vehicleId: string) => {
  try {
    const options: IUseApiOptions = {
      method: "GET",
    };
    const endpointPath = ENDPOINTS.QONTROL.VEHICLE.HISTORY.SOC.buildPath({
      vehicleId: vehicleId,
    });
    const requestOptions = {
      endpoint: endpointPath,
      options: options,
      schema: z.array(schema),
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await useAsyncApi<null, VehicleHistorySocType[]>(
      requestOptions,
    );

    if (!response.data) {
      return { status: EndpointStatusEnum.NO_DATA, data: null };
    }

    return { status: EndpointStatusEnum.SUCCESS, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: EndpointStatusEnum.ERROR, data: null };
  }
};
