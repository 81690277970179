import { ThemeConfig } from "antd";
import { createStyles } from "antd-style";
import { ButtonConfig } from "antd/es/config-provider/context";

const buttonTokens: ThemeConfig["components"] = {
  Button: {
    defaultBg: "rgba(0, 0, 0, 0.88)",
  },
};

export const useButtonStyle = (): {
  buttonStyles: ButtonConfig;
  buttonTokens: ThemeConfig["components"];
} => {
  const { styles } = createStyles(() => ({
    "qbuzz-button": {},
  }))();

  return {
    buttonTokens,
    buttonStyles: {
      className: styles["qbuzz-button"],
    },
  };
};
