import { useEffect, useMemo } from "react";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { Mapbox } from "components/mapbox/Mapbox";
import { ROUTES } from "features/pages/Routes";
import { useFleetVehicleMapGPSEvents } from "hooks/mapbox/events/useFleetVehicleMapGPSEvents";
import styles from "./Map.module.scss";
import { useAppDispatch } from "hooks/useRedux";
import { useFleetVehicleMapRouteEvents } from "hooks/mapbox/events/useFleetVehicleMapRouteEvents";
import { MapButtons } from "components/mapbox/MapButtons";
import { useNavigate } from "react-router-dom";
import { CardItemVehicle } from "components/elements/card/items/CardItemVehicle";
import { useConcession, useVehicle, useVehicleInfo } from "hooks/react-query";
import { isMatchingVehicle } from "helpers/VehicleMatcher";
import { Vehicle, VehicleWithDetails } from "hooks/react-query/resources";
import { FilterHeader } from "features/layout/header/filter/FilterHeader";

export const Map = () => {
  const [{ blockId, concessionId, vehicleId, vehicleModelId, origin }] =
    useTypedSearchParams(ROUTES.MAP);
  const { setBackArrow } = useAppDispatch();
  const navigate = useNavigate();

  const fleetVehicleMapFilter = useMemo(() => {
    if (
      blockId === undefined &&
      concessionId === undefined &&
      vehicleId === undefined &&
      vehicleModelId === undefined
    ) {
      return undefined;
    }

    return {
      ...(blockId && { blockId }),
      ...(concessionId && { concessionId }),
      ...(vehicleId && { vehicleId }),
      ...(vehicleModelId && { vehicleModelId }),
    };
  }, [blockId, concessionId, vehicleId, vehicleModelId]);

  const getVehicles = useVehicle().useGetAllVehiclesWithDetails();

  const filteredVehicles = useMemo(() => {
    return getVehicles.data?.filter(
      (data) =>
        !fleetVehicleMapFilter ||
        isMatchingVehicle(fleetVehicleMapFilter, data),
    );
  }, [fleetVehicleMapFilter, getVehicles.data]);

  const socVehicleIds = filteredVehicles
    ?.filter(
      ({ vehicle }: { vehicle: Vehicle }) =>
        vehicle.vehicleModel.typeOfFuel === "ELECTRICITY",
    )
    .map(({ vehicle }: { vehicle: Vehicle }) => vehicle.id);

  const viewingOneVehicle = () => {
    return filteredVehicles !== undefined && filteredVehicles.length === 1;
  };

  const getVehicleInfoSoc =
    useVehicleInfo().useGetAllSocByVehicleIds(socVehicleIds);

  const getVehicleInfoAllGps = useVehicleInfo().useGetAllGps(
    filteredVehicles !== undefined && filteredVehicles.length > 1,
  );

  const getVehicleInfoOneGps = useVehicleInfo().useGetOneGpsByVehicleId(
    viewingOneVehicle() && filteredVehicles?.[0]
      ? filteredVehicles[0].vehicle.id
      : undefined,
  );

  const getGpsForCorrectSource = (vehicleWithDetails: VehicleWithDetails) => {
    const gps = getVehicleInfoAllGps.data?.find(
      (vehicleGps) => vehicleGps.vehicleId === vehicleWithDetails.vehicle.id,
    );

    if (!viewingOneVehicle() || !getVehicleInfoOneGps.isSuccess) {
      return gps;
    }

    return getVehicleInfoOneGps.data;
  };

  const filteredVehiclesData = useMemo(() => {
    if (!filteredVehicles) {
      return undefined;
    }

    return filteredVehicles.map((vehicleWithDetails) => ({
      ...vehicleWithDetails,
      vehicleSoc: getVehicleInfoSoc.data?.find(
        ({ vehicleId }) => vehicleWithDetails.vehicle.id === vehicleId,
      ),
      vehiclePosition: getGpsForCorrectSource(vehicleWithDetails),
    }));
  }, [
    filteredVehicles,
    getVehicleInfoSoc.data,
    getVehicleInfoAllGps.data,
    getVehicleInfoOneGps.data,
  ]);

  useEffect(() => {
    setBackArrow(false);

    return () => {
      setBackArrow(true);
    };
  }, []);

  const navigateBack = () => {
    if (origin) {
      navigate(origin);
      return;
    }

    getVehicles.refetch();
    getVehicleInfoSoc.refetch();
    getVehicleInfoAllGps.refetch();

    navigate(ROUTES.MAP.buildPath({}, {}));
  };

  const getConcessions = useConcession().useGetAll();
  const getVehicleInfoTrip = useVehicleInfo().useGetAllTripByVehicleIds(
    viewingOneVehicle() && filteredVehicles?.[0]
      ? [filteredVehicles[0].vehicle.id]
      : undefined,
  );

  return (
    <>
      <FilterHeader
        path="map"
        floating={true}
        viewingOneVehicle={viewingOneVehicle()}
      />

      <div className={styles.map}>
        <Mapbox
          // TODO QID-12470: Do not render custom React hooks inside callbacks
          customEventHooks={[
            () =>
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useFleetVehicleMapGPSEvents({
                vehicles: filteredVehiclesData,
                filter: fleetVehicleMapFilter,
              }),
            () =>
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useFleetVehicleMapRouteEvents({
                vehicleId:
                  viewingOneVehicle() && filteredVehiclesData?.[0]
                    ? filteredVehiclesData[0].vehicle.id
                    : undefined,
              }),
          ]}
        />

        {viewingOneVehicle() && (
          <MapButtons
            firstButtonEvent={navigateBack}
            firstButtonVisible={true}
            secondButtonVisible={false}
          />
        )}

        {viewingOneVehicle() && filteredVehiclesData && (
          <div className={styles.fleet_vehicles_container}>
            <CardItemVehicle
              key={filteredVehiclesData[0].vehicle.id}
              locationButtonIsActive={true}
              origin={`${location.pathname}${location.search}`}
              subMenuActive={true}
              vehicle={filteredVehiclesData[0].vehicle}
              vehicleIncidents={filteredVehiclesData[0].incidents}
              vehicleSoc={filteredVehiclesData[0].vehicleSoc}
              vehicleTripInfo={getVehicleInfoTrip.data?.[0]}
              concessionShortCode={
                getConcessions.data?.find(
                  (concession) =>
                    concession.id ===
                    filteredVehiclesData[0].vehicle.concession,
                )?.shortCode
              }
            />
          </div>
        )}
      </div>
    </>
  );
};
