import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tag } from "antd";
import useModal from "antd/es/modal/useModal";
import BusIcon from "assets/img/icons/svg/BusIcon.svg";
import BoatIcon from "assets/img/icons/svg/BootIcon.svg";
import TramIcon from "assets/img/icons/svg/TramIcon.svg";
import TrainIcon from "assets/img/icons/svg/TrainIcon.svg";
import IncidentsIcon from "assets/img/icons/svg/IncidentsIcon.svg";
import PinPointIcon from "assets/img/icons/svg/PinPointIcon.svg";
import DropdownIcon from "assets/img/icons/svg/DropdownIcon.svg";
import { BlockActivities } from "components/block-activities/BlockActivities";
import { ButtonWithNumber } from "components/elements/button/ButtonWithNumber";
import { ButtonWithTimestamp } from "components/elements/button/ButtonWithTimestamp";
import { Card } from "components/elements/card/Card";
import { Incidents } from "components/incidents/Incidents";
import { ScheduledRouteModal } from "components/scheduled-route/ScheduledRouteModal";
import { ROUTES } from "features/pages/Routes";
import { DateUtils } from "helpers/DateUtils";
import { SocUtils } from "helpers/SocUtils";
import {
  Incident,
  Vehicle,
  VehicleSoc,
  VehicleTrip,
} from "hooks/react-query/resources";
import { useAppDispatch } from "hooks/useRedux";
import styles from "./CardItemVehicle.module.scss";
import { CardItemVehicleSubMenu } from "./CardItemVehicleSubMenu";

const BUTTON_TIMESTAMP_REFRESH_INTERVAL = 5000;

interface ICardItemVehicleProps {
  concessionShortCode?: string;
  locationButtonIsActive?: boolean;
  origin: string;
  subMenuActive?: boolean;
  vehicle: Vehicle;
  vehicleIncidents?: Incident[];
  vehicleSoc?: VehicleSoc;
  vehicleTripInfo?: VehicleTrip;
}

export const CardItemVehicle = ({
  concessionShortCode,
  locationButtonIsActive,
  origin,
  subMenuActive = false,
  vehicle,
  vehicleIncidents,
  vehicleSoc,
  vehicleTripInfo,
}: ICardItemVehicleProps) => {
  const { t } = useTranslation();

  const [ivuDate, setIvuDate] = useState<DateUtils.ITimeElapsed | null>(
    DateUtils.getTimeElapsed(
      DateUtils.convertStringToDate(vehicleTripInfo?.lastUpdated),
    ),
  );
  const [socDate, setSocDate] = useState<DateUtils.ITimeElapsed | null>(
    DateUtils.getTimeElapsed(
      DateUtils.convertUnixTimestampToDate(vehicleSoc?.time),
    ),
  );
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);
  const [tripModalOpen, setTripModalOpen] = useState<boolean>(false);

  const { setSocPopupVisible, setSocPopupData } = useAppDispatch();
  const navigate = useNavigate();

  const [blockActivitiesModal, blockActivitiesModalContextHolder] = useModal();
  const [incidentsModal, incidentsModalContextHolder] = useModal();

  const socColorClass = (() => {
    const socColor = SocUtils.getSocColor(vehicleSoc?.value);

    switch (socColor) {
      case SocUtils.SocColor.GREY:
        return styles["color-grey"];
      case SocUtils.SocColor.RED:
        return styles["color-red"];
      case SocUtils.SocColor.ORANGE:
        return styles["color-orange"];
      case SocUtils.SocColor.YELLOW:
        return styles["color-yellow"];
      case SocUtils.SocColor.GREEN:
        return styles["color-green"];
      default:
        return styles["color-grey"];
    }
  })();

  const onVehicleMapClick = () => {
    const searchParams = {
      vehicleId: vehicle.id,
      origin,
    };

    return navigate(ROUTES.MAP.buildPath({}, searchParams));
  };

  const onSocButtonClick = (vehicleId: string) => {
    setSocPopupVisible(true);
    setSocPopupData({ vehicleId });
  };

  const onBlockButtonClick = async (blockId?: string) => {
    if (!blockId) {
      return;
    }

    blockActivitiesModal.info({
      autoFocusButton: null,
      centered: true,
      closable: true,
      content: <BlockActivities blockId={blockId} />,
      icon: null,
      maskClosable: true,
      okText: t("functional.back"),
      title: `${t("block_activities.title")} ${blockId}`,
    });
  };

  const onIncidentsButtonClick = async (vehicleId: string) => {
    incidentsModal.info({
      autoFocusButton: null,
      centered: true,
      closable: true,
      content: <Incidents vehicleId={vehicleId} />,
      icon: null,
      maskClosable: true,
      okText: t("functional.back"),
      title: t("functional.open_incidents"),
    });
  };

  useEffect(() => {
    const initialIvuDate = DateUtils.getTimeElapsed(
      DateUtils.convertStringToDate(vehicleTripInfo?.lastUpdated),
    );
    setIvuDate(initialIvuDate);

    const interval = setInterval(() => {
      if (!vehicleTripInfo) {
        return;
      }

      const intervalIvuDate = DateUtils.getTimeElapsed(
        DateUtils.convertStringToDate(vehicleTripInfo?.lastUpdated),
      );

      setIvuDate(intervalIvuDate);
    }, BUTTON_TIMESTAMP_REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [vehicleTripInfo]);

  useEffect(() => {
    const initialSocDate = DateUtils.getTimeElapsed(
      DateUtils.convertUnixTimestampToDate(vehicleSoc?.time),
    );
    setSocDate(initialSocDate);

    const interval = setInterval(() => {
      if (!vehicleSoc) {
        return;
      }

      const intervalSocDate = DateUtils.getTimeElapsed(
        DateUtils.convertUnixTimestampToDate(vehicleSoc.time),
      );
      setSocDate(intervalSocDate);
    }, BUTTON_TIMESTAMP_REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [vehicleSoc]);

  return (
    <Card className={styles.card_item_vehicles}>
      {subMenuActive && (
        <button
          className={`${styles.submenu_arrow} hoverItem`}
          onClick={() => setSubMenuOpen(!subMenuOpen)}
        >
          <img
            src={DropdownIcon}
            className={subMenuOpen ? "" : styles.rotated}
            alt="arrow up"
          />
        </button>
      )}
      <div className={styles.content}>
        <div className={styles.top_side}>
          <div className={styles.vehicle_number}>
            <span className={styles.icon}>
              {vehicle.vehicleGroup.transportType === "BUS" && (
                <img
                  src={BusIcon}
                  alt={vehicle.vehicleGroup.transportType || ""}
                />
              )}
              {vehicle.vehicleGroup.transportType === "TRAM" && (
                <img
                  src={TramIcon}
                  alt={vehicle.vehicleGroup.transportType || ""}
                />
              )}
              {vehicle.vehicleGroup.transportType === "TRAIN" && (
                <img
                  src={TrainIcon}
                  alt={vehicle.vehicleGroup.transportType || ""}
                />
              )}
              {vehicle.vehicleGroup.transportType === "BOAT" && (
                <img
                  src={BoatIcon}
                  alt={vehicle.vehicleGroup.transportType || ""}
                />
              )}
            </span>
            {vehicle.id ?? ""}
          </div>

          <div className={styles.vehicle_details}>
            <Tag bordered={false}>{concessionShortCode}</Tag>
            <Tag bordered={false}>
              {vehicle.vehicleModel?.make} {vehicle.vehicleModel?.model}
            </Tag>
          </div>

          <div className={styles.vehicle_buttons}>
            <ButtonWithNumber
              disabled={false}
              onClickEvent={() => onIncidentsButtonClick(vehicle.id)}
              value={
                vehicleIncidents && vehicleIncidents?.length > 0
                  ? vehicleIncidents.length
                  : undefined
              }
            >
              <img src={IncidentsIcon} alt="incidents" />
            </ButtonWithNumber>
            <button
              className={
                locationButtonIsActive ? `${styles.active} disabled` : ""
              }
              onClick={onVehicleMapClick}
            >
              <img src={PinPointIcon} alt="pinpoint" />
            </button>
          </div>
        </div>

        <div className={styles.bottom_side}>
          <div className={styles.vehicle_trip_buttons}>
            <ButtonWithTimestamp
              disabled={false}
              timestampValue={
                ivuDate ? `${ivuDate?.value}${ivuDate?.type}` : ""
              }
              timestampBackgroundColor={`${ivuDate?.type === "s" && ivuDate.value <= 30 ? "green" : "normal"}`}
              onClickEvent={() => setTripModalOpen(true)}
            >
              {t("terminology.line")} {vehicleTripInfo?.routeId}
            </ButtonWithTimestamp>
            <ButtonWithTimestamp disabled={true}>
              {t("terminology.trip")} {vehicleTripInfo?.tripNumber}
            </ButtonWithTimestamp>
            <ButtonWithTimestamp
              disabled={!vehicleTripInfo?.blockId}
              onClickEvent={() => onBlockButtonClick(vehicleTripInfo?.blockId)}
            >
              {t("terminology.block")} {vehicleTripInfo?.blockId ?? "-"}
            </ButtonWithTimestamp>
            <ButtonWithTimestamp
              disabled={!vehicleSoc?.value}
              timestampValue={
                socDate ? `${socDate?.value}${socDate?.type}` : ""
              }
              timestampBackgroundColor={`${socDate?.type === "s" && socDate.value <= 30 ? "green" : "normal"}`}
              onClickEvent={() => onSocButtonClick(vehicle.id)}
            >
              <div className={socColorClass}>
                {vehicleSoc?.value
                  ? `${t("terminology.soc")} ${vehicleSoc.value}%`
                  : "-"}
              </div>
            </ButtonWithTimestamp>
          </div>
        </div>

        {subMenuOpen && (
          <div className={styles.foldable_submenu_container}>
            <CardItemVehicleSubMenu vehicleId={vehicle.id} />
          </div>
        )}
      </div>

      <>{blockActivitiesModalContextHolder}</>
      <>{incidentsModalContextHolder}</>

      {tripModalOpen && (
        <ScheduledRouteModal
          onCancel={() => setTripModalOpen(false)}
          vehicleId={vehicle.id}
        />
      )}
    </Card>
  );
};
