import { route, string } from "react-router-typesafe-routes/dom";

/**
 * The endpoints of the API.
 * It is possible to use search parameters in the routes or hash parameters.
 */
export const ENDPOINTS = {
  QONTROL: route(
    "qontrol",
    {},
    {
      CONCESSIONS: route("concessions", {}),
      ME: route("me", {}),
      TIMECARD: route("timecard/:operationDay", {}),
      VEHICLE: route(
        "vehicle",
        {},
        {
          MODELS: route("models/:concessionId", {}),
          PERCONCESSION: route("per-concession/:concessionId", {}),
          INFO: route(
            "info",
            {},
            {
              GPS: route("gps/:vehicleId", {}),
              GPSALLVEHICLES: route("gps", {}),
              ROUTE: route("route/:vehicleId", {}),
              SOC: route("soc", {}),
              TRIP: route("trip", {}),
            },
          ),
          HISTORY: route(
            "history",
            {},
            {
              SOC: route("soc/:vehicleId", {}),
            },
          ),
          VEHICLE_WITH_DETAILS: route("vehicles-with-details", {}),
        },
      ),
      SCHEDULE: route(
        "schedule",
        {},
        {
          ROUTE: route("route/:routeId/:tripNumber/:reinforcementSequence", {
            params: {
              routeId: string(),
              tripNumber: string(),
              reinforcementSequence: string(),
            },
          }),
        },
      ),
      INCIDENT: route(
        "incident",
        {},
        {
          VEHICLE: route("vehicle/:vehicleId?", {
            params: {
              vehicleId: string(),
            },
          }),
        },
      ),
      QUALITY_MEASUREMENTS: route(
        "quality-measurements",
        {},
        {
          IMAGE_UPLOAD: route("image", {}),
          IMAGE_DELETE: route("image/:imageId?", {
            params: {
              imageId: string(),
            },
          }),
          IMAGE_GET: route("image/:imageId?", {
            params: {
              imageId: string(),
            },
          }),
          SAVE_ANSWER: route("answer", {}),
          QUESTIONNAIRE: route("questionnaire", {}),
        },
      ),
      OPERATIONAL_SUPPORT: route(
        "operational-support",
        {},
        {
          STORE_CURRENT_JOB: route("current-job", {}),
          SIGN_OFF: route("", {}),
          SUPPORT_STAFF: route("support-staff", {}),
        },
      ),
      BLOCK: route(
        "block/:blockId",
        {
          params: {
            blockId: string(),
          },
        },
        {
          BLOCK_ACTIVITIES: route("block-activities", {}),
        },
      ),
    },
  ),
};
