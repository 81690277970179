import { AutoTextSize } from "auto-text-size";
import { CSSProperties, ReactNode } from "react";
import styles from "./AutoSize.module.scss";

export interface AutoTextSizeProps {
  children: ReactNode;
  className?: string;
  mode?: "oneline" | "multiline" | "box";
  minFontSizePx?: number;
  maxFontSizePx?: number;
  style?: CSSProperties;
}

export const AutoSize = ({
  children,
  className,
  mode,
  minFontSizePx,
  maxFontSizePx,
  style,
}: AutoTextSizeProps) => {
  return (
    <AutoTextSize
      className={`${styles.text_no_overflow} ${className ?? ""}`}
      mode={mode || "oneline"}
      minFontSizePx={minFontSizePx || 12}
      maxFontSizePx={maxFontSizePx || 16}
      style={style}
    >
      {children}
    </AutoTextSize>
  );
};
