import { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu } from "components/menu/Menu";
import { Splashscreen } from "components/splashscreen/Splashscreen";
import { Content } from "features/layout/content/Content";
import { ROUTES } from "features/pages/Routes";
import { AllocateDatabase } from "features/pages/allocate/AllocateDatabase";
import { Login } from "features/pages/authentication/login/Login";
import { useAppDispatch } from "hooks/useRedux";
import "@id/frontend.components/dist/style.css";
import "@id/frontend.styling/dist/style.css";

export const App = () => {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    logout,
  } = useAuth0();

  const { setAuth0 } = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  // Set auth0 functions in redux store for use in other functions (not hooks/components).
  useEffect(() => {
    setAuth0({ getAccessTokenSilently, getAccessTokenWithPopup, logout });
  }, [getAccessTokenSilently, getAccessTokenWithPopup, logout]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (
      !isAuthenticated &&
      location.pathname !== ROUTES.AUTHENTICATION.LOGIN.path.toString()
    ) {
      navigate(ROUTES.AUTHENTICATION.LOGIN.path);
    }

    if (isAuthenticated) {
      navigate(ROUTES.AUTHENTICATION.ALLOCATE_DB.path);
    }
  }, [isAuthenticated, isLoading]);

  return !isAuthenticated ||
    isLoading ||
    (isAuthenticated &&
      location.pathname === ROUTES.AUTHENTICATION.ALLOCATE_DB.path) ? (
    <Splashscreen isLoading={isLoading}>
      <Routes>
        <Route path={ROUTES.AUTHENTICATION.LOGIN.path} element={<Login />} />
        <Route
          path={ROUTES.AUTHENTICATION.ALLOCATE_DB.path}
          element={<AllocateDatabase />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTES.AUTHENTICATION.LOGIN.path} />}
        />
      </Routes>
    </Splashscreen>
  ) : (
    <>
      <Content />
      <Menu />
    </>
  );
};
