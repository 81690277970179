import { t } from "i18next";
import { useLayoutEffect } from "react";
import { useAppDispatch } from "hooks/useRedux";
import { AutoSize } from "components/elements/autosize/AutoSize";

export const Page404 = ({ headerTitle }: { headerTitle?: string }) => {
  const { setTitle } = useAppDispatch();

  useLayoutEffect(() => {
    if (headerTitle) {
      setTitle(headerTitle || t("functional.not_found"));
    }
  }, []);

  return (
    <>
      <AutoSize>{t("functional.not_found")}</AutoSize>
    </>
  );
};
