import { ThemeConfig } from "antd";
import { createStyles } from "antd-style";
import { ModalConfig } from "antd/es/config-provider/context";

const modalTokens: ThemeConfig["components"] = {
  Modal: {
    contentBg: "rgb(73, 78, 81)",
    footerBg: "rgb(73, 78, 81)",
    headerBg: "rgb(73, 78, 81)",
    colorIcon: "rgb(255 255 255 / 80%)",
  },
};

export const useModalStyle = (): {
  modalStyles: ModalConfig;
  modalTokens: ThemeConfig["components"];
} => {
  const { styles } = createStyles(() => ({
    "qbuzz-modal": {
      ".qbuzz-modal-content": {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
      },
    },
  }))();

  return {
    modalTokens,
    modalStyles: {
      className: styles["qbuzz-modal"],
    },
  };
};
