import { ThemeConfig } from "antd";
import { createStyles } from "antd-style";
import { TableConfig } from "antd/es/config-provider/context";

const tableTokens: ThemeConfig["components"] = {
  Table: {
    colorBgContainer: "transparent",
    cellPaddingBlock: 8,
    cellPaddingInline: 8,
    colorTextDisabled: "rgb(255 255 255 / 80%)",
  },
};

export const useTableStyle = (): {
  tableStyles: TableConfig;
  tableTokens: ThemeConfig["components"];
} => {
  const { styles } = createStyles(() => ({
    "qbuzz-table": {},
  }))();

  return {
    tableTokens,
    tableStyles: {
      className: styles["qbuzz-table"],
    },
  };
};
