import BlockIcon from "assets/img/icons/svg/BlockIcon.svg";
import GoogleMapsIcon from "assets/img/icons/svg/GoogleMapsIcon.svg";
import { useVehicleInfo } from "hooks/react-query";

type CardItemVehicleSubMenuProps = {
  vehicleId: string;
};

export const CardItemVehicleSubMenu = ({
  vehicleId,
}: CardItemVehicleSubMenuProps) => {
  const vehicleGpsPosition =
    useVehicleInfo().useGetOneGpsByVehicleId(vehicleId);

  // TODO QID-6591: Gebruiker informeren over het feit dat de Google Maps knop niet werkt als er geen GPS data is
  const onGoogleMapsButtonClick = () => {
    const { latitude, longitude } = vehicleGpsPosition.data || {};
    if (!latitude || !longitude) {
      return;
    }

    const link = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(link, "_blank");
  };

  return (
    <>
      <button
        className={`${vehicleGpsPosition.isSuccess ? "" : "disabled"}`}
        onClick={() => onGoogleMapsButtonClick()}
      >
        <img src={GoogleMapsIcon} alt="pinpoint" />
      </button>

      <button className="disabled">
        <img src={BlockIcon} alt="pinpoint" />
      </button>
    </>
  );
};
