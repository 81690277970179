import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { Button, Typography } from "antd";
import { CardItemVehicle } from "components/elements/card/items/CardItemVehicle";
import { PageError } from "components/error/PageError";
import { Loader } from "components/loader/Loader";
import { ROUTES } from "features/pages/Routes";
import { isMatchingVehicle } from "helpers/VehicleMatcher";
import { useConcession, useVehicle, useVehicleInfo } from "hooks/react-query";
import { Vehicle } from "hooks/react-query/resources";
import { useAppDispatch } from "hooks/useRedux";
import styles from "./FleetVehicles.module.scss";

const SHOWN_VEHICLES_AMOUNT_INCREMENT = 50;

export const FleetVehicles = () => {
  const [shownVehiclesAmount, setShownVehiclesAmount] = useState<number>(
    SHOWN_VEHICLES_AMOUNT_INCREMENT,
  );

  const location = useLocation();
  const [fleetVehicleFilters] = useTypedSearchParams(ROUTES.FLEET.LIST);
  const { t } = useTranslation();

  const { setBackArrow } = useAppDispatch();

  const getConcessions = useConcession().useGetAll();
  const getVehicles = useVehicle().useGetAllVehiclesWithDetails();

  const filteredVehicles = (getVehicles.data || []).filter(
    (vehicleWithDetails) =>
      isMatchingVehicle(fleetVehicleFilters, vehicleWithDetails),
  );

  const socVehicleIds = getVehicles.data
    ?.filter(
      ({ vehicle }: { vehicle: Vehicle }) =>
        vehicle.vehicleModel.typeOfFuel === "ELECTRICITY",
    )
    .map(({ vehicle }: { vehicle: Vehicle }) => vehicle.id);

  const getVehicleInfoSoc =
    useVehicleInfo().useGetAllSocByVehicleIds(socVehicleIds);

  useEffect(() => {
    setBackArrow(false);

    return () => {
      setBackArrow(true);
    };
  }, []);

  if (getVehicles.error) {
    return <PageError reloadPage={true} />;
  }

  if (getVehicles.isLoading) {
    return <Loader />;
  }

  if (getVehicles.data?.length === 0) {
    return (
      <PageError
        reloadPage={false}
        errorText={t("pages.fleet.vehicles.no_vehicles_found")}
      />
    );
  }

  if (filteredVehicles.length === 0) {
    return (
      <PageError
        reloadPage={false}
        errorText={t("pages.fleet.vehicles.no_vehicles_found_with_filters")}
      />
    );
  }

  return (
    <div className={styles.fleet_vehicles}>
      {filteredVehicles
        .slice(0, shownVehiclesAmount)
        .map(({ currentBlockId, vehicle, incidents }) => (
          <CardItemVehicle
            concessionShortCode={
              getConcessions.data?.find(
                (concession) => concession.id === vehicle.concession,
              )?.shortCode
            }
            key={vehicle.id}
            origin={`${location.pathname}${location.search}`}
            vehicle={vehicle}
            vehicleIncidents={incidents?.filter(
              (incidents) => incidents.vehicleIds.indexOf(vehicle.id) >= 0,
            )}
            vehicleSoc={getVehicleInfoSoc.data?.find(
              ({ vehicleId }) => vehicle.id === vehicleId,
            )}
            vehicleTripInfo={{
              blockId: currentBlockId,
              vehicleId: vehicle.id,
            }}
          />
        ))}

      <div className={styles.fleet_vehicles_footer}>
        {shownVehiclesAmount < filteredVehicles.length && (
          <Button
            onClick={() => setShownVehiclesAmount(shownVehiclesAmount + 50)}
          >
            Meer voertuigen
          </Button>
        )}
        <Typography.Text className={styles.fleet_vehicles_footer_paginator}>
          {shownVehiclesAmount < filteredVehicles.length
            ? shownVehiclesAmount
            : filteredVehicles.length}{" "}
          / {filteredVehicles.length}
        </Typography.Text>
      </div>
    </div>
  );
};
