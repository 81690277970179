import { z } from "zod";

declare global {
  interface Window {
    _env_: EnvironmentVariables;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const environmentVariablesSchema = z.object({
  AUTH0_DOMAIN: z.string().min(1),
  AUTH0_CLIENT_ID: z.string().min(1),
  AUTH0_AUDIENCE: z.string().min(1),
  AUTH0_SCOPE: z.string().min(1),

  API_URL: z.string().min(1),

  MAPBOX_TOKEN: z.string().min(1),
  MAPBOX_STYLE_URL: z.string().min(1),

  DOCKER_IMAGE_VERSION: z.string().min(1),
  ENVIRONMENT: z.string().min(1),
  QIWI_ROUTER_URL: z.string().min(1),
});

export type EnvironmentVariables = z.infer<typeof environmentVariablesSchema>;

export const getEnvironmentVariable = (
  envKey: keyof EnvironmentVariables,
): string => {
  return window._env_[envKey];
};
