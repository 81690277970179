import { Auth0ContextInterface } from "@auth0/auth0-react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PayloadType {
  getAccessTokenSilently:
    | Auth0ContextInterface["getAccessTokenSilently"]
    | null;
  getAccessTokenWithPopup:
    | Auth0ContextInterface["getAccessTokenWithPopup"]
    | null;
  logout: Auth0ContextInterface["logout"] | null;
  returnTo: string | null;
}

const initialState: PayloadType = {
  getAccessTokenSilently: null,
  getAccessTokenWithPopup: null,
  logout: null,
  returnTo: null,
};

export const Auth0Reducer = createSlice({
  name: "Auth0Reducer",
  initialState: initialState,
  reducers: {
    setAuth0: (
      state: PayloadType,
      action: PayloadAction<Omit<PayloadType, "returnTo">>,
    ) => {
      state.getAccessTokenSilently = action.payload.getAccessTokenSilently;
      state.getAccessTokenWithPopup = action.payload.getAccessTokenWithPopup;
      state.logout = action.payload.logout;
    },
    setReturnTo: (state: PayloadType, action: PayloadAction<string>) => {
      state.returnTo = action.payload;
    },
  },
});

export const Auth0Actions = Auth0Reducer.actions;
