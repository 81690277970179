import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiOptions, IUseApiProps, useAsyncApi } from "helpers/useAsyncApi";
import { EndpointStatusEnum } from "../EndpointHelper";

const schema = z.any();
type postDataType = null;

export const QualityMeasurementsImageDeleteEndpoint = async (
  imageId: string,
) => {
  try {
    const options: IUseApiOptions = {
      method: "DELETE",
    };
    const endpointPath =
      ENDPOINTS.QONTROL.QUALITY_MEASUREMENTS.IMAGE_DELETE.buildPath({
        imageId: imageId,
      });
    const requestOptions: IUseApiProps<postDataType> = {
      endpoint: endpointPath,
      options: options,
      schema: schema,
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await useAsyncApi<postDataType, string>(requestOptions);

    if (response.status !== 200) {
      return { status: EndpointStatusEnum.ERROR, data: null };
    }

    return { status: EndpointStatusEnum.SUCCESS, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: EndpointStatusEnum.ERROR, data: null };
  }
};
