import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiState, useAsyncApi } from "helpers/useAsyncApi";

const RouteStopSchema = z.object({
  description: z.string(),
});

const RouteStopWrapperSchema = z.object({
  seqNr: z.number(),
  arrivalTime: z.coerce.date(),
  punctuality: z.number().optional(),
  stopId: z.string(),
  stop: RouteStopSchema,
});

const RouteSchema = z.object({
  routeId: z.string(),
  tripNumber: z.number(),
  stops: z.array(RouteStopWrapperSchema),
});

export type RouteStop = z.infer<typeof RouteStopSchema>;
export type RouteStopWrapper = z.infer<typeof RouteStopWrapperSchema>;
export type Route = z.infer<typeof RouteSchema>;

export class RouteResource {
  public getOne = async (
    routeId: string,
    tripNumber: string,
    reinforcementSequence: string,
  ): Promise<IUseApiState<Route>> => {
    return useAsyncApi<null, Route>({
      endpoint: ENDPOINTS.QONTROL.SCHEDULE.ROUTE.buildPath({
        routeId,
        tripNumber,
        reinforcementSequence,
      }),
      options: {
        method: "GET",
      },
      schema: RouteSchema,
    });
  };
}
