export const numberSort = (a?: number, b?: number) => {
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }

  return a - b;
};

export const textSort = (a?: string | null, b?: string | null) => {
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};
