import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { ToastProvider } from "@id/frontend.components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ROUTES } from "features/pages/Routes";
import { useAppDispatch } from "hooks/useRedux";
import { getEnvironmentVariable } from "helpers/EnvironmentVariableUtils";
import { ThemeProvider } from "./theme-provider/ThemeProvider";
import { App } from "../App";

/**
  React Query is not used here because it is not yet available this early in the page
  */
const logError = (errorEvent: ErrorEvent) => {
  const environment = getEnvironmentVariable("ENVIRONMENT");

  if (!!environment && environment !== "local") {
    const url = getEnvironmentVariable("QIWI_ROUTER_URL");

    fetch(`${url}/fe-error-logger`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        message: errorEvent.error.error,
        url: window.location.href,
        pageName: "qontrol",
        userAgent: navigator.userAgent,
        lineno: errorEvent.lineno,
        colno: errorEvent.colno,
        filename: errorEvent.filename,
      }),
    }).catch(console.error);
  }
};

export const returnToLocation = `${window.location.pathname}${window.location.search}`;
// eslint-disable-next-line react-refresh/only-export-components
const forbiddenReturnToRoutes = [
  ROUTES.DEFAULT.path.toString(),
  ROUTES.AUTHENTICATION.ALLOCATE_DB.path.toString(),
  ROUTES.AUTHENTICATION.LOGIN.path.toString(),
];

const queryClient = new QueryClient();

export const MainProvider = () => {
  const { setReturnTo } = useAppDispatch();
  const navigate = useNavigate();

  /**
   * @description This function is called when the user is redirected back to the app after authentication.
   * @param appState The state that was provided to the `loginWithRedirect` call (e.g. the returnTo route)
   */
  const onRedirectCallback = (appState?: AppState) => {
    if (
      appState?.returnTo &&
      !forbiddenReturnToRoutes.includes(appState?.returnTo)
    ) {
      setReturnTo(appState?.returnTo);
    }

    // Navigate to the app's login route, so allocate database can be called if needed.
    navigate(ROUTES.AUTHENTICATION.LOGIN.path);
  };

  useEffect(() => {
    if (!forbiddenReturnToRoutes.includes(returnToLocation)) {
      setReturnTo(returnToLocation);
    }
  }, []);

  useEffect(() => {
    const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
      logError(new ErrorEvent("unhandledrejection", { error: event.reason }));
    };

    window.addEventListener("error", logError);
    window.addEventListener("unhandledrejection", unhandledRejectionHandler);

    return () => {
      window.removeEventListener("error", logError);
      window.removeEventListener(
        "unhandledrejection",
        unhandledRejectionHandler,
      );
    };
  }, []);

  return (
    <Auth0Provider
      domain={getEnvironmentVariable("AUTH0_DOMAIN")}
      clientId={getEnvironmentVariable("AUTH0_CLIENT_ID")}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: getEnvironmentVariable("AUTH0_AUDIENCE"),
        scope: getEnvironmentVariable("AUTH0_SCOPE"),
      }}
      onRedirectCallback={onRedirectCallback}
      // https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/29
      useRefreshTokensFallback={true}
      useRefreshTokens={true}
      cacheLocation={"localstorage"}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ToastProvider>
            <App />
          </ToastProvider>
        </ThemeProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Auth0Provider>
  );
};
