import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useRef } from "react";
import { getEnvironmentVariable } from "helpers/EnvironmentVariableUtils";
import { LocalStorageUtils, LocalStorageKeys } from "helpers/LocalStorageUtils";
import { useMapEvents } from "hooks/mapbox/events/useMapEvent";
import styles from "./Mapbox.module.scss";
import { COORDINATES_CENTER_NETHERLANDS } from "constants/constants";

mapboxgl.accessToken = getEnvironmentVariable("MAPBOX_TOKEN");

export const Mapbox = ({
  customEventHooks,
}: {
  customEventHooks?: Array<typeof useMapEvents>;
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const { setMap: setMapForMapEvents } = useMapEvents();
  const setMapFunctions = customEventHooks?.map(
    (customEventHook) => customEventHook().setMap,
  );
  const map = useRef<mapboxgl.Map | null>(null);

  useEffect(() => {
    if (map.current) {
      return;
    }

    const coordinates =
      LocalStorageUtils.getLocalStorage(
        LocalStorageKeys.LAST_MAP_COORDINATES,
      ) || COORDINATES_CENTER_NETHERLANDS;
    const zoom =
      LocalStorageUtils.getLocalStorage(LocalStorageKeys.LAST_MAP_ZOOM) || 6;
    const pitch =
      LocalStorageUtils.getLocalStorage(LocalStorageKeys.LAST_MAP_PITCH) || 0;

    map.current = new mapboxgl.Map({
      container: mapContainer.current as HTMLElement,
      style: getEnvironmentVariable("MAPBOX_STYLE_URL"),
      center: coordinates,
      zoom: zoom,
      pitch: pitch,
      bearing: 0,
      attributionControl: false,
      trackResize: true,
      projection: { name: "globe" },
    });

    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    map.current.on("load", () => {
      setMapForMapEvents(map.current);

      setMapFunctions?.forEach((setMapFunction) => {
        setMapFunction(map.current);
      });
    });
  }, []);

  return (
    <div
      ref={mapContainer}
      className={`${styles.mapbox_container} mapboxgl-map`}
    />
  );
};
