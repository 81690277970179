import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageResizerUtil } from "helpers/ImageResizerUtil";
import { AutoSize } from "components/elements/autosize/AutoSize";
import { Button } from "components/elements/button/Button";
import { Loader } from "components/loader/Loader";
import styles from "./Upload.module.scss";

interface UploadProps {
  identifier: string;
  className?: string;
  visible: boolean;
  setVisibility: (visible: boolean) => void;
  onUploaded?: ({ id, images }: { id: string; images: File[] }) => void;
  images?: string;
}

export const Upload = ({
  identifier,
  className,
  visible,
  setVisibility,
  onUploaded,
  images,
}: UploadProps) => {
  const { t } = useTranslation();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [imageInputValue, setImageInputValue] = useState(images ?? "");
  const [numberOfImages, setNumberOfImages] = useState(0);
  const [numberOfResizedImages, setNumberOfResizedImages] = useState(0);
  const [isResizing, setIsResizing] = useState(false);

  const triggerUpload = () => {
    fileUploadRef.current?.click();
  };

  const onImageInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);

      setIsResizing(true);
      setNumberOfImages(files.length);
      const resizedFiles = await Promise.all(
        files.map(async (file) => {
          const resizedFile = await ImageResizerUtil.resize(file);

          setNumberOfResizedImages((prev) => prev + 1);
          return resizedFile;
        }),
      );

      setIsResizing(false);
      setNumberOfImages(0);
      setNumberOfResizedImages(0);
      setImageInputValue("");

      setVisibility(false);
      onUploaded?.({
        id: identifier,
        images: resizedFiles.filter((file) => file !== null),
      });
    }
  };

  const closeUploader = () => {
    if (isResizing) {
      return;
    }

    setVisibility(false);
  };

  useEffect(() => {
    setImageInputValue(images ?? "");
  }, [images]);

  return (
    <div
      className={`${styles.upload_container} ${className} ${visible ? "" : styles.hidden}`}
    >
      <div className={styles.background} onClick={closeUploader} />

      <div className={styles.uploader}>
        {isResizing && (
          <div className={styles.upload_status}>
            <Loader centerInPage={false} />
            <div className={styles.text}>
              {t("uploader.upload_progress", {
                multiple: numberOfImages > 1 ? "(s)" : "",
              })}{" "}
              <br />
              {`${numberOfResizedImages} / ${numberOfImages}`}
            </div>
          </div>
        )}

        {!isResizing && (
          <Button
            onClickEvent={triggerUpload}
            background="standard"
            className={styles.upload_button}
          >
            <AutoSize minFontSizePx={15}>
              {t("uploader.select_button")}
            </AutoSize>
          </Button>
        )}
      </div>

      <input
        type="file"
        ref={fileUploadRef}
        multiple={true}
        value={imageInputValue}
        onChange={onImageInputChange}
      />
    </div>
  );
};
