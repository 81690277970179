import { ReactNode, RefObject } from "react";
import styles from "./ButtonWithNumber.module.scss";

interface IButtonWithNumberProps {
  backgroundColor?: "normal";
  children?: ReactNode;
  disabled: boolean;
  onClickEvent?: () => void;
  refItem?: RefObject<HTMLButtonElement>;
  value?: number;
}

export const ButtonWithNumber = ({
  backgroundColor = "normal",
  children,
  disabled = true,
  onClickEvent,
  refItem,
  value,
}: IButtonWithNumberProps) => {
  return (
    <button
      className={styles.button_with_number}
      disabled={disabled}
      onClick={onClickEvent}
      ref={refItem}
    >
      {children}
      {value && (
        <div
          className={`${styles.number} ${("" + value).length == 2 ? styles.two_digits : ""} ${backgroundColor}`}
        >
          {value}
        </div>
      )}
    </button>
  );
};
