import { useEffect, useState } from "react";
import { EndpointStatusEnum } from "hooks/collectors/endpoints/EndpointHelper";
import { QualityMeasurementsImageGetEndpoint } from "hooks/collectors/endpoints/quality-measurement/QualityMeasurementsImageGetEndpoint";
import { IAnswer, IQuestion } from "state/reducers/QuestionnaireReducer";
import { ScrollElement } from "@id/frontend.components";

interface QKCAImagesProps {
  question: IQuestion;
  currentAnswer: IAnswer;
  removeImage: (params: { questionId: string; imageId: string }) => void;
}

interface Images {
  image: string;
  imageId: string;
}

export const QuestionnaireImages = ({
  question,
  currentAnswer,
  removeImage,
}: QKCAImagesProps) => {
  const [images, setImages] = useState<Images[]>([]);

  useEffect(() => {
    const getImages = async () => {
      const imageIds = currentAnswer.imageIds;
      setImages((prev) =>
        prev.filter((image) => imageIds?.includes(image.imageId)),
      );

      if (!currentAnswer.imageIds) {
        return null;
      }
      for (const imageId of currentAnswer.imageIds) {
        if (!imageId || imageId === "") {
          continue;
        }

        const response = await QualityMeasurementsImageGetEndpoint(imageId);
        if (response.status !== EndpointStatusEnum.SUCCESS || !response.data) {
          return null;
        }

        setImages((prev) => {
          if (prev.find((image) => image.imageId === imageId)) {
            return prev;
          }

          return [
            ...prev,
            {
              image: URL.createObjectURL(response.data),
              imageId: imageId,
            },
          ];
        });
      }
    };

    getImages();
  }, [currentAnswer]);

  if (!currentAnswer?.imageIds || currentAnswer.imageIds.length === 0) {
    return null;
  }

  return (
    <ScrollElement
      className="images"
      scrollDirection="horizontal"
      scrollBarVisible={true}
    >
      {images.map((image, index) => {
        if (!image) {
          return null;
        }

        return (
          <div key={index} className="image">
            <img src={image.image} alt="Image" />
            <button
              className="delete_button"
              type="button"
              onClick={() =>
                removeImage({
                  questionId: question.questionId,
                  imageId: image.imageId,
                })
              }
            >
              <i className="q-icons">Ê</i>
            </button>
          </div>
        );
      })}
    </ScrollElement>
  );
};
