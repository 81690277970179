import { Severity, useToasts } from "@id/frontend.components";
import { Modal } from "antd";
import { db } from "assets/database/Database";
import { QualityMeasurementImageDeleteUtil } from "helpers/QualityMeasurementImageDeleteUtil";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface DeleteConceptModalProps {
  deleteConceptModalOpen: boolean;
  deleteConceptId: string | undefined;
  setDeleteConceptModalOpen: (value: boolean) => void;
  onDeleteSuccess: () => void;
}

export const DeleteConceptModal = ({
  deleteConceptModalOpen,
  deleteConceptId,
  setDeleteConceptModalOpen,
  onDeleteSuccess,
}: DeleteConceptModalProps) => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  const { addToast } = useToasts();

  const onDelete = async () => {
    if (!deleteConceptId) {
      return;
    }

    const concept = await db.qualityMeasurementConceptsTable.get({
      conceptId: deleteConceptId,
    });
    if (!concept) {
      return;
    }

    setIsDeleting(true);

    // Make sure to cleanup old images in our backend, if they are not longer used.
    const imageIds = concept.answerList.answers
      .map((answer) => answer.imageIds)
      .filter((imageId) => !!imageId)
      .flat() as string[];
    await QualityMeasurementImageDeleteUtil.deleteImages(imageIds);
    await db.qualityMeasurementConceptsTable.delete(deleteConceptId);

    onDeleteSuccess();
    addToast(
      t("pages.quality_measurements.list.concepts.delete.deleted"),
      Severity.Success,
    );
    setIsDeleting(false);
  };

  const onCancel = () => {
    setDeleteConceptModalOpen(false);
  };

  return (
    <Modal
      open={deleteConceptModalOpen}
      title={t("pages.quality_measurements.list.concepts.delete.title")}
      onOk={onDelete}
      cancelText={t("default.cancel")}
      okText={t("default.delete")}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: isDeleting }}
      closable={isDeleting ? false : true}
      maskClosable={isDeleting ? false : true}
      confirmLoading={isDeleting}
    >
      <p>{t("pages.quality_measurements.list.concepts.delete.text")}</p>
    </Modal>
  );
};
