// colorTextPlaceholder
import { ThemeConfig } from "antd";
import { createStyles } from "antd-style";
import { InputConfig } from "antd/es/config-provider/context";

const inputTokens: ThemeConfig["components"] = {
  Input: {
    colorTextPlaceholder: "rgba(255, 255, 255, 0.8)",
  },
};

export const useInputStyle = (): {
  inputStyles: InputConfig;
  inputTokens: ThemeConfig["components"];
} => {
  const { styles } = createStyles(() => ({
    "qbuzz-input": {},
  }))();

  return {
    inputTokens,
    inputStyles: {
      className: styles["qbuzz-input"],
    },
  };
};
