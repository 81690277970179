import { ReactNode, RefObject } from "react";
import styles from "./ButtonWithLine.module.scss";

interface IButtonWithTimestampProps {
  className?: string;
  disabled: boolean;
  refItem?: RefObject<HTMLButtonElement>;
  onClickEvent?: () => void;
  active: boolean;
  children?: ReactNode;
}

export const ButtonWithLine = ({
  className = "",
  disabled = true,
  refItem,
  active,
  onClickEvent,
  children,
}: IButtonWithTimestampProps) => {
  return (
    <div className={styles.button_with_line_container}>
      <button
        className={`${className}${active ? "active" : ""}`}
        disabled={disabled}
        ref={refItem}
        onClick={onClickEvent}
      >
        {children}
      </button>
    </div>
  );
};
