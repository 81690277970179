import { useEffect, useState } from "react";
import "./TextInput.scss";
import { Explanation } from "features/pages/quality-measurements/items/questions/elements/Explanation";

interface ITextInputProps {
  type:
    | "INPUT"
    | "TEXTAREA"
    | "NUMBER"
    | "EMAIL"
    | "TEL"
    | "DATE"
    | "TIME"
    | undefined;
  text: string;
  placeholder: string;
  onChange: (value: string) => void;
  onReset: () => void;
  value: string;
  min?: number;
  max?: number;
  required?: boolean;
  regexFilter?: RegExp;
  explanation?: string;
}

export const TextInput = ({
  type,
  text,
  onChange,
  onReset,
  value,
  min,
  max,
  required = true,
  regexFilter,
  explanation,
}: ITextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => setInputValue(value), [value]);

  const onChangeInput = (value: string) => {
    const filteredValue = (
      regexFilter ? value.replace(regexFilter, "") : value
    ).substring(0, max ?? value.length);
    setInputValue(filteredValue);
    onChange(filteredValue);
  };

  const onResetInput = () => {
    onChangeInput("");
    onReset();
  };

  return (
    <div
      className={`text_input_container ${type} ${isFocused || inputValue ? "focused" : ""}`}
    >
      <label className={`label ${type === "TEXTAREA" ? "top" : ""}`}>
        {text}
      </label>
      <div className="inputs">
        {type === "TEXTAREA" ? (
          <textarea
            value={inputValue}
            onChange={(e) => onChangeInput(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            required={required}
            className={`input textarea ${isFocused || inputValue ? "focused" : ""}`}
          />
        ) : (
          <input
            type={type}
            value={inputValue}
            onChange={(e) => onChangeInput(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            required={required}
            min={min ?? undefined}
            max={max ?? undefined}
            className={`input ${isFocused || inputValue ? "focused" : ""}`}
          />
        )}
        {inputValue.length > 0 && (
          <input
            type="reset"
            value=""
            onClick={() => onResetInput()}
            className="reset hover_class"
          />
        )}
      </div>
      {explanation != undefined ? <Explanation text={explanation} /> : null}
    </div>
  );
};
