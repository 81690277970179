import { QuestionnaireReducer } from "./reducers/QuestionnaireReducer";
import { combineReducers } from "redux";
import { AppStateReducer } from "./reducers/AppStateReducer";
import { Auth0Reducer } from "./reducers/Auth0Reducer";
import { FilterHeaderReducer } from "./reducers/FilterHeaderReducer";
import { MapboxReducer } from "./reducers/MapboxReducer";

export const RootReducer = combineReducers({
  FilterHeaderReducer: FilterHeaderReducer.reducer,
  Auth0Reducer: Auth0Reducer.reducer,
  MapboxReducer: MapboxReducer.reducer,
  AppStateReducer: AppStateReducer.reducer,
  QuestionnaireReducer: QuestionnaireReducer.reducer,
});

export type RootState = ReturnType<typeof RootReducer>;
