import mapboxgl from "mapbox-gl";
import { vehicleGPSSourceId } from "hooks/mapbox/events/useFleetVehicleMapGPSEvents";
import { vehicleRouteSourceId } from "hooks/mapbox/events/useFleetVehicleMapRouteEvents";

export const vehicleGPSLayerIds = {
  layer_vehicle_background: "vehicle-gps-layer-background-card",
  layer_vehicle_background_black: "vehicle-gps-layer-background-card_black",
  layer_vehicle_background_soc: "vehicle-gps-layer-background-card_soc",
  layer_vehicle_background_soc_black:
    "vehicle-gps-layer-background-card_soc_black",
  layer_vehicle_icon: "vehicle-gps-layer-vehicle-icon",
  vehicle_icon_bus: "vehicle-gps-layer-vehicle-icon-bus",
  vehicle_icon_tram: "vehicle-gps-layer-vehicle-icon-tram",
  vehicle_icon_train: "vehicle-gps-layer-vehicle-icon-train",
  vehicle_icon_boat: "vehicle-gps-layer-vehicle-icon-boat",
  soc_background_blue: "vehicle-gps-layer-soc-background-blue",
  soc_background_red: "vehicle-gps-layer-soc-background-red",
  soc_background_gray: "vehicle-gps-layer-soc-background-gray",
  soc_background_orange: "vehicle-gps-layer-soc-background-orange",
  soc_background_yellow: "vehicle-gps-layer-soc-background-yellow",
  soc_background_green: "vehicle-gps-layer-soc-background-green",
  text: "vehicle-gps-layer-text",

  soc_text: "vehicle-gps-layer-soc-text",
  soc_background: "vehicle-gps-layer-soc-background",
  gps_timestamp_text: "vehicle-gps-layer-gps-timestamp-text",

  route_arrow_image: "vehicle-gps-layer-route-arrow-image",
  route_arrow_layer: "vehicle-gps-layer-route-arrow-layer",
  route_line_layer: "vehicle-gps-layer-route-line-layer",
};

export const addDefaultLayers = (map: mapboxgl.Map) => {
  map.addLayer({
    id: vehicleGPSLayerIds.layer_vehicle_background,
    type: "symbol",
    source: vehicleGPSSourceId,
    minzoom: 4,
    paint: {
      "icon-opacity": 1,
    },
    layout: {
      "icon-offset": [0, -114],
      "icon-image": "{vehicleBackground}",
      "icon-size": {
        stops: [
          [0, 0.03],
          [20, 0.4],
        ],
      },
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
      "icon-anchor": "center",
      "icon-padding": 0,
    },
  });

  // Add a layer for the text in the middle
  map.addLayer({
    id: vehicleGPSLayerIds.layer_vehicle_icon,
    type: "symbol",
    source: vehicleGPSSourceId,
    minzoom: 4,
    layout: {
      "icon-image": "{vehicleIcon}",
      "icon-offset": [
        "case",
        ["==", ["get", "socEnabled"], true],
        ["literal", [0, -88]],
        ["literal", [0, -104]],
      ],
      "icon-size": {
        stops: [
          [0, 0.05],
          [20, 0.6],
        ],
      },
      "icon-allow-overlap": true,
      "icon-ignore-placement": false,
      "icon-padding": 0,
      "icon-optional": true,
    },
  });

  map.addLayer({
    id: vehicleGPSLayerIds.text,
    type: "symbol",
    source: vehicleGPSSourceId,
    minzoom: 11,
    paint: {
      "text-color": "#FFFFFF",
    },
    layout: {
      "text-offset": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        [
          "case",
          ["==", ["get", "socEnabled"], true],
          ["literal", [0, -1.95]],
          ["literal", [0, -2.35]],
        ],
        20,
        [
          "case",
          ["==", ["get", "socEnabled"], true],
          ["literal", [0, -1.95]],
          ["literal", [0, -2.5]],
        ],
      ],
      "text-field": "{vehicleId}", // Set the text you want to display
      "text-size": {
        stops: [
          [0, 1.5],
          [20, 14.2],
        ],
      },
      "text-padding": 0,
      "text-anchor": "center", // Align the text to the center
      "text-font": ["Roboto Bold"], // Set the font family
    },
  });

  map.addLayer({
    id: vehicleGPSLayerIds.soc_background,
    type: "symbol",
    minzoom: 4,
    source: vehicleGPSSourceId,
    paint: {
      "icon-opacity": 0.7,
    },
    layout: {
      "icon-image": "{socCardBackground}",
      "icon-offset": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        ["literal", [0, -205]],
        20,
        ["literal", [0, -215]],
      ],
      "icon-size": {
        stops: [
          [0, 0.03],
          [20, 0.38],
        ],
      },
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
      "icon-padding": 0,
      "icon-optional": false,
    },
    filter: ["==", "socEnabled", true],
  });

  map.addLayer({
    id: vehicleGPSLayerIds.soc_text,
    type: "symbol",
    source: vehicleGPSSourceId,
    minzoom: 11,
    paint: {
      "text-color": "#FFFFFF",
    },
    layout: {
      "text-offset": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        ["literal", [0, -5.26]],
        10,
        ["literal", [0, -5.85]],
        15,
        ["literal", [0, -6.02]],
        20,
        ["literal", [0, -6.12]],
      ],
      "text-field": "{soc}",
      "text-size": {
        stops: [
          [0, 1.5],
          [20, 13],
        ],
      },
      "text-allow-overlap": true,
      "text-ignore-placement": true,
      "text-anchor": "center", // Align the text to the center
      "text-font": ["Roboto Bold"], // Set the font family
    },
    filter: ["==", "socEnabled", true],
  });
};

export const addRouteLayers = (map: mapboxgl.Map) => {
  if (!map) {
    return;
  }

  if (!map.getLayer(vehicleGPSLayerIds.route_line_layer)) {
    map.addLayer(
      {
        id: vehicleGPSLayerIds.route_line_layer,
        type: "line",
        source: vehicleRouteSourceId,
        paint: {
          "line-color": "rgba(0,0,255,0.5)",
          "line-width": ["interpolate", ["linear"], ["zoom"], 10, 5, 22, 20],
          "line-opacity": 0.8,
        },
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
      },
      map.getLayer(vehicleGPSLayerIds.layer_vehicle_background)
        ? vehicleGPSLayerIds.layer_vehicle_background
        : undefined,
    );
  }

  if (!map.getLayer(vehicleGPSLayerIds.route_arrow_layer)) {
    map.addLayer(
      {
        id: vehicleGPSLayerIds.route_arrow_layer,
        type: "symbol",
        source: vehicleRouteSourceId,
        layout: {
          "icon-image": vehicleGPSLayerIds.route_arrow_image,
          "symbol-placement": "line",
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "symbol-spacing": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0,
            22,
            200,
          ],
          "icon-padding": 0,
          "icon-size": 0.39,
        },
        paint: {
          "icon-opacity": 1,
        },
      },
      map.getLayer(vehicleGPSLayerIds.layer_vehicle_background)
        ? vehicleGPSLayerIds.layer_vehicle_background
        : undefined,
    );
  }
};
