import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiOptions, useAsyncApi } from "helpers/useAsyncApi";
import { EndpointStatusEnum } from "./EndpointHelper";
import { IQuestionnaire } from "state/reducers/QuestionnaireReducer";

const typesSchema = z.array(
  z.object({
    questionnaireId: z.string(),
    title: z.string(),
    version: z.number(),
    validFrom: z.string(),
    validTo: z.string(),
    questions: z.array(
      z.object({
        questionId: z.string(),
        text: z.string(),
        type: z.string(),
        order: z.number(),
        parentQuestionId: z.optional(z.string()),
        inputPlaceholder: z.optional(z.string()),
        required: z.optional(z.boolean()),
        imageRequired: z.optional(z.boolean()),
        commentRequired: z.optional(z.boolean()),
        options: z.optional(
          z.array(
            z.object({
              optionId: z.string(),
              text: z.string(),
              order: z.number(),
            }),
          ),
        ),
      }),
    ),
  }),
);

export const QuestionnairesEndpoint = async () => {
  try {
    const options: IUseApiOptions = {
      method: "GET",
    };
    const endpointPath =
      ENDPOINTS.QONTROL.QUALITY_MEASUREMENTS.QUESTIONNAIRE.path;
    const requestOptions = {
      endpoint: endpointPath,
      options: options,
      schema: typesSchema,
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await useAsyncApi<null, IQuestionnaire[]>(requestOptions);

    if (!response.data) {
      return { status: EndpointStatusEnum.NO_DATA, data: null };
    }

    if (response.status !== 200) {
      return { status: EndpointStatusEnum.ERROR, data: null };
    }

    return { status: EndpointStatusEnum.SUCCESS, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: EndpointStatusEnum.ERROR, data: null };
  }
};
