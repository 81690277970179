import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import BusIcon from "assets/img/icons/svg/BusIcon.svg";
import ContactIcon from "assets/img/icons/svg/ContactIcon.svg";
import MapIcon from "assets/img/icons/svg/MapIcon.svg";
import MenuIcon from "assets/img/icons/svg/MenuIcon.svg";
import { ROUTES } from "features/pages/Routes";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import styles from "./Menu.module.scss";
import { MenuItem } from "./MenuItem";
import { useUser } from "hooks/react-query";
import { Role } from "hooks/react-query/resources";

export interface MenuItemProps {
  name: string;
  icon: string | null;
  enabled: boolean;
  active: boolean;
  role?: string;
  // pathPrefix is used to check if the current location starts with the pathPrefix.
  pathPrefix?: string;
  path: string;
  multiplePaths?: string[];
  onClickEvent?: () => void;
}

export const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setTitle } = useAppDispatch();
  const { menuVisible } = useAppSelector((state) => state.AppStateReducer);
  const { t } = useTranslation();
  const { setSideMenuVisible } = useAppDispatch();
  const getUser = useUser().useGetUser();
  const defaultMenuItems: MenuItemProps[] = [
    {
      name: t("menu.Menu"),
      icon: MenuIcon,
      enabled: true,
      active: false,
      path: "",
      onClickEvent: () => setSideMenuVisible(true),
    },
    {
      name: t("menu.Vloot"),
      icon: BusIcon,
      enabled: true,
      active: true,
      pathPrefix: ROUTES.FLEET.LIST.buildPath({}),
      path: ROUTES.FLEET.LIST.buildPath({}),
    },
    {
      name: t("menu.Kaart"),
      icon: MapIcon,
      enabled: true,
      active: false,
      pathPrefix: ROUTES.MAP.buildPath({}),
      path: ROUTES.MAP.buildPath({}),
    },
    {
      name: t("menu.Contact"),
      icon: ContactIcon,
      role: Role.SUPPORT_STAFF,
      enabled: true,
      active: false,
      pathPrefix: ROUTES.CONTACT.buildPath({}),
      path: ROUTES.CONTACT.buildPath({}),
    },
  ];

  const [menuItems, setMenuItems] = useState(defaultMenuItems);

  useEffect(() => {
    const locationPath = `${location.pathname}`;
    const selectedMenuItem = menuItems.find(
      (item) =>
        item.multiplePaths?.includes(locationPath) ||
        (item.pathPrefix && locationPath.startsWith(item.pathPrefix)),
    );
    if (!selectedMenuItem) {
      return;
    }

    setMenuItems(
      menuItems.map((menuItem) => ({
        ...menuItem,
        active: menuItem.name === selectedMenuItem.name,
      })),
    );

    setTitle(selectedMenuItem.name);
  }, [location.pathname]);

  const onClick = (menu: MenuItemProps) => {
    if (!menu.enabled) {
      return;
    }

    if (menu.onClickEvent) {
      menu.onClickEvent();
      return;
    }

    navigate(menu.path);
  };

  if (!menuVisible) {
    return null;
  }

  return (
    <footer className={styles.footer_menu}>
      {menuItems
        .filter((menuItem) =>
          getUser.isSuccess && menuItem.role
            ? getUser.data.roles.includes(menuItem.role)
            : true,
        )
        .map((item) => {
          return (
            <MenuItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              enabled={item.enabled}
              active={item.active}
              path={item.path}
              onClickEvent={() => onClick(item)}
            />
          );
        })}
    </footer>
  );
};
