import * as echarts from "echarts";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "components/elements/button/Button";
import { ButtonWithLine } from "components/elements/button/ButtonWithLine";
import { DateUtils } from "helpers/DateUtils";
import { EndpointStatusEnum } from "hooks/collectors/endpoints/EndpointHelper";
import {
  VehicleHistorySocEndpoint,
  VehicleHistorySocType,
} from "hooks/collectors/endpoints/VehicleHistorySocEndpoint";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import styles from "./SocPopup.module.scss";
import { useChargePointCollector } from "hooks/collectors/useChargePointCollector";
import { useTranslation } from "react-i18next";

export const SocPopup = () => {
  const socChart = useRef<echarts.ECharts | undefined>(undefined);
  const socData = useRef<VehicleHistorySocType[] | undefined>(undefined);
  const [hoursAgo, setHoursAgo] = useState<number>(1);
  const [isOpen, setIsOpen] = useState(false);
  const chartRef = useRef<HTMLDivElement>(null);
  const { socPopupVisible, socPopupData } = useAppSelector(
    (state) => state.AppStateReducer,
  );
  const { setSocPopupVisible, setSocPopupData } = useAppDispatch();
  const { t } = useTranslation();

  const chargePointFilter = useMemo(() => {
    if (!socPopupData || !socPopupData.vehicleId) {
      return undefined;
    }

    return [
      {
        id: socPopupData.vehicleId,
        types: ["soc"],
      },
    ];
  }, [socPopupData]);

  const { data: chargePointData, refresh: chargePointRefresh } =
    useChargePointCollector({
      cacheOnly: false,
      filterOnly: true,
      filter: chargePointFilter,
    });

  const resizeListener = () => {
    socChart.current?.resize();
  };

  const fetchSocData = async () => {
    if (!socPopupData.vehicleId) {
      return;
    }

    chargePointRefresh();
    const { status, data } = await VehicleHistorySocEndpoint(
      socPopupData.vehicleId,
    );

    if (status !== EndpointStatusEnum.SUCCESS) {
      return;
    }

    if (!data || data.length === 0) {
      return;
    }

    socData.current = data;
  };

  const setChartInstance = () => {
    if (!chartRef.current) {
      return;
    }

    socChart.current = echarts.init(chartRef.current);
  };

  const loadSocData = async (force = false, showLoading = true) => {
    if (!chartRef.current || !socPopupData.vehicleId) {
      return;
    }

    if (showLoading) {
      socChart.current?.showLoading({
        text: "Laden...",
        textColor: "#ffffff",
        maskColor: "transparent",
      });
    }

    if (!socData.current || force) {
      chargePointRefresh();
      await fetchSocData();
    }

    const date = Date.now();
    const filteredSocData = socData.current?.filter((item) => {
      return item.time > date - hoursAgo * 60 * 60 * 1000;
    });

    const xAxisMap = filteredSocData?.map((item) => {
      return DateUtils.getLocaleTimeFromDate(new Date(item.time));
    });

    const yAxisMap = filteredSocData?.map((item) => {
      return item.value;
    });

    const option = {
      title: {
        show: !xAxisMap || xAxisMap.length === 0,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: t("functional.no_data"),
        left: "center",
        top: "center",
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: xAxisMap,
        axisLabel: {
          rotate: 45,
        },
      },
      yAxis: {
        type: "value",
      },
      tooltip: {
        trigger: "axis",
      },
      series: [
        {
          data: yAxisMap,
          type: "line",
          areaStyle: {
            color: "rgba(89, 152, 74, 0.2)",
          },
          lineStyle: {
            color: "rgba(89, 152, 74, 1)",
            width: 3,
          },
          itemStyle: {
            color: "rgba(255,255,255,1)",
          },
        },
      ],
      textStyle: {
        color: "#fff",
      },
    };

    socChart.current?.setOption(option);
    socChart.current?.hideLoading();

    window.removeEventListener("resize", resizeListener);
    window.addEventListener("resize", resizeListener);
  };

  useEffect(() => {
    if (!chartRef.current || !socPopupData.vehicleId) {
      return;
    }

    setChartInstance();
    loadSocData();

    return () => {
      socChart.current?.dispose();
      window.removeEventListener("resize", resizeListener);
    };
  }, [socPopupData, hoursAgo]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadSocData(true, false);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [socPopupData, hoursAgo]);

  useEffect(() => {
    if (!socPopupVisible) {
      closePopup();
      return;
    }

    openPopup();
  }, [socPopupVisible]);

  const openPopup = () => {
    setIsOpen(true);

    setHoursAgo(1);
    socData.current = undefined;
  };

  const closePopup = () => {
    setIsOpen(false);
    setSocPopupVisible(false);
    setSocPopupData({ vehicleId: null });

    setHoursAgo(1);
    socData.current = undefined;
  };

  const currentSocValue = useMemo(() => {
    if (!chargePointData || chargePointData.length === 0) {
      return undefined;
    }

    const vehicleChargePointData = chargePointData?.find(
      (chargePoint) => chargePoint.id === socPopupData?.vehicleId,
    );
    return vehicleChargePointData?.value?.soc?.value;
  }, [chargePointData]);

  return (
    <div className={styles.soc_popup_container}>
      <div
        className={`${styles.background} ${isOpen ? styles.open : ""}`}
        onClick={closePopup}
      ></div>
      <div className={`${styles.popup} ${isOpen ? styles.open : ""}`}>
        <div className={styles.header}>
          <div className={styles.icon}>
            <i className=".q-icons">o</i>
          </div>

          <div className={styles.spacer}></div>

          <div className={styles.time_buttons}>
            <ButtonWithLine
              onClickEvent={() => setHoursAgo(1)}
              disabled={false}
              active={hoursAgo === 1}
            >
              1h
            </ButtonWithLine>
            <ButtonWithLine
              onClickEvent={() => setHoursAgo(3)}
              disabled={false}
              active={hoursAgo === 3}
            >
              3h
            </ButtonWithLine>
            <ButtonWithLine
              onClickEvent={() => setHoursAgo(24)}
              disabled={false}
              active={hoursAgo === 24}
            >
              24h
            </ButtonWithLine>
          </div>

          <Button
            className={styles.reload_button}
            onClickEvent={() => loadSocData(true)}
            enabled={true}
          >
            <i className="q-icons">i</i>
          </Button>

          <div className={styles.spacer}></div>

          <Button
            className={styles.close_button}
            onClickEvent={closePopup}
            enabled={true}
          >
            <i className="q-icons">Í</i>
          </Button>
        </div>

        <div className={styles.vehicle_id}>
          {DateUtils.getLocaleTimeFromDate(new Date())} |&nbsp;
          {currentSocValue ? `${currentSocValue}% | ` : ""}
          {t("terminology.vehicle")}: {socPopupData.vehicleId || "Onbekend"}
        </div>

        <div className={styles.chart} ref={chartRef}></div>
      </div>
    </div>
  );
};
