import { ChangeEvent, RefObject, useEffect, useRef } from "react";
import DropdownIcon from "assets/img/icons/svg/DropdownIcon.svg";
import { Button } from "components/elements/button/Button";
import { IListItems } from "features/layout/header/filter/FilterElement";
import "./FilterButtonDropdown.scss";

type FilterButtonDropdownProps = {
  type?: "list" | "datepicker";
  title: string;
  contentText: string;
  listItems: IListItems[];
  onButtonClickEvent?: (listRef: RefObject<HTMLDivElement>) => void;
  onListItemClickEvent?: (id: string) => void;
  onDatePickerChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const FilterButtonDropdown = ({
  type = "list",
  title,
  contentText,
  listItems,
  onButtonClickEvent,
  onListItemClickEvent,
  onDatePickerChange,
}: FilterButtonDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const pickerRef = useRef<HTMLInputElement>(null);

  const onButtonClick = () => {
    dropdownRef?.current
      ?.querySelector(".dropdown_button")
      ?.classList.toggle("no_bottom_border_radius");
    listRef?.current?.classList.toggle("dropdown_list_visible");

    onButtonClickEvent?.(listRef);
  };

  const onItemClick = (id: string) => {
    closeDropdown();
    onListItemClickEvent?.(id);
  };

  const closeDropdown = () => {
    dropdownRef?.current
      ?.querySelector(".dropdown_button")
      ?.classList.remove("no_bottom_border_radius");
    listRef?.current?.classList.remove("dropdown_list_visible");
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as HTMLElement)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchend", handleClickOutside);
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("wheel", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("wheel", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <Button className="dropdown_button" onClickEvent={onButtonClick}>
        <span className="filter_item_text">
          {title}: {contentText}
        </span>
        <span className="filter_item_icon">
          <img src={DropdownIcon} alt="Dropdown" />
        </span>
      </Button>

      <div className="dropdown_list" ref={listRef}>
        {type === "list" &&
          listItems.map((item) => {
            return (
              <button
                className={`item${item.active ? " item_selected" : ""}`}
                key={`${item.key}`}
                onClick={() => onItemClick(item.value)}
              >
                <span className="item_text">{item.text}</span>
              </button>
            );
          })}

        {type === "datepicker" && (
          <input
            ref={pickerRef}
            className="date_picker"
            type="date"
            onChange={onDatePickerChange}
          />
        )}
      </div>
    </div>
  );
};
