import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterCriteria } from "hooks/collectors/collectorHelper";

export enum FilterHeaderPositions {
  Default = "Default",
  Floating = "Floating",
}

const initialState = {
  title: undefined,
  position: FilterHeaderPositions.Default,
  elements: {
    backArrow: false,
    search: {
      vehicleIdField: true,
      blockIdField: true,
      inputField: false,
    },
    filtersListVisible: false,
  },
};

export type FilterHeaderPayload = {
  title?: string;
  position: FilterHeaderPositions;
  elements: {
    backArrow: boolean;
    search: {
      vehicleIdField: boolean;
      blockIdField: boolean;
      inputField: boolean;
    };
    filtersListVisible: boolean;
  };
};

export const FilterHeaderReducer = createSlice({
  name: "FilterHeaderReducer",
  initialState,
  reducers: {
    setTitle: (
      state: FilterHeaderPayload,
      action: PayloadAction<FilterHeaderPayload["title"]>,
    ) => {
      state.title = action.payload;
    },
    setPosition: (
      state: FilterHeaderPayload,
      action: PayloadAction<FilterHeaderPayload["position"]>,
    ) => {
      state.position = action.payload;
    },
    setSearchElement: (
      state: FilterHeaderPayload,
      action: PayloadAction<
        FilterCriteria<FilterHeaderPayload["elements"]["search"]>
      >,
    ) => {
      state.elements.search = {
        ...state.elements.search,
        ...action.payload,
      };
    },
    setBackArrow: (
      state: FilterHeaderPayload,
      action: PayloadAction<FilterHeaderPayload["elements"]["backArrow"]>,
    ) => {
      state.elements.backArrow = action.payload;
    },
    setFiltersListVisible: (
      state: FilterHeaderPayload,
      action: PayloadAction<
        FilterHeaderPayload["elements"]["filtersListVisible"]
      >,
    ) => {
      state.elements.filtersListVisible = action.payload;
    },
    toggleFiltersListVisible: (state: FilterHeaderPayload) => {
      state.elements.filtersListVisible = !state.elements.filtersListVisible;
    },
  },
});
