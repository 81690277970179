import { useEffect, useState } from "react";
import { DateUtils } from "helpers/DateUtils";
import { LocalStorageUtils, LocalStorageKeys } from "helpers/LocalStorageUtils";
import { useConcession, useUser, useVehicle } from "./react-query";

export enum AllocationStatusEnum {
  IDLE,
  STARTED,
  LOADING,
  CACHED,
  ALLOCATED,
  ALLOCATING_FAILED,
}

/**
 * This hook is used to allocate the database, when all the allocation-hooks return cached data (which cannot be older than 7 days),
 * we skip the allocation process and directly navigate to the app. Otherwise we allocate the database using fresh data and wait for all api calls to finish.
 *
 * By using this technique optimization is achieved, because we don't have to wait for the api calls to finish, when we already have the data cached. (e.g. Offline mode/fast app startups)
 */
export const useAllocateDatabase = () => {
  const [allocationStatus, setAllocationStatus] =
    useState<AllocationStatusEnum>(AllocationStatusEnum.STARTED);

  const lastAllocation = LocalStorageUtils.getLocalStorage(
    LocalStorageKeys.LAST_ALLOCATION_TIMESTAMP,
  );
  const lastAllocationDate: Date | undefined = lastAllocation
    ? new Date(lastAllocation)
    : undefined;
  const lastAllocationInDays = DateUtils.getDaysBetweenDates(
    lastAllocationDate,
    new Date(),
  );

  const {
    isLoading: concessionLoading,
    isError: concessionError,
    isSuccess: concessionSuccess,
  } = useConcession().useGetAll();

  const {
    isLoading: userLoading,
    isError: userError,
    isSuccess: userSuccess,
  } = useUser().useGetUser();

  const {
    isLoading: vehiclesWithDetailsLoading,
    isError: vehiclesWithDetailsError,
    isSuccess: vehiclesWithDetailsSuccess,
  } = useVehicle().useGetAllVehiclesWithDetails();

  const loadings = [concessionLoading, userLoading, vehiclesWithDetailsLoading];
  const errors = [concessionError, userError, vehiclesWithDetailsError];
  const successes = [
    concessionSuccess,
    userSuccess,
    vehiclesWithDetailsSuccess,
  ];

  const checkStatus = () => {
    if (
      successes.every((value) => value === true) &&
      lastAllocationInDays &&
      lastAllocationInDays < 7
    ) {
      return setAllocationStatus(AllocationStatusEnum.CACHED);
    }

    if (errors.includes(true)) {
      return setAllocationStatus(AllocationStatusEnum.ALLOCATING_FAILED);
    }

    if (loadings.some((val) => val === true)) {
      return setAllocationStatus(AllocationStatusEnum.LOADING);
    }

    if (successes.every((value) => value === true)) {
      LocalStorageUtils.setLocalStorage(
        LocalStorageKeys.LAST_ALLOCATION_TIMESTAMP,
        new Date().toISOString(),
      );
      return setAllocationStatus(AllocationStatusEnum.ALLOCATED);
    }
  };

  useEffect(() => {
    checkStatus();
  }, [...loadings, ...errors, ...successes, lastAllocationInDays]);

  return { allocationStatus };
};
