import { EndpointStatusEnum } from "./endpoints/EndpointHelper";

/**
 * @description - This is used to create a filter for the collector hooks
 */
export type FilterCriteria<T> = {
  [P in keyof T]?: T[P] extends object ? FilterCriteria<T[P]> : T[P];
};

export interface ICollectorProps<T> {
  cacheOnly?: boolean;
  filterOnly?: boolean;
  filter?: FilterCriteria<T>[];
  filterValue?: string;
}

export const getFetchMethod = (insertStatus: EndpointStatusEnum) => {
  return insertStatus === EndpointStatusEnum.NO_DATA ||
    insertStatus === EndpointStatusEnum.ERROR ||
    insertStatus === EndpointStatusEnum.IDLE
    ? "network"
    : "cache";
};
