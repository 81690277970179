import mapboxgl from "mapbox-gl";
import { useEffect, useState } from "react";
import { Debug } from "helpers/Debug";
import { LocalStorageUtils, LocalStorageKeys } from "helpers/LocalStorageUtils";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";

/**
 * @description - A generic useMapEvents hook, which initializes basic functionality for the map (eg saving zoom, pitch, bearing, coordinates, 3D buildings, etc)
 * @returns {setMap} - Sets the map to the state so it can be manipulated from here
 */
export const useMapEvents = () => {
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [coordinates, setCoordinates] = useState<number[]>([]);
  const [zoom, setZoom] = useState<number>(0);
  const [pitch, setPitch] = useState<number>(0);
  const [bearing, setBearing] = useState<number>(0);
  const { mapLoaded } = useAppSelector((state) => state.MapboxReducer);
  const { setMapLoaded } = useAppDispatch();

  useEffect(() => {
    if (!map) {
      return;
    }

    setMapLoaded(true);
    // For fast scrolling/zooming on computers
    map.scrollZoom.setWheelZoomRate(1 / 95);
    map.getCanvas().style.cursor = "pointer";

    const onMoveEnd = () => {
      const currentZoom = map.getZoom();
      if (currentZoom && currentZoom < 5) {
        return;
      }

      setCoordinates(map.getCenter().toArray());
      setZoom(map.getZoom());
      setPitch(map.getPitch());
      setBearing(map.getBearing());
    };

    map.on("moveend", onMoveEnd);

    add3DBuildings(map);

    // Cleanup function to remove the event listener / layer so it doesn't get added multiple times
    return () => {
      map.off("moveend", onMoveEnd);
      map.removeLayer("3d-buildings");
    };
  }, [map]);

  useEffect(() => {
    if (!map || !mapLoaded) {
      return;
    }

    LocalStorageUtils.setLocalStorage(
      LocalStorageKeys.LAST_MAP_COORDINATES,
      map.getCenter().toArray(),
    );
    LocalStorageUtils.setLocalStorage(
      LocalStorageKeys.LAST_MAP_ZOOM,
      map.getZoom(),
    );
    LocalStorageUtils.setLocalStorage(
      LocalStorageKeys.LAST_MAP_PITCH,
      map.getPitch(),
    );
    LocalStorageUtils.setLocalStorage(
      LocalStorageKeys.LAST_MAP_BEARING,
      map.getBearing(),
    );
  }, [coordinates, zoom, pitch, bearing]);

  return { setMap };
};

const add3DBuildings = (map: mapboxgl.Map) => {
  {
    // Insert the layer beneath any symbol layer.
    const layers = map.getStyle().layers;
    if (!layers) {
      return Debug.warn("Layers are not defined");
    }

    const labelLayerId = layers?.find(
      (layer) => layer.type === "symbol" && layer.layout?.["text-field"],
    )?.id;

    // The 'building' layer in the Mapbox Streets
    // vector tileset contains building height data
    // from OpenStreetMap.
    map.addLayer(
      {
        id: "3d-buildings",
        source: "composite",
        "source-layer": "building",
        filter: ["==", "extrude", "true"],
        type: "fill-extrusion",
        minzoom: 15,
        paint: {
          "fill-extrusion-color": "#aaa",

          // Use an 'interpolate' expression to
          // add a smooth transition effect to
          // the buildings as the user zooms in.
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "min_height"],
          ],
          "fill-extrusion-opacity": 0.6,
        },
      },
      labelLayerId,
    );
  }
};
