import { z } from "zod";
import { store } from "state/Store";
import { getEnvironmentVariable } from "./EnvironmentVariableUtils";

enum Auth0Errors {
  LOGINREQUIRED = "login_required",
  UNAUTHORIZED = "unauthorized",
  CONSENTREQUIRED = "consent_required",
}

export interface IUseApiOptions {
  method: "GET" | "POST" | "DELETE";
}

export interface IUseApiProps<D> {
  endpoint: string;
  options: IUseApiOptions;
  schema: z.ZodSchema<unknown>;
  data?: D;
  contentType?: "application/json" | "multipart/form-data" | "image/jpeg";
  acceptType?: "application/json" | "text/plain" | "image/jpeg";
}

export interface IUseApiState<T> {
  status?: number;
  error: string | null;
  loading: boolean;
  data: T | null;
  ok: boolean;
}

export const useAsyncApi = async <D, T>({
  endpoint,
  options,
  schema,
  data,
  contentType = "application/json",
  acceptType,
}: IUseApiProps<D>): Promise<IUseApiState<T>> => {
  const { getAccessTokenSilently, logout } = store.getState().Auth0Reducer;

  const logoutFunction = () => {
    if (logout) {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  };

  try {
    if (!getAccessTokenSilently) {
      return {
        error: "Cannot find silent access token function.",
        loading: false,
        data: null,
        ok: false,
      };
    }

    const accessToken = await getAccessTokenSilently();
    const apiURL = getEnvironmentVariable("API_URL");

    const request = await fetch(`${apiURL}${endpoint}`, {
      method: options.method,
      body: (contentType === "multipart/form-data"
        ? data
        : JSON.stringify(data)) as unknown as BodyInit,
      credentials: "include",
      headers: {
        "x-qbuzz-authorization": `Bearer ${accessToken}`,
        ...(contentType === "application/json" && {
          "Content-Type": "application/json",
        }),
        ...(acceptType && { Accept: acceptType }),
      },
    });

    if (request.status === 401) {
      logoutFunction();
    }

    const responseData = await (async () => {
      try {
        switch (acceptType) {
          case "image/jpeg":
            return await request.blob();
          case "text/plain":
            return await request.text();
          default:
            return await request.json();
        }
      } catch {
        return null;
      }
    })();

    if (request.status === 200) {
      // Throws error if data is not valid according to schema
      schema.parse(responseData);
    }

    return {
      status: request.status,
      error: null,
      loading: false,
      data: responseData,
      ok: request.ok,
    };
  } catch (error: unknown) {
    if (error && error instanceof ErrorEvent) {
      switch (error.error) {
        case Auth0Errors.LOGINREQUIRED:
          logoutFunction();
          break;
        case Auth0Errors.CONSENTREQUIRED:
          logoutFunction();
          break;
        default:
          console.error(error);
          break;
      }
    } else if (error && error instanceof z.ZodError) {
      console.error("Schema error", endpoint, error.issues);
    } else if ((error as DOMException).name !== "AbortError") {
      console.error(error);
    }

    return {
      error: error ? "Er is een fout opgetreden." : null,
      loading: false,
      data: null,
      ok: false,
    };
  }
};
