import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import {
  BlockActivitiesEndpoint,
  BlockActivityType,
} from "hooks/collectors/endpoints/BlockActivitiesEndpoint";
import { EndpointStatusEnum } from "hooks/collectors/endpoints/EndpointHelper";
import { Loader } from "components/loader/Loader";
import { DateUtils } from "helpers/DateUtils";
import styles from "./BlockActivities.module.scss";

type BlockActivitiesProps = {
  readonly blockId: string;
};

type DataSourceItem = BlockActivityType & { key: string };

export const BlockActivities = ({ blockId }: BlockActivitiesProps) => {
  const [dataSource, setDataSource] = useState<DataSourceItem[]>([]);
  const [isPending, setIsPending] = useState<boolean>(true);
  const { t } = useTranslation();

  const columns = [
    {
      dataIndex: "route",
      render: (text: string) => `${t("block_activities.route")}: ${text}`,
    },
    {
      dataIndex: "tripNumber",
      render: (text: number) => `${t("block_activities.trip_number")}: ${text}`,
    },
  ];

  const fetchBlockActivities = async () => {
    const { status, data } = await BlockActivitiesEndpoint(blockId);

    if (status === EndpointStatusEnum.SUCCESS && data) {
      setDataSource(
        data.map((blockActivity) => ({
          ...blockActivity,
          key: `${blockActivity.departureTime} - ${blockActivity.arrivalTime}`,
        })),
      );
    }

    setIsPending(false);
  };

  const createExpandedRow = (item: DataSourceItem) => (
    <div className={styles.expanded_table_row}>
      <ul>
        <li>
          {t("block_activities.departure_time")}:{" "}
          {item.departureTime
            ? `${DateUtils.convertSecondsToTime(item.departureTime)} ${t("block_activities.hour")}`
            : t("block_activities.unknown")}
        </li>
        <li>
          {t("block_activities.departure_stop")}:{" "}
          {item.departureStop ?? t("block_activities.unknown")}
        </li>
        <li>
          {t("block_activities.arrival_time")}:{" "}
          {item.arrivalTime
            ? `${DateUtils.convertSecondsToTime(item.arrivalTime)} ${t("block_activities.hour")}`
            : t("block_activities.unknown")}
        </li>
        <li>
          {t("block_activities.arrival_stop")}:{" "}
          {item.arrivalStop ?? t("block_activities.unknown")}
        </li>
      </ul>
    </div>
  );

  useEffect(() => {
    fetchBlockActivities();
  }, [blockId]);

  if (isPending) {
    return (
      <div className={styles.loading}>
        <Loader centerInPage={false} />
        <p>{t("functional.loading")}</p>
      </div>
    );
  }

  return (
    <div className={styles.block_activities}>
      <Table
        bordered
        columns={columns}
        dataSource={dataSource}
        expandable={{
          expandedRowRender: (item) => createExpandedRow(item),
          defaultExpandAllRows: true,
          showExpandColumn: false,
        }}
        showHeader={false}
        pagination={false}
        size="small"
      />
    </div>
  );
};
