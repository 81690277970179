import { MenuItemProps } from "./Menu";
import styles from "./MenuItem.module.scss";

export const MenuItem = (props: MenuItemProps) => {
  return (
    <button
      className={`${styles.item} ${props.enabled ? "" : styles.item_disabled}`}
      onClick={props.onClickEvent}
    >
      <div
        className={`${styles.icon} ${props.enabled && props.active ? styles.selected : ""}`}
      >
        {props.icon && (
          <img src={props.icon} alt={props.name} width={28} height={28} />
        )}
      </div>
      <div className={styles.name}>{props.name}</div>

      {props.enabled ? (
        <div
          className={styles.line}
          style={{
            backgroundColor: props.enabled && props.active ? "" : "transparent",
          }}
        ></div>
      ) : (
        <div className={styles.placeholder}></div>
      )}
    </button>
  );
};
