import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import MSIcon from "assets/img/icons/svg/MSIcon.svg";
import { returnToLocation } from "providers/MainProvider";
import styles from "./Login.module.scss";

export const Login = () => {
  const { isAuthenticated, error, loginWithRedirect } = useAuth0();
  const { t } = useTranslation();

  if (isAuthenticated) {
    return null;
  }

  const loginWithRedirectFunction = () => {
    loginWithRedirect({
      appState: {
        returnTo: returnToLocation,
      },
    });
  };

  return (
    !isAuthenticated && (
      <>
        {error && (
          <div className={styles.login_error}>
            <p>{t("login.error")}</p>
          </div>
        )}

        <button
          className={styles.login_button}
          onClick={() => loginWithRedirectFunction()}
        >
          <img src={MSIcon} alt="MS Icon" className="x-icon" />
          {t("login.login_button_text")}
        </button>
      </>
    )
  );
};
