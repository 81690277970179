import { ROUTES } from "features/pages/Routes";
import { useAppDispatch } from "hooks/useRedux";
import { t } from "i18next";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./QuestionnaireRouter.scss";
import { QuestionnairesList } from "./list/QuestionnairesList";
import { QuestionnaireQuestionsPage } from "./items/questions/QuestionnaireQuestionsPage";

export const QuestionnaireRouter = () => {
  const { SetQuestionnaireHeaderState, setBackArrow } = useAppDispatch();

  useEffect(() => {
    SetQuestionnaireHeaderState({
      headerTitle: t("pages.quality_measurements.title"),
    });
  }, [t]);

  useEffect(() => {
    setBackArrow(true);
  }, []);

  return (
    <Routes>
      <Route path={"/"} element={<QuestionnairesList />} />
      <Route
        path={ROUTES.QUALITY_MEASUREMENTS.$.MEASUREMENT_ID.path}
        element={<QuestionnaireQuestionsPage />}
      />
      <Route
        path={ROUTES.QUALITY_MEASUREMENTS.$.MEASUREMENT_ID.EDIT.path}
        element={<QuestionnaireQuestionsPage />}
      />
    </Routes>
  );
};
