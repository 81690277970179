import Dexie, { Table } from "dexie";
import { chargePointTable } from "./tables/ChargePointTable";
import { QualityMeasurementConceptsTable } from "./tables/QualityMeasurementConceptsTable";
import { VehicleGPSPositionTable } from "./tables/VehicleGPSPositionTable";

const DB_VERSION = 31;
const DB_NAME = `Qontrol_${DB_VERSION}`;

/**
 * This class is used to create the database and its tables (indexedDB) with the help of Dexie.
 * @class Database
 * @extends {Dexie}
 * @see https://dexie.org/docs/Typescript
 */
export class Database extends Dexie {
  vehicleGPSPosition!: Table<VehicleGPSPositionTable.IVehicleGPSPosition>;
  chargePoint!: Table<chargePointTable.IDatabase>;
  qualityMeasurementConceptsTable!: Table<QualityMeasurementConceptsTable.IQualityMeasurementConcepts>;

  constructor() {
    super(DB_NAME);

    // Added temporary hardcoded database version
    this.version(DB_VERSION).stores({
      vehicleGPSPosition: this.convertArrayToStoreString<
        keyof VehicleGPSPositionTable.IVehicleGPSPosition
      >(VehicleGPSPositionTable.vehicleGPSPosition),
      chargePoint: this.convertArrayToStoreString<
        keyof chargePointTable.IDatabase
      >(chargePointTable.tableKeys),
      qualityMeasurementConceptsTable: this.convertArrayToStoreString<
        keyof QualityMeasurementConceptsTable.IQualityMeasurementConcepts
      >(QualityMeasurementConceptsTable.qualityMeasurementConceptTypes),
    });

    this.deleteOldDatabase();
  }

  /**
   * This function is used to convert an array of properties to a comma-separated string that can be used to create a table in the database.
   * @template T Generic type of the array.
   * @param array The array of properties.
   * @returns {string} The string that can be used to create a table in the database, with type checking and auto-increment(only for the 'id' property), when present.
   */
  private convertArrayToStoreString<T>(array: Array<T>): string {
    return array
      .map((value) => {
        return value === "id" ? "++id" : value;
      })
      .join(", ");
  }

  private deleteOldDatabase() {
    const oldDatabase = new Dexie(`Qontrol_${DB_VERSION - 1}`);
    oldDatabase.delete();
  }

  public manuallyDeleteDatabase() {
    const oldDatabase = new Dexie(`Qontrol_${DB_VERSION}`);
    oldDatabase.delete();
  }
}

export const db = new Database();
