import { ROUTES } from "features/pages/Routes";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import "./QuestionnaireQuestionsView.scss";
import { QualityMeasurementConceptsTable } from "assets/database/tables/QualityMeasurementConceptsTable";
import { useEffect, useState } from "react";
import { db } from "assets/database/Database";
import { QuestionnaireQuestionsView } from "./QuestionnaireQuestionsView";
import { Loader } from "components/loader/Loader";

// TODO QID-7870: check if id exists in qdp.
const table = db.qualityMeasurementConceptsTable;
export const QuestionnaireQuestionsPage = () => {
  const { id, conceptId } = useTypedParams(
    ROUTES.QUALITY_MEASUREMENTS.MEASUREMENT_ID.EDIT,
  );
  const [conceptAnswersListData, setConceptAnswersListData] = useState<
    | QualityMeasurementConceptsTable.IQualityMeasurementConcepts
    | undefined
    | null
  >(undefined);

  useEffect(() => {
    if (!id || !conceptId) {
      return setConceptAnswersListData(null);
    }

    const getConceptAnswersListData = async () => {
      const conceptAnswersListData = await table.get({ conceptId });
      if (!conceptAnswersListData) {
        return setConceptAnswersListData(null);
      }

      setConceptAnswersListData(conceptAnswersListData);
    };

    getConceptAnswersListData();
  }, [id, conceptId]);

  if (conceptAnswersListData === undefined) {
    return <Loader />;
  }

  return (
    <QuestionnaireQuestionsView
      conceptAnswersListData={conceptAnswersListData}
    />
  );
};
