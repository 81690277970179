import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  mapLoaded: false,
  trackVehiclePosition: false,
};

export const MapboxReducer = createSlice({
  name: "MapboxReducer",
  initialState: initialState,
  reducers: {
    setMapLoaded: (state, action: PayloadAction<boolean>) => {
      state.mapLoaded = action.payload;
    },
    setTrackVehiclePosition: (state, action: PayloadAction<boolean>) => {
      state.trackVehiclePosition = action.payload;
    },
  },
});

export const { setMapLoaded, setTrackVehiclePosition } = MapboxReducer.actions;
