export enum LocalStorageKeys {
  APP_TOKEN = "app_token",
  LAST_MAP_STYLE = "last_map_style",
  LAST_MAP_COORDINATES = "last_map_coordinates",
  LAST_MAP_ZOOM = "last_map_zoom",
  LAST_MAP_PITCH = "last_map_pitch",
  LAST_MAP_BEARING = "last_map_bearing",
  LAST_ALLOCATION_TIMESTAMP = "last_allocation_timestamp",
}

export namespace LocalStorageUtils {
  const tryParseJson = (value: string) => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };

  export const getLocalStorage = (key: LocalStorageKeys) => {
    const value = localStorage.getItem(key);
    return value ? tryParseJson(value) : null;
  };

  export const setLocalStorage = <T>(key: LocalStorageKeys, value: T) => {
    try {
      return localStorage
        ? localStorage.setItem(key, JSON.stringify(value))
        : console.error("Local storage not available");
    } catch (error) {
      console.error(error);
    }
  };
}
