import { Navigate, Route, Routes } from "react-router-dom";
import { Page404 } from "components/error/Page404";
import { ROUTES } from "features/pages/Routes";
import { FleetVehicles } from "./vehicles/list/FleetVehicles";
import styles from "./Fleet.module.scss";

export const Fleet = () => {
  return (
    <div className={styles.fleet}>
      <Routes>
        <Route path={ROUTES.FLEET.$.LIST.path} element={<FleetVehicles />} />

        <Route
          path="/"
          element={<Navigate to={ROUTES.FLEET.LIST.buildPath({})} />}
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
};
