import { ThemeConfig } from "antd";
import { createStyles } from "antd-style";
import { TagConfig } from "antd/es/config-provider/context";

const tagTokens: ThemeConfig["components"] = {
  Tag: {
    defaultBg: "rgb(62, 68, 75)",
    defaultColor: "rgba(255, 255, 255, 0.7)",
  },
};

export const useTagStyle = (): {
  tagStyles: TagConfig;
  tagTokens: ThemeConfig["components"];
} => {
  const { styles } = createStyles(() => ({
    "qbuzz-tag": {
      whiteSpace: "normal",
    },
  }))();

  return {
    tagTokens,
    tagStyles: {
      className: styles["qbuzz-tag"],
    },
  };
};
