/* eslint-disable @typescript-eslint/no-empty-function */

export class Debug {
  private static isDebugMode = import.meta.env.DEV;

  static log = this.isDebugMode ? console.log.bind(window.console) : () => {};
  static error = this.isDebugMode
    ? console.error.bind(window.console)
    : () => {};
  static warn = this.isDebugMode ? console.warn.bind(window.console) : () => {};
  static table = this.isDebugMode
    ? console.table.bind(window.console)
    : () => {};
}
