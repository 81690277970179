import { DateUtils } from "helpers/DateUtils";
import { vehicleGPSLayerIds } from "./Layers";
import { SocUtils } from "helpers/SocUtils";
import { TransportType } from "hooks/react-query/resources";
import { featureCollection, point } from "@turf/helpers";
import { Feature, Point } from "geojson";
import { VehicleWithExtraDetails } from "hooks/mapbox/events/useFleetVehicleMapGPSEvents";

export const getSocCardBackground = (
  socValue: number | undefined,
  SocTimestamp: DateUtils.ITimeElapsed | null,
) => {
  if (socValue === undefined) {
    return vehicleGPSLayerIds.soc_background_red;
  }

  if (
    (SocTimestamp && SocTimestamp?.type === "m" && SocTimestamp?.value >= 2) ||
    SocTimestamp?.type === "h" ||
    SocTimestamp?.type === "d"
  ) {
    return vehicleGPSLayerIds.soc_background_gray;
  }

  return socColorBySocValue(socValue);
};

const socColorBySocValue = (socValue: number) => {
  const socColor = SocUtils.getSocColor(socValue);
  switch (socColor) {
    case SocUtils.SocColor.RED:
      return vehicleGPSLayerIds.soc_background_red;
    case SocUtils.SocColor.ORANGE:
      return vehicleGPSLayerIds.soc_background_orange;
    case SocUtils.SocColor.YELLOW:
      return vehicleGPSLayerIds.soc_background_yellow;
    default:
      return vehicleGPSLayerIds.soc_background_green;
  }
};

export const getVehicleCardBackground = (
  socValue: number | undefined,
  gpsTimestamp: DateUtils.ITimeElapsed | null,
) => {
  let background = vehicleGPSLayerIds.layer_vehicle_background;
  let blackBackground = vehicleGPSLayerIds.layer_vehicle_background_black;

  if (socValue !== undefined) {
    background = vehicleGPSLayerIds.layer_vehicle_background_soc;
    blackBackground = vehicleGPSLayerIds.layer_vehicle_background_soc_black;
  }

  if (
    gpsTimestamp?.type === "s" ||
    (gpsTimestamp?.type === "m" && gpsTimestamp.value <= 2)
  ) {
    return background;
  }

  return blackBackground;
};

export const getVehicleIcon = (transportType?: TransportType) => {
  switch (transportType) {
    case "BUS":
      return vehicleGPSLayerIds.vehicle_icon_bus;
    case "TRAM":
      return vehicleGPSLayerIds.vehicle_icon_tram;
    case "TRAIN":
      return vehicleGPSLayerIds.vehicle_icon_train;
    case "BOAT":
      return vehicleGPSLayerIds.vehicle_icon_boat;
    default:
      return vehicleGPSLayerIds.vehicle_icon_bus;
  }
};

export const vehicleToGeoJson = (vehicles: VehicleWithExtraDetails[]) => {
  const positions: Array<Feature<Point>> = [];
  vehicles.forEach((vehicleData) => {
    if (vehicleData.vehiclePosition === undefined) {
      return;
    }
    const vehicleGPSPositionTimestamp = DateUtils.getTimeElapsed(
      DateUtils.convertUTCDatetoLocalDate(
        DateUtils.convertStringToDate(vehicleData.vehiclePosition?.time),
      ),
    );

    const vehicleSocTimestamp = DateUtils.getTimeElapsed(
      DateUtils.convertUnixTimestampToDate(vehicleData.vehicleSoc?.time),
    );
    const socValue = vehicleData.vehicleSoc?.value;
    const socEnabled = socValue !== undefined;

    positions.push(
      point(
        [
          vehicleData.vehiclePosition.longitude,
          vehicleData.vehiclePosition.latitude,
        ],
        {
          vehicleId: vehicleData.vehiclePosition.vehicleId,
          vehicleBackground: getVehicleCardBackground(
            socValue,
            vehicleGPSPositionTimestamp,
          ),
          vehicleIcon: getVehicleIcon(
            vehicleData.vehicle?.vehicleGroup.transportType,
          ),
          socTimestamp: `${vehicleSocTimestamp?.value}${vehicleSocTimestamp?.type}`,
          soc: `${socValue}%`,
          socEnabled: socEnabled,
          socCardBackground: getSocCardBackground(
            socValue,
            vehicleSocTimestamp,
          ),
        },
      ),
    );
  });

  return featureCollection([...positions]);
};
