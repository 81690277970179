import { useTranslation } from "react-i18next";
import { AutoSize } from "components/elements/autosize/AutoSize";
import { Button } from "components/elements/button/Button";
import styles from "./PageError.module.scss";
import { db } from "assets/database/Database";

interface IPageError {
  onButtonClick?: () => void;
  reloadPage?: boolean;
  errorText?: string;
}

export const PageError = ({
  onButtonClick,
  reloadPage = true,
  errorText,
}: IPageError) => {
  const { t } = useTranslation();

  const reloadAndForceLogout = () => {
    db.manuallyDeleteDatabase();
    window.location.reload();
  };

  return (
    <div className={styles.page_error_container}>
      <AutoSize mode="box">
        {errorText ? errorText : t("functional.error")}
      </AutoSize>
      <br />
      {reloadPage && (
        <Button
          className={styles.restart_app_button}
          size="large"
          onClickEvent={onButtonClick || (() => reloadAndForceLogout())}
        >
          {t("functional.reload")}
        </Button>
      )}
    </div>
  );
};
