import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiOptions, useAsyncApi } from "helpers/useAsyncApi";
import { EndpointStatusEnum } from "hooks/collectors/endpoints/EndpointHelper";

const GarageSchema = z.object({
  id: z.string(),
  description: z.string(),
  number: z.number(),
});
const EmployeeSchema = z.object({
  id: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phone2: z.string().optional(),
});

const DutySchema = z.object({
  dutyDescription: z.string(),
});

const SupportStaffSchema = z.object({
  employeeId: z.string(),
  currentJob: z.string().optional(),
  gpsTime: z.string().optional(),
  lastUpdate: z.string(),
  garage: GarageSchema,
  employee: EmployeeSchema,
  distance: z.number(),
  duty: DutySchema,
});

export type SupportStaffType = z.infer<typeof SupportStaffSchema>;

export const SupportStaffEndpoint = async () => {
  try {
    const options: IUseApiOptions = {
      method: "GET",
    };
    const endpoint = ENDPOINTS.QONTROL.OPERATIONAL_SUPPORT.SUPPORT_STAFF.path;
    const requestOptions = {
      endpoint,
      options,
      schema: z.array(SupportStaffSchema),
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await useAsyncApi<null, SupportStaffType[]>(
      requestOptions,
    );

    if (response.error || response.status !== 200) {
      return { status: EndpointStatusEnum.ERROR, data: null };
    }

    if (!response.data) {
      return { status: EndpointStatusEnum.NO_DATA, data: null };
    }

    return { status: EndpointStatusEnum.SUCCESS, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: EndpointStatusEnum.ERROR, data: null };
  }
};
