import { ConfigProviderProps, ThemeConfig } from "antd";
import { useButtonStyle } from "./components/button";
import { useInputStyle } from "./components/input";
import { useModalStyle } from "./components/modal";
import { useTableStyle } from "./components/table";
import { useTabsStyle } from "./components/tabs";
import { useTagStyle } from "./components/tag";
import { useRadioStyle } from "./components/radio";

export const useThemeConfig = () => {
  const { buttonStyles, buttonTokens } = useButtonStyle();
  const { inputStyles, inputTokens } = useInputStyle();
  const { modalStyles, modalTokens } = useModalStyle();
  const { tableStyles, tableTokens } = useTableStyle();
  const { tabsStyles, tabsTokens } = useTabsStyle();
  const { tagStyles, tagTokens } = useTagStyle();
  const { radioStyles, radioTokens } = useRadioStyle();

  const theme: ThemeConfig = {
    token: {
      colorPrimary: "#3e444b",
      colorText: "rgb(255 255 255 / 80%)",
    },
    components: {
      ...buttonTokens,
      ...inputTokens,
      ...modalTokens,
      ...tableTokens,
      ...tabsTokens,
      ...tagTokens,
      ...radioTokens,
    },
  };

  const config: ConfigProviderProps = {
    theme,
    prefixCls: "qbuzz",
    // component styles
    button: buttonStyles,
    input: inputStyles,
    modal: modalStyles,
    table: tableStyles,
    tabs: tabsStyles,
    tag: tagStyles,
    radio: radioStyles,
  };

  return { themeConfig: config };
};
