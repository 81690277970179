import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { IncidentResource, type Incident } from "./resources/incident";

export const useIncident = () => {
  const incidentApi = new IncidentResource();

  const useGetOneByVehicleId: (
    vehicleId?: string,
  ) => UseQueryResult<Incident[], Error> = (vehicleId?: string) =>
    useQuery({
      queryKey: ["incident", vehicleId],
      queryFn: async () => {
        const response = await incidentApi.getOneByVehicleId(vehicleId);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
      enabled: !!vehicleId,
    });

  return { useGetOneByVehicleId };
};
