import { Navigate, Route, Routes } from "react-router-dom";
import { ScrollElement } from "@id/frontend.components";
import { HeaderRouter } from "features/layout/header/HeaderRouter";
import { ROUTES } from "features/pages/Routes";
import { Contact } from "features/pages/contact/Contact";
import { Map } from "features/pages/map/Map";
import { SideMenu } from "features/pages/menu/SideMenu";
import { QuestionnaireRouter } from "features/pages/quality-measurements/QuestionnaireRouter";
import { SocPopup } from "features/pages/soc/SocPopup";
import { useAppSelector } from "hooks/useRedux";
import { FilterHeaderPositions } from "state/reducers/FilterHeaderReducer";
import styles from "./Content.module.scss";
import { Fleet } from "features/pages/fleet/Fleet";

export const Content = () => {
  const { position } = useAppSelector((state) => state.FilterHeaderReducer);

  return (
    <>
      {position === FilterHeaderPositions.Default && <HeaderRouter />}
      <ScrollElement
        scrollDirection="vertical"
        scrollBarVisible={false}
        className={styles.main_content}
      >
        {position === FilterHeaderPositions.Floating && <HeaderRouter />}
        <Routes>
          <Route
            path={ROUTES.DEFAULT.path}
            element={<Navigate to={ROUTES.FLEET.LIST.buildPath({})} />}
          />

          <Route path={ROUTES.FLEET.path} element={<Fleet />} />
          <Route path={ROUTES.MAP.path} element={<Map />} />
          <Route path={ROUTES.CONTACT.path} element={<Contact />} />
          <Route
            path={ROUTES.QUALITY_MEASUREMENTS.path}
            element={<QuestionnaireRouter />}
          />

          <Route path="*" element={<>404</>} />
        </Routes>
      </ScrollElement>
      <SideMenu />
      <SocPopup />
    </>
  );
};
