import { AppStateReducer } from "./reducers/AppStateReducer";
import { Auth0Reducer } from "./reducers/Auth0Reducer";
import { FilterHeaderReducer } from "./reducers/FilterHeaderReducer";
import { MapboxReducer } from "./reducers/MapboxReducer";
import { QuestionnaireReducer } from "./reducers/QuestionnaireReducer";

// By combining all the actions into one object, we can import them all at once and have a generic redux hook
export const Actions = {
  setTitle: FilterHeaderReducer.actions.setTitle,
  setPosition: FilterHeaderReducer.actions.setPosition,
  setSearchElement: FilterHeaderReducer.actions.setSearchElement,
  setBackArrow: FilterHeaderReducer.actions.setBackArrow,
  setFiltersListVisible: FilterHeaderReducer.actions.setFiltersListVisible,
  toggleFiltersListVisible:
    FilterHeaderReducer.actions.toggleFiltersListVisible,

  setAuth0: Auth0Reducer.actions.setAuth0,
  setReturnTo: Auth0Reducer.actions.setReturnTo,
  setMapLoaded: MapboxReducer.actions.setMapLoaded,
  setTrackVehiclePosition: MapboxReducer.actions.setTrackVehiclePosition,

  setMenuVisible: AppStateReducer.actions.setMenuVisible,
  setSideMenuVisible: AppStateReducer.actions.setSideMenuVisible,
  setSocPopupVisible: AppStateReducer.actions.setSocPopupVisible,
  setSocPopupData: AppStateReducer.actions.setSocPopupData,

  SetQuestionnaireHeaderState:
    QuestionnaireReducer.actions.SetQuestionnaireHeaderState,
  SetQuestionnaireViewState:
    QuestionnaireReducer.actions.SetQuestionnaireViewState,
  SetQuestionnaireAnswersState:
    QuestionnaireReducer.actions.SetQuestionnaireAnswersState,
  insertOrUpdateQuestionnaireAnswer:
    QuestionnaireReducer.actions.insertOrUpdateQuestionnaireAnswer,
  removeQuestionnaireAnswer:
    QuestionnaireReducer.actions.removeQuestionnaireAnswer,
};
