import pica from "pica";

export namespace ImageResizerUtil {
  export const resize = async (image: File): Promise<File> => {
    return new Promise<File>((resolve) => {
      const image_element = document.createElement("img");
      image_element.src = URL.createObjectURL(image);
      const originalCanvas = document.createElement("canvas");
      const resizedCanvas = document.createElement("canvas");
      const randomFileName =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      image_element.onload = async (e: Event) => {
        try {
          const target = e.target as HTMLImageElement;
          originalCanvas.width = image_element.width;
          originalCanvas.height = image_element.height;

          const ctx: CanvasRenderingContext2D | null =
            originalCanvas.getContext("2d");

          if (!ctx) {
            throw "Could not get canvas context";
          }

          ctx.imageSmoothingEnabled = false;
          ctx.drawImage(image_element, 0, 0);

          if (target.width <= 700) {
            throw "Image is already small enough";
          }

          const MAX_WIDTH = 700;
          const scaleSize = MAX_WIDTH / target.width;
          resizedCanvas.width = MAX_WIDTH;
          resizedCanvas.height = target.height * scaleSize;

          await pica().resize(originalCanvas, resizedCanvas, {
            unsharpAmount: 160,
            unsharpRadius: 0.6,
            unsharpThreshold: 1,
          });

          const imageDataUrl = addTransparentPixel(resizedCanvas);
          const imageFile = new File(
            [dataURLToBlob(imageDataUrl)],
            `${randomFileName}.jpg`,
            { type: "image/jpeg" },
          );
          resizedCanvas.remove();
          originalCanvas.remove();
          image_element.remove();

          resolve(imageFile);
        } catch (err) {
          console.error(err);
          const imageDataUrl = addTransparentPixel(originalCanvas);
          const imageFile = new File(
            [dataURLToBlob(imageDataUrl)],
            `${randomFileName}.jpg`,
            { type: "image/jpeg" },
          );

          resizedCanvas.remove();
          originalCanvas.remove();
          image_element.remove();

          // If an error occurs, return the original image (without resizing)
          resolve(imageFile);
        }
      };
    });
  };

  /**
   * @description Adds a transparent pixel to the canvas, this way each image will have a different hash and will be unique.
   * @param canvas - The canvas to add the transparent pixel to.
   * @returns The canvas as a data url.
   */
  export const addTransparentPixel = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    const randomX = Math.floor(Math.random() * canvas.width);
    const randomY = Math.floor(Math.random() * canvas.height);
    if (ctx) {
      ctx.fillStyle = "rgba(0, 0, 0, 0.011)";
      ctx.fillRect(randomX, randomY, 1, 1);
    }

    return canvas.toDataURL("image/jpeg", 1);
  };

  const dataURLToBlob = (dataURL: string): Blob => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([int8Array], { type: mimeString });
  };
}
