export namespace SocUtils {
  export enum SocColor {
    GREY = "grey",
    RED = "red",
    ORANGE = "orange",
    YELLOW = "yellow",
    GREEN = "green",
  }

  export const getSocColor = (soc: number | undefined): SocUtils.SocColor => {
    if (soc === undefined) {
      return SocColor.GREY;
    }

    switch (true) {
      case soc <= 4:
        return SocColor.RED;
      case soc <= 30:
        return SocColor.ORANGE;
      case soc <= 80:
        return SocColor.YELLOW;
      default:
        return SocColor.GREEN;
    }
  };
}
