import { configureStore } from "@reduxjs/toolkit";
import { RootReducer } from "./RootReducer";

export const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // We need to ignore these paths because they are functions and cannot be serialized, we do not want this for any other action/reducer
      serializableCheck: {
        ignoredPaths: [
          "Auth0Reducer.getAccessTokenSilently",
          "Auth0Reducer.getAccessTokenWithPopup",
          "Auth0Reducer.logout",
        ],
        ignoredActionPaths: [
          "payload.getAccessTokenSilently",
          "payload.getAccessTokenWithPopup",
          "payload.logout",
        ],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
