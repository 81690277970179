import { useTranslation } from "react-i18next";
import { intervalToDuration, formatDuration } from "date-fns";
import { Table, Typography } from "antd";
import { PageError } from "components/error/PageError";
import { Loader } from "components/loader/Loader";
import { DateUtils } from "helpers/DateUtils";
import { useRoute, useVehicleInfo } from "hooks/react-query";
import styles from "./ScheduledRoute.module.scss";

type ScheduledRouteProps = {
  readonly vehicleId: string;
};

type DataSourceItem = {
  key: string;
  description: string;
  arrivalTime: Date;
  punctuality?: number;
};

const renderTimeWithPunctuality = (arrivalTime: Date, punctuality?: number) => {
  const formattedTime = DateUtils.formatDate(arrivalTime, "HH:mm");

  if (!punctuality) {
    return formattedTime;
  }

  const duration = intervalToDuration({
    start: 0,
    end: (punctuality < 0 ? punctuality * -1 : punctuality) * 1000,
  });

  const formatted = formatDuration(duration, {
    format: ["hours", "minutes", "seconds"],
    zero: true,
    delimiter: ":",
    locale: {
      formatDistance: (_token, count) => {
        const paddedCount = String(count).padStart(2, "0");

        if (duration.hours && _token.includes("Minutes")) {
          return paddedCount;
        }

        if (duration.minutes && _token.includes("Seconds")) {
          return paddedCount;
        }

        return String(count);
      },
    },
  });

  const unit = duration.hours ? "h" : duration.minutes ? "m" : "s";

  return (
    <>
      {formattedTime}
      <span className={punctuality < 0 ? styles.green : styles.orange}>
        &nbsp; ({punctuality < 0 ? "-" : "+"}
        {formatted}
        {unit})
      </span>
    </>
  );
};

export const ScheduledRoute = ({ vehicleId }: ScheduledRouteProps) => {
  const { t } = useTranslation();

  const getVehicleInfoTrip = useVehicleInfo().useGetAllTripByVehicleIds([
    vehicleId,
  ]);
  const { reinforcementSequenceNumber, routeId, tripNumber } =
    getVehicleInfoTrip.data?.find((trip) => trip.vehicleId === vehicleId) || {};

  const getRoute = useRoute().useGetOne(
    routeId,
    tripNumber,
    reinforcementSequenceNumber,
  );

  const dataSource = (getRoute.data?.stops || []).map(
    ({ stop: { description }, arrivalTime, punctuality, seqNr }) => ({
      key: String(seqNr),
      description,
      arrivalTime,
      punctuality,
    }),
  );

  const columns = [
    {
      dataIndex: "description",
    },
    {
      dataIndex: "punctuality",
      render: (punctuality: number, record: DataSourceItem) => (
        <>{renderTimeWithPunctuality(record.arrivalTime, punctuality)}</>
      ),
    },
  ];

  if (getVehicleInfoTrip.isLoading || getRoute.isLoading) {
    return (
      <div className={styles.loading}>
        <Loader centerInPage={false} />
        <p>{t("functional.loading")}</p>
      </div>
    );
  }

  if (getVehicleInfoTrip.isError || getRoute.isError) {
    return <PageError reloadPage={false} />;
  }

  return (
    getVehicleInfoTrip.isSuccess && (
      <div className={styles.scheduled_route}>
        {getRoute.isSuccess && (
          <>
            <p>
              {t("terminology.line")}:{" "}
              <Typography.Text strong>{routeId}</Typography.Text>
            </p>
            <p>
              {t("terminology.trip")}:{" "}
              <Typography.Text strong>{tripNumber}</Typography.Text>
            </p>
          </>
        )}
        <Table
          bordered={dataSource?.length > 0}
          columns={columns}
          dataSource={dataSource}
          locale={{
            emptyText: t("scheduled_route.empty"),
          }}
          showHeader={false}
          pagination={false}
          size="small"
        />
      </div>
    )
  );
};
