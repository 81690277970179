import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AppStatePayload = {
  menuVisible: boolean;
  sideMenuVisible: boolean;
  socPopupVisible: boolean;
  socPopupData: {
    vehicleId: string | null;
  };
};
const initialState: AppStatePayload = {
  menuVisible: true,
  sideMenuVisible: false,
  socPopupVisible: false,
  socPopupData: {
    vehicleId: null,
  },
};

export const AppStateReducer = createSlice({
  name: "AppStateReducer",
  initialState: initialState,
  reducers: {
    setMenuVisible: (
      state: AppStatePayload,
      action: PayloadAction<AppStatePayload["menuVisible"]>,
    ) => {
      state.menuVisible = action.payload;
    },
    setSideMenuVisible: (
      state: AppStatePayload,
      action: PayloadAction<AppStatePayload["sideMenuVisible"]>,
    ) => {
      state.sideMenuVisible = action.payload;
    },
    setSocPopupVisible: (
      state: AppStatePayload,
      action: PayloadAction<AppStatePayload["socPopupVisible"]>,
    ) => {
      state.socPopupVisible = action.payload;
    },
    setSocPopupData: (
      state: AppStatePayload,
      action: PayloadAction<AppStatePayload["socPopupData"]>,
    ) => {
      state.socPopupData = action.payload;
    },
  },
});

export const {
  setMenuVisible,
  setSideMenuVisible,
  setSocPopupVisible,
  setSocPopupData,
} = AppStateReducer.actions;
