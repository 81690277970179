import { Button } from "components/elements/button/Button";
import { PageError } from "components/error/PageError";
import { Loader } from "components/loader/Loader";
import { ROUTES } from "features/pages/Routes";
import { useQuestionnairesCollector } from "hooks/collectors/useQuestionnairesCollector";
import { useAppDispatch } from "hooks/useRedux";
import { AutoSize } from "components/elements/autosize/AutoSize";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { QuestionnaresListConcepts } from "./QuestionnaresListConcepts";

export const QuestionnairesList = () => {
  const navigate = useNavigate();
  const { SetQuestionnaireHeaderState, setMenuVisible } = useAppDispatch();
  const {
    data: questionnaires,
    isLoading,
    error,
    refresh,
  } = useQuestionnairesCollector();
  const { t } = useTranslation();

  const onErrorButtonClick = () => {
    refresh();
  };

  const displayQuestionnaires = useMemo(() => {
    if (error) {
      return <PageError onButtonClick={onErrorButtonClick} />;
    }
    if (isLoading && (!questionnaires || questionnaires.length === 0)) {
      return <Loader />;
    }

    return questionnaires?.map((questionnaire) => (
      <Button
        key={questionnaire.questionnaireId}
        className="item"
        onClickEvent={() =>
          navigate(
            ROUTES.QUALITY_MEASUREMENTS.MEASUREMENT_ID.buildPath({
              id: questionnaire.questionnaireId,
            }),
          )
        }
      >
        <div className="icon">
          <i className="q-icons">e</i>
        </div>
        <div>
          <AutoSize className="title">{questionnaire.title}</AutoSize>
        </div>
      </Button>
    ));
  }, [questionnaires, error, isLoading]);

  useEffect(() => {
    SetQuestionnaireHeaderState({
      headerTitle: t("pages.quality_measurements.title"),
      showBackArrow: true,
    });

    setMenuVisible(false);

    return () => {
      setMenuVisible(true);
    };
  }, [t]);

  return (
    <div className="quality_measurements">
      <div className="list">
        {displayQuestionnaires}
        {questionnaires && (
          <QuestionnaresListConcepts questionnaires={questionnaires} />
        )}
      </div>
    </div>
  );
};
