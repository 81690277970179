import { ReactNode, RefObject } from "react";
import styles from "./Header.module.scss";

export const Header = ({
  className,
  floating = false,
  children,
  refObject,
}: {
  className?: string;
  floating?: boolean;
  children: ReactNode;
  refObject?: RefObject<HTMLDivElement>;
}) => {
  return (
    <div
      className={`${styles.header_container} ${className} ${floating ? styles.floating : ""}`}
      ref={refObject}
    >
      {children}
    </div>
  );
};
