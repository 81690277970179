import { useEffect, useState } from "react";
import { EndpointStatusEnum } from "./endpoints/EndpointHelper";
import { QuestionnairesEndpoint } from "hooks/collectors/endpoints/QuestionnairesEndpoint";
import { IQuestionnaire } from "state/reducers/QuestionnaireReducer";

type returnDataType = IQuestionnaire[] | null;

export const useQuestionnairesCollector = () => {
  const [data, setData] = useState<returnDataType>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [insertStatus, setInsertStatus] = useState<EndpointStatusEnum>(
    EndpointStatusEnum.IDLE,
  );
  const [refreshIndex, setRefreshIndex] = useState(0);

  const fetch = async () => {
    setLoading(true);
    setError(false);

    setInsertStatus(EndpointStatusEnum.LOADING);
    QuestionnairesEndpoint()
      .then(({ status, data }) => {
        setData(data);
        setInsertStatus(status);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (insertStatus !== EndpointStatusEnum.LOADING) {
      setInsertStatus(EndpointStatusEnum.IDLE);
      fetch();
    }
  }, [refreshIndex]);

  return {
    data,
    isLoading,
    error,
    insertStatus,
    refresh: () => setRefreshIndex((prev) => prev + 1),
  };
};
