import { ConfigProvider } from "antd";
import { ReactNode } from "react";
import { useThemeConfig } from "./useThemeConfig";

type ThemeProviderProps = {
  children: ReactNode;
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const { themeConfig } = useThemeConfig();

  return <ConfigProvider {...themeConfig}>{children}</ConfigProvider>;
};
