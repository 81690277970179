import { ThemeConfig } from "antd";
import { createStyles } from "antd-style";
import { ComponentStyleConfig } from "antd/es/config-provider/context";

const radioTokens: ThemeConfig["components"] = {
  Radio: {
    buttonBg: "none",
  },
};

export const useRadioStyle = (): {
  radioStyles: ComponentStyleConfig;
  radioTokens: ThemeConfig["components"];
} => {
  const { styles } = createStyles(() => ({
    "qbuzz-radio-group": {
      "&.qbuzz-radio-button-wrapper": {
        "&:hover": {
          color: "inherit",
        },
      },
    },
  }))();

  return {
    radioTokens,
    radioStyles: {
      className: styles["qbuzz-radio-group"],
    },
  };
};
