import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiState, useAsyncApi } from "helpers/useAsyncApi";

const ConcessionSchema = z.object({
  id: z.number(),
  name: z.string(),
  shortCode: z.string(),
});

export type Concession = z.infer<typeof ConcessionSchema>;

export class ConcessionResource {
  public getAll = async (): Promise<IUseApiState<Concession[]>> => {
    return useAsyncApi<null, Concession[]>({
      endpoint: ENDPOINTS.QONTROL.CONCESSIONS.buildPath({}),
      options: {
        method: "GET",
      },
      schema: z.array(ConcessionSchema),
    });
  };
}
