import { useTranslation } from "react-i18next";
import { User } from "hooks/react-query/resources";

interface Props {
  user: User;
}

export const UserName = ({ user }: Props) => {
  const { t } = useTranslation();
  const name = user?.email.split(".")[0];

  return user?.impersonate
    ? `${name} (${t("user.impersonate")} ${user.impersonate.firstName})`
    : name;
};
