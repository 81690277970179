import { t } from "i18next";
import { db } from "assets/database/Database";
import { QualityMeasurementConceptsTable } from "assets/database/tables/QualityMeasurementConceptsTable";
import { AutoSize } from "components/elements/autosize/AutoSize";
import { DateUtils } from "helpers/DateUtils";
import { IQuestionnaire } from "state/reducers/QuestionnaireReducer";
import { useEffect, useState } from "react";
import { ROUTES } from "features/pages/Routes";
import { useNavigate } from "react-router-dom";
import { Button } from "components/elements/button/Button";
import XMarkIcon from "assets/img/icons/svg/XMarkIcon.svg";
import { DeleteConceptModal } from "../items/modal/DeleteConceptModal";
import { QualityMeasurementImageDeleteUtil } from "helpers/QualityMeasurementImageDeleteUtil";

interface QuestionnaresListConceptsProps {
  questionnaires: IQuestionnaire[] | null;
}

const table = db.qualityMeasurementConceptsTable;

export const QuestionnaresListConcepts = ({
  questionnaires,
}: QuestionnaresListConceptsProps) => {
  const [deleteConceptModalOpen, setDeleteConceptModalOpen] = useState(false);
  const [deleteConceptId, setDeleteConceptId] = useState<string | undefined>(
    undefined,
  );
  const [concepts, setConcepts] = useState<
    QualityMeasurementConceptsTable.IQualityMeasurementConcepts[]
  >([]);
  const navigate = useNavigate();

  const onDeleteButtonClick = (conceptId: string) => {
    setDeleteConceptId(conceptId);
    setDeleteConceptModalOpen(true);
  };

  const onDeleteSuccess = () => {
    setDeleteConceptModalOpen(false);
    setDeleteConceptId(undefined);
    getConcepts();
  };

  const getConcepts = async () => {
    if (!questionnaires || questionnaires.length === 0) {
      return;
    }

    const databaseConcepts = await table.toArray();
    const filteredConcepts = databaseConcepts.filter((concept) => {
      const questionnaire = questionnaires?.find((questionnaire) => {
        return (
          questionnaire.questionnaireId ===
            concept.answerList.questionnaireId &&
          questionnaire.version === concept.answerList.version
        );
      });

      return !!questionnaire;
    });

    try {
      // Delete old concepts if they do not longer exist in our backend
      const oldConcepts = databaseConcepts.filter(
        (concept) => !filteredConcepts.includes(concept),
      );
      const oldImageIds = oldConcepts
        .map((concept) => {
          const conceptsWithImageIds = concept.answerList.answers.filter(
            (answer) => answer.imageIds && answer.imageIds.length > 0,
          );

          return conceptsWithImageIds.map((answer) => answer.imageIds).flat();
        })
        .flat() as string[];

      await QualityMeasurementImageDeleteUtil.deleteImages(oldImageIds);
      table.bulkDelete(oldConcepts.map((concept) => concept.conceptId));
    } catch (error) {
      console.error(error);
    }

    const sortedConcepts = filteredConcepts.sort((a, b) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });

    setConcepts(sortedConcepts);
  };

  useEffect(() => {
    getConcepts();
  }, [questionnaires]);

  if (!concepts || concepts.length === 0) {
    return null;
  }

  return (
    <div className="concepts">
      <AutoSize className="title">
        {t("pages.quality_measurements.list.concepts.title")}:
      </AutoSize>

      {concepts.map((concept) => {
        const questionnaire = questionnaires?.find(
          (questionnaire) =>
            questionnaire.questionnaireId ===
            concept.answerList.questionnaireId,
        );
        const timestamp = DateUtils.formatDate(
          DateUtils.convertStringToDate(concept.timestamp.toLocaleString()),
          "MM-dd-yyyy HH:mm",
        );

        return (
          <div className="concept" key={concept.conceptId}>
            <Button
              className="item"
              onClickEvent={() =>
                navigate(
                  ROUTES.QUALITY_MEASUREMENTS.MEASUREMENT_ID.EDIT.buildPath({
                    id: concept.answerList.questionnaireId,
                    conceptId: concept.conceptId,
                  }),
                )
              }
            >
              <div className="icon">
                <i className="q-icons">e</i>
              </div>
              <div className="concept">
                <AutoSize className="title">{questionnaire?.title}</AutoSize>
                <AutoSize className="title" maxFontSizePx={14}>
                  {t("pages.quality_measurements.list.concepts.timestamp")}:{" "}
                  {timestamp}
                </AutoSize>
              </div>
            </Button>
            <Button
              className="delete"
              onClickEvent={() => onDeleteButtonClick(concept.conceptId)}
            >
              <img src={XMarkIcon} alt="Delete" />
            </Button>
          </div>
        );
      })}
      <DeleteConceptModal
        deleteConceptModalOpen={deleteConceptModalOpen}
        setDeleteConceptModalOpen={setDeleteConceptModalOpen}
        deleteConceptId={deleteConceptId}
        onDeleteSuccess={onDeleteSuccess}
      />
    </div>
  );
};
