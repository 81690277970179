import "./Explanation.scss";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";

interface IProps {
  text: string;
}

const MAX_LENGTH = 120;

export const Explanation = ({ text }: IProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const textIsTooLong = (): boolean => {
    return text.length > MAX_LENGTH;
  };

  const getText = useMemo(() => {
    if (!textIsTooLong()) {
      return text;
    }

    return `${text.substring(0, MAX_LENGTH)} ... ${t("functional.read_more")}`;
  }, [text, t]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const CompleteTextModal = () => {
    if (textIsTooLong()) {
      return (
        <Modal
          title={t("pages.quality_measurements.explanation")}
          open={isModalOpen}
          onOk={handleClose}
          onCancel={handleClose}
        >
          <p>{text}</p>
        </Modal>
      );
    }
  };

  return (
    <>
      <div className={"explanation"} onClick={showModal}>
        {getText}
      </div>
      <CompleteTextModal />
    </>
  );
};
