import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import nl from "./locales/nl.json";

export const resources = {
  nl,
};

const localResources = {
  nl: { translation: nl },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: localResources,
    fallbackLng: "nl",
    debug: import.meta.env.DEV,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
