import { UseQueryResult, useQuery } from "@tanstack/react-query";
import {
  type Soc,
  VehicleInfoResource,
  type VehicleGps,
  VehicleTrip,
  VehicleSoc,
  VehicleRoute,
} from "./resources/vehicleInfo";
import {
  ALL_VEHICLES_GPS_INTERVAL,
  VEHICLE_GPS_INTERVAL,
  VEHICLE_ROUTE_INTERVAL,
  VEHICLES_SOC_INTERVAL,
} from "constants/constants";

const transformVehicleSocs = (
  data: Record<string, Record<string, Soc>> | null,
): VehicleSoc[] | undefined => {
  if (!data) {
    return undefined;
  }

  return Object.entries(data).map(([vehicleId, vehicleSoc]) => ({
    vehicleId,
    ...vehicleSoc["soc"],
  }));
};

export const useVehicleInfo = () => {
  const vehicleInfoApi = new VehicleInfoResource();

  const useGetAllGps: (
    enabled: boolean,
  ) => UseQueryResult<VehicleGps[] | undefined, Error> = (enabled = false) =>
    useQuery({
      queryKey: ["vehicles", "gps"],
      queryFn: async () => {
        const response = await vehicleInfoApi.getAllGps();

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
      enabled,
      refetchInterval: ALL_VEHICLES_GPS_INTERVAL,
    });

  const useGetOneGpsByVehicleId: (
    vehicleId?: string,
  ) => UseQueryResult<VehicleGps, Error> = (vehicleId?: string) =>
    useQuery({
      queryKey: ["vehicles", "gps", vehicleId],
      queryFn: async () => {
        const response = await vehicleInfoApi.getOneGpsByVehicleId(
          String(vehicleId),
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
      enabled: !!vehicleId,
      refetchInterval: VEHICLE_GPS_INTERVAL,
    });

  const useGetAllSocByVehicleIds: (
    vehicleIds?: string[],
  ) => UseQueryResult<VehicleSoc[] | undefined, Error> = (
    vehicleIds?: string[],
  ) =>
    useQuery({
      queryKey: ["vehicles", "soc", vehicleIds],
      queryFn: async () => {
        const response = await vehicleInfoApi.getAllSocByVehicleIds(vehicleIds);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
      select: transformVehicleSocs,
      enabled: !!vehicleIds,
      refetchInterval: VEHICLES_SOC_INTERVAL,
    });

  const useGetAllTripByVehicleIds: (
    vehicleIds?: string[],
  ) => UseQueryResult<VehicleTrip[], Error> = (vehicleIds?: string[]) =>
    useQuery({
      queryKey: ["vehicles", "trip", vehicleIds],
      queryFn: async () => {
        const response =
          await vehicleInfoApi.getAllTripByVehicleIds(vehicleIds);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
      enabled: !!vehicleIds,
    });

  const useGetRouteInfoForVehicle: (
    vehicleId?: string,
  ) => UseQueryResult<VehicleRoute, Error> = (vehicleId?: string) =>
    useQuery({
      queryKey: ["vehicle", "route", vehicleId],
      queryFn: async () => {
        if (!vehicleId) {
          return null;
        }

        const response =
          await vehicleInfoApi.useGetRouteInfoForVehicle(vehicleId);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
      enabled: !!vehicleId,
      refetchInterval: VEHICLE_ROUTE_INTERVAL,
    });

  return {
    useGetAllGps,
    useGetOneGpsByVehicleId,
    useGetAllSocByVehicleIds,
    useGetAllTripByVehicleIds,
    useGetRouteInfoForVehicle,
  };
};
