import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiState, useAsyncApi } from "helpers/useAsyncApi";
import { IncidentSchema } from "./incident";

export enum TransportType {
  BOAT = "BOAT",
  BUS = "BUS",
  TRAM = "TRAM",
  TRAIN = "TRAIN",
}

const VehicleGroupSchema = z.object({
  id: z.string(),
  description: z.string(),
  transportType: z.nativeEnum(TransportType),
});

const VehicleModelSchema = z.object({
  batteryCapacity: z.number().optional(),
  concession: z.number().optional(),
  id: z.number(),
  make: z.string(),
  model: z.string(),
  typeOfFuel: z
    .enum([
      "PETROL",
      "DIESEL",
      "NATURAL_GAS",
      "BIODIESEL",
      "ELECTRICITY",
      "HYDROGEN",
      "OTHER",
    ] as const)
    .optional(),
});

const VehicleSchema = z.object({
  concession: z.number(),
  description: z.string().optional(),
  id: z.string(),
  vehicleGroup: VehicleGroupSchema,
  vehicleModel: VehicleModelSchema,
});

export const VehicleWithDetailsSchema = z.object({
  currentBlockId: z.string().optional(),
  incidents: z.array(IncidentSchema),
  vehicle: VehicleSchema,
});

export type VehicleGroup = z.infer<typeof VehicleGroupSchema>;
export type VehicleModel = z.infer<typeof VehicleModelSchema>;
export type Vehicle = z.infer<typeof VehicleSchema>;
export type VehicleWithDetails = z.infer<typeof VehicleWithDetailsSchema>;

export class VehicleResource {
  public getAllVehiclesWithDetails = async (): Promise<
    IUseApiState<VehicleWithDetails[]>
  > => {
    return useAsyncApi<null, VehicleWithDetails[]>({
      endpoint: ENDPOINTS.QONTROL.VEHICLE.VEHICLE_WITH_DETAILS.buildPath({}),
      options: {
        method: "GET",
      },
      schema: z.array(VehicleWithDetailsSchema),
    });
  };

  public getOne = async (
    vehicleId?: string,
  ): Promise<IUseApiState<Vehicle>> => {
    return useAsyncApi<null, Vehicle>({
      endpoint: `${ENDPOINTS.QONTROL.VEHICLE.buildPath({})}/${vehicleId}`,
      options: {
        method: "GET",
      },
      schema: VehicleSchema,
    });
  };

  public getAllVehicleModelsByConcessionId = async (
    concessionId: string,
  ): Promise<IUseApiState<VehicleModel[]>> => {
    return useAsyncApi<null, VehicleModel[]>({
      endpoint: ENDPOINTS.QONTROL.VEHICLE.MODELS.buildPath({ concessionId }),
      options: {
        method: "GET",
      },
      schema: z.array(VehicleModelSchema),
    });
  };
}
