import { z } from "zod";

export namespace VehicleGPSPositionTable {
  export interface IVehicleGPSPosition {
    vehicleId: string;
    time: string;
    receivedTimestamp: number;
    latitude: number;
    longitude: number;
    accuracy: number;
  }

  /**
   * This is the array of the properties of the VehicleGPSPosition table.
   * This is used to create the table in the database, with type checking. (It will be converted to a comma-separated string in the Database.ts file)
   * @type {Array<keyof IUser>}
   */
  export const vehicleGPSPosition: Array<keyof IVehicleGPSPosition> = [
    "vehicleId",
  ];

  /**
   * This is the schema of the data that is coming from the server, this is used to validate the data
   */
  export const VehicleGPSPositionTypesSchema = z.object({
    vehicleId: z.string(),
    time: z.string(),
    receivedTimestamp: z.number(),
    latitude: z.number(),
    longitude: z.number(),
    accuracy: z.number(),
  });
}
