import { useEffect, useMemo, useState } from "react";
import { db } from "assets/database/Database";
import { chargePointTable } from "assets/database/tables/ChargePointTable";
import { ICollectorProps, getFetchMethod } from "./collectorHelper";
import { ChargePointEndpoint } from "./endpoints/ChargePointEndpoint";
import { EndpointStatusEnum } from "./endpoints/EndpointHelper";
import { useLiveQuery } from "dexie-react-hooks";

const table = db.chargePoint;

type IChargePointCollectorProps = Omit<
  ICollectorProps<chargePointTable.IDatabase>,
  "filter"
> & {
  filter?: chargePointTable.ILocalFilter[];
};

export const useChargePointCollector = ({
  cacheOnly,
  filterOnly,
  filter,
}: IChargePointCollectorProps) => {
  const data = useLiveQuery(async () => {
    try {
      if (filterOnly && !filter) {
        return undefined;
      }
      setError(false);
      setLoading(true);
      const cachedData = await table.toArray();
      const returnData = filter
        ? filterChargePointDataByCriteria(cachedData, filter)
        : cachedData;
      setLoading(false);

      return returnData;
    } catch {
      setError(true);
      return undefined;
    }
  }, [filterOnly, filter]);

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [insertStatus, setInsertStatus] = useState<EndpointStatusEnum>(
    EndpointStatusEnum.IDLE,
  );
  const [refreshIndex, setRefreshIndex] = useState(0);
  const filterPostData = useMemo(() => {
    const filteredItems = filter?.filter((item) => item !== undefined);
    return filteredItems?.length
      ? chargePointTable.convertDatabaseObjectToPostObject(filteredItems)
      : undefined;
  }, [filter]);

  const fetchData = async (type: "cache" | "network") => {
    if (!filter && filterOnly) {
      return setInsertStatus(EndpointStatusEnum.NO_DATA);
    }

    if (cacheOnly || type == "cache") {
      return;
    }

    setInsertStatus(EndpointStatusEnum.LOADING);
    ChargePointEndpoint(filterPostData).then(({ status }) => {
      setInsertStatus(status);
    });
  };

  useEffect(() => {
    fetchData(getFetchMethod(insertStatus));
  }, [filter]);

  useEffect(() => {
    if (insertStatus !== EndpointStatusEnum.LOADING) {
      setInsertStatus(EndpointStatusEnum.IDLE);
      fetchData("network");
    }
  }, [refreshIndex]);

  return {
    data,
    isLoading,
    error,
    InsertStatus: insertStatus,
    refresh: () => setRefreshIndex((prev) => prev + 1),
  };
};

const filterChargePointDataByCriteria = (
  data: chargePointTable.IDatabase[],
  filter: IChargePointCollectorProps["filter"],
) => {
  if (!filter) {
    return data;
  }

  return data.filter((data) =>
    filter.some((filter) => {
      return !filter.id || filter.id === data.id;
    }),
  );
};
