import styles from "./Card.module.scss";
import { CSSProperties, ReactNode, Ref } from "react";

interface CardProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  ref?: Ref<HTMLDivElement>;
}

export const Card = ({ children, className, style, ref }: CardProps) => {
  return (
    <div
      className={`${styles.card_element} ${className || ""}`}
      style={style}
      ref={ref}
    >
      {children}
    </div>
  );
};
