import mapboxgl from "mapbox-gl";
import { point } from "@turf/helpers";
import { bearing } from "@turf/bearing";
import { distance } from "@turf/distance";

export const useMapFunctions = (map: mapboxgl.Map | null) => {
  const setMapCenter = (coordinates: [number, number]) => {
    map?.setCenter(coordinates);
  };

  const setMapZoom = (zoom: number) => {
    map?.setZoom(zoom);
  };

  const flyTo = (options: mapboxgl.FlyToOptions) => {
    map?.flyTo(options, { mapIsMoving: true });
  };

  const calculateBearing = (
    coord1: [number, number],
    coord2: [number, number],
  ) => {
    // Ensure coordinates are in the format [longitude, latitude]
    const point1 = point(coord1);
    const point2 = point(coord2);

    // Calculate the bearing between the two points
    const calculatedBearing = bearing(point1, point2);

    return calculatedBearing;
  };

  const calculateDistance = (
    coord1: [number, number],
    coord2: [number, number],
  ) => {
    // Ensure coordinates are in the format [longitude, latitude]
    const point1 = point(coord1);
    const point2 = point(coord2);

    // Set the options for the distance calculation, using typescript Parameters to get the type of the distance function options parameter
    const options: Parameters<typeof distance>[2] = {
      units: "meters",
    };
    // Calculate the distance between the two points
    const calculatedDistance = distance(point1, point2, options);

    return calculatedDistance;
  };

  const addImageToMap = (imageId: string, image: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      map?.loadImage(image, (error, image) => {
        if (error || !image) {
          return reject(error);
        }

        if (map.hasImage(imageId)) {
          return resolve(imageId);
        }
        map.addImage(imageId, image);
        resolve(imageId);
      });
    });
  };

  return {
    setMapCenter,
    setMapZoom,
    flyTo,
    calculateBearing,
    calculateDistance,
    addImageToMap,
  };
};
