import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { RouteResource, type Route } from "./resources/route";

export const useRoute = () => {
  const routeApi = new RouteResource();

  const useGetOne: (
    routeId?: string,
    tripNumber?: string,
    reinforcementSequence?: string,
  ) => UseQueryResult<Route, Error> = (
    routeId?: string,
    tripNumber?: string,
    reinforcementSequence?: string,
  ) =>
    useQuery({
      queryKey: ["route", routeId, tripNumber, reinforcementSequence],
      queryFn: async () => {
        const response = await routeApi.getOne(
          String(routeId),
          String(tripNumber),
          String(reinforcementSequence),
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
      enabled: !!routeId && !!tripNumber && !!reinforcementSequence,
    });

  return { useGetOne };
};
