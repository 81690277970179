import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { Radio, RadioChangeEvent, Space, Switch } from "antd";
import { addDays } from "date-fns";
import { Severity, useToasts } from "@id/frontend.components";
import PinPointIcon from "assets/img/icons/svg/PinPointIcon.svg";
import { AutoSize } from "components/elements/autosize/AutoSize";
import { UserName } from "components/elements/button/UserName";
import { Header } from "components/header/Header";
import { SupportStaff } from "components/support-staff/SupportStaff";
import { TimeCard } from "components/time-card/TimeCard";
import { getEnvironmentVariable } from "helpers/EnvironmentVariableUtils";
import { EndpointStatusEnum } from "hooks/collectors/endpoints/EndpointHelper";
import {
  TimeCardEndpoint,
  TimeCardType,
} from "hooks/collectors/endpoints/TimeCardEndpoint";
import { SaveCurrentJob } from "hooks/collectors/endpoints/support-staff/SaveCurrentJob";
import { SignOff } from "hooks/collectors/endpoints/support-staff/SignOff";
import { useUser } from "hooks/react-query";
import styles from "./Contact.module.scss";

window.GPSToggleFunction = undefined;

export const Contact = () => {
  const { t } = useTranslation();
  const [sortedTimeCardData, setSortedTimeCardData] = useState<TimeCardType[]>(
    [],
  );
  const [loadedTimeCardData, setLoadedTimeCardData] = useState<boolean>(false);
  const [GPSSwitchEnabled, setGPSSwitchEnabled] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const [isAvailable, setIsAvailable] = useState<boolean>(true);
  const [currentJob, setCurrentJob] = useState<string | null>();
  const { addToast } = useToasts();

  const getUser = useUser().useGetUser();

  const storeCurrentJob = async (
    currentJob: string | null,
    isAvailable: boolean,
  ) => {
    const response = await SaveCurrentJob({
      currentJob,
    });

    if (response.status !== EndpointStatusEnum.SUCCESS) {
      addToast(t("pages.contact.failed"), Severity.Error);
      return;
    }

    if (isAvailable) {
      addToast(t("pages.contact.status_set_to_available"), Severity.Success);
      return;
    }

    addToast(
      t("pages.contact.current_job_stored_successfully"),
      Severity.Success,
    );
  };

  const signOff = async () => {
    const response = await SignOff();

    if (response.status === EndpointStatusEnum.SUCCESS) {
      addToast(t("pages.contact.sign_off_successfully"), Severity.Success);
      setIsAvailable(true);
      setCurrentJob(null);

      return;
    }

    addToast(t("pages.contact.failed"), Severity.Error);
  };

  const fetchTimeCardDataPerDay = async (number: number) => {
    const date = addDays(new Date(), number);

    const { status, data } = await TimeCardEndpoint(date);

    if (status !== EndpointStatusEnum.SUCCESS || !data) {
      setLoadedTimeCardData(true);
      return;
    }

    return data;
  };

  const fetchTimeCardData = async () => {
    const promises = [];

    for (const value of Array.from(Array(8).keys())) {
      promises.push(fetchTimeCardDataPerDay(value));
    }

    const data = await Promise.all(promises);
    const filteredData = data.filter(
      (item): item is TimeCardType => item !== undefined,
    );
    if (!filteredData) {
      return;
    }

    filteredData.sort((a, b) => a.operatingDay.localeCompare(b.operatingDay));

    setSortedTimeCardData(filteredData);
    setLoadedTimeCardData(true);
  };

  const onEnableGpsTrackingClick = () => {
    if (window.Android) {
      window.Android.enableForegroundGPSTracking();
    }
  };

  const onDisableGpsTrackingClick = () => {
    if (window.Android) {
      window.Android.disableForegroundGPSTracking();
    }
  };

  useEffect(() => {
    if (sortedTimeCardData.length === 0) {
      fetchTimeCardData();
    }
  }, []);

  const onGPSSwitchClick = async (checked: boolean) => {
    setGPSSwitchEnabled(checked);

    if (!checked) {
      signOff();
    }

    if (!window.Android) {
      return;
    }

    if (checked) {
      onEnableGpsTrackingClick();
    } else {
      onDisableGpsTrackingClick();
    }

    const accessToken = await getAccessTokenSilently();
    if (window.Android.sendAccessToken) {
      window.Android.sendAccessToken(
        accessToken,
        getEnvironmentVariable("API_URL"),
      );
    }
  };

  // Setting this to the window object, makes it so the android app can toggle this switch.
  useEffect(() => {
    window.GPSToggleFunction = setGPSSwitchEnabled;

    if (!window.Android) {
      return;
    }
    const foregroundServiceStatus: boolean =
      (window.Android.checkForegroundServiceStatus &&
        window.Android.checkForegroundServiceStatus()) ??
      false;
    setGPSSwitchEnabled(foregroundServiceStatus);
  }, []);

  const CurrentTaskSelector = () => {
    if (isAvailable) {
      return null;
    }

    return (
      <div className={`${styles.location_button} ${styles.tasks}`}>
        <div className={styles.block}>
          <Radio.Group
            buttonStyle="solid"
            onChange={onChangeCurrentJob}
            value={currentJob}
          >
            <Space direction="vertical">
              <Radio.Button value={t("pages.contact.tasks.starting_shift")}>
                {t("pages.contact.tasks.starting_shift")}
              </Radio.Button>
              <Radio.Button
                value={t("pages.contact.tasks.bus_switch_in_operation")}
              >
                {t("pages.contact.tasks.bus_switch_in_operation")}
              </Radio.Button>
              <Radio.Button
                value={t("pages.contact.tasks.driver_shift_takeover")}
              >
                {t("pages.contact.tasks.driver_shift_takeover")}
              </Radio.Button>
              <Radio.Button value={t("pages.contact.tasks.accident_support")}>
                {t("pages.contact.tasks.accident_support")}
              </Radio.Button>
              <Radio.Button value={t("pages.contact.tasks.logistical_work")}>
                {t("pages.contact.tasks.logistical_work")}
              </Radio.Button>
              <Radio.Button
                value={t("pages.contact.tasks.minor_outage_repair")}
              >
                {t("pages.contact.tasks.minor_outage_repair")}
              </Radio.Button>
            </Space>
          </Radio.Group>
        </div>
      </div>
    );
  };

  const IsAvailableContent = () => {
    if (!GPSSwitchEnabled) {
      return null;
    }

    return (
      <div className={styles.location_button}>
        <div className={styles.icon}></div>
        <div className={styles.block}>
          {t("pages.contact.are_you_available")}?
        </div>
        <div className={styles.right_container}>
          <Switch
            className={`${styles.switch} ${isAvailable ? styles.on : styles.off}`}
            defaultValue={isAvailable}
            onChange={onChangeAvailable}
          />
        </div>
      </div>
    );
  };

  const headerDropdownContent = (() => {
    if (!window.Android) {
      return null;
    }

    return (
      <>
        <a
          className={styles.location_button}
          onClick={() => onGPSSwitchClick(!GPSSwitchEnabled)}
        >
          <div className={styles.icon}>
            <img src={PinPointIcon} alt="Pinpoint" />
          </div>
          <div className={styles.text}>{t("pages.contact.location")}</div>
          <div className={styles.right_container}>
            <div className={styles.text}>
              <AutoSize mode="box">
                {GPSSwitchEnabled
                  ? t("pages.contact.location_available")
                  : t("pages.contact.location_not_available")}
              </AutoSize>
            </div>
            <Switch
              className={`${styles.switch} ${GPSSwitchEnabled ? styles.on : styles.off}`}
              checked={GPSSwitchEnabled}
              defaultChecked={GPSSwitchEnabled}
            />
          </div>
        </a>

        <IsAvailableContent />
        <CurrentTaskSelector />
      </>
    );
  })();

  const onChangeAvailable = (isAvailable: boolean) => {
    setIsAvailable(isAvailable);
    if (isAvailable) {
      setCurrentJob(null);
      storeCurrentJob(null, isAvailable);
    }
  };

  const onChangeCurrentJob = (e: RadioChangeEvent) => {
    setCurrentJob(e.target.value);
    storeCurrentJob(e.target.value, isAvailable);
  };

  return (
    <div className={styles.contact_container}>
      <Header
        className={styles.contact_header}
        rightIconEnabled={false}
        dropdownEnabled={true}
        dropdownOpenByDefault={true}
        dropdownChildren={headerDropdownContent}
      >
        {getUser.data && (
          <>
            <div className={styles.title}>
              <AutoSize>
                <UserName user={getUser.data} />
              </AutoSize>
            </div>
            <div className={styles.subtitle}>
              <AutoSize>{getUser.data.email}</AutoSize>
            </div>
          </>
        )}
      </Header>

      <div className={styles.time_card_container}>
        <div className={styles.time_card_header}>
          <div className={styles["time_card-icon"]}>
            <i className="q-icons">“</i>
          </div>

          <div className={styles.time_card_title}>
            {t("time_card.header")}
            <span>
              {getUser.data?.impersonate
                ? `${getUser.data?.impersonate?.firstName} - ${getUser.data?.impersonate?.lastName}`
                : null}
            </span>
          </div>
        </div>
        {!loadedTimeCardData && (
          <div className={styles.time_card_box}>
            <div className={styles.time_card_date}>
              {t("functional.loading")}
            </div>
          </div>
        )}
        {(!sortedTimeCardData || sortedTimeCardData.length === 0) &&
          loadedTimeCardData && (
            <div className={styles.time_card_box}>
              <div className={styles.time_card_date}>
                {t("time_card.nodata")}
              </div>
            </div>
          )}
        {sortedTimeCardData
          ?.filter(
            (item) => item.dutyDescription && item.dutyDescription.length > 0,
          )
          .map((item) => <TimeCard key={item.operatingDay} timeCard={item} />)}
      </div>
      <div className={styles.time_card_container}>
        <div className={styles.time_card_header}>
          <div className={styles["time_card-icon"]}>
            <i className="q-icons">“</i>
          </div>
          <div className={styles.time_card_title}>
            {t("support_staff.header")}
          </div>
        </div>
        <div className={styles.time_card_box}>
          <SupportStaff />
        </div>
      </div>
    </div>
  );
};
