import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import {
  addRouteLayers,
  vehicleGPSLayerIds,
} from "hooks/mapbox/helpers/Layers";
import { addRouteImage } from "hooks/mapbox/helpers/Images";
import { useVehicleInfo } from "hooks/react-query";
import { VehicleRoute } from "hooks/react-query/resources/vehicleInfo";

export const vehicleRouteSourceId = "vehicle-route-source-id";

type FleetVehicleMapRouteEventsProps = {
  readonly vehicleId: string | undefined;
};

export const useFleetVehicleMapRouteEvents = ({
  vehicleId,
}: FleetVehicleMapRouteEventsProps) => {
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [routeData, setRouteData] = useState<VehicleRoute | null>(null);

  const getVehicleRoute = useVehicleInfo().useGetRouteInfoForVehicle(vehicleId);
  useEffect(() => {
    if (vehicleId && getVehicleRoute.data) {
      setRouteData(getVehicleRoute.data);
    } else {
      setRouteData(null);
    }
  }, [vehicleId, getVehicleRoute]);

  useEffect(() => {
    if (!map) {
      return;
    }

    addLayers();
  }, [map, routeData]);

  // Memorize the geojson data, so it only updates when the vehicleGPSPositionData changes
  const geojson = () => {
    if (!routeData?.features || routeData.features.length === 0) {
      return undefined;
    }
    try {
      return JSON.parse(JSON.stringify(routeData));
    } catch {
      return undefined;
    }
  };

  // Add the layers to the map
  const addLayers = async () => {
    if (!map) {
      return;
    }

    removeLayers();

    const data = geojson() ?? {
      type: "FeatureCollection",
      features: [],
    };

    map.addSource(vehicleRouteSourceId, {
      type: "geojson",
      data: data,
    });

    await addRouteImage(map);
    addRouteLayers(map);
  };

  // Remove the layers from the map
  const removeLayers = () => {
    if (!map) {
      return;
    }
    if (map.hasImage(vehicleGPSLayerIds.route_arrow_image)) {
      map.removeImage(vehicleGPSLayerIds.route_arrow_image);
    }

    if (map.getLayer(vehicleGPSLayerIds.route_line_layer)) {
      map.removeLayer(vehicleGPSLayerIds.route_line_layer);
    }

    if (map.getLayer(vehicleGPSLayerIds.route_arrow_layer)) {
      map.removeLayer(vehicleGPSLayerIds.route_arrow_layer);
    }
    if (map.getLayer(vehicleGPSLayerIds.route_arrow_image)) {
      map.removeLayer(vehicleGPSLayerIds.route_arrow_image);
    }
    if (map.getSource(vehicleRouteSourceId)) {
      map.removeSource(vehicleRouteSourceId);
    }
  };

  return { setMap };
};
