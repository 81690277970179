import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { registerSW } from "virtual:pwa-register";
import { MainProvider } from "./providers/MainProvider";
import { store } from "./state/Store";
import "./assets/localization/i18n";
import "./main.scss";
import "./styles/_variables.scss";

// This will trigger a page reload once there is a new service worker available (e.g. a new version of the app)
registerSW({ immediate: true });

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <MainProvider />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
);
