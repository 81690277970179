import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiState, useAsyncApi } from "helpers/useAsyncApi";

export enum Role {
  SUPPORT_STAFF = "qontrol_supportstaff",
}

const ImpersonateSchema = z.object({
  email: z.string(),
  employeeId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
});

const UserSchema = z.object({
  concessions: z.array(z.number()),
  email: z.string(),
  impersonate: ImpersonateSchema.optional(),
  roles: z.array(z.string()),
});

export type User = z.infer<typeof UserSchema>;

export class UserResource {
  public getUser = async (): Promise<IUseApiState<User>> => {
    return useAsyncApi<null, User>({
      endpoint: ENDPOINTS.QONTROL.ME.buildPath({}),
      options: {
        method: "GET",
      },
      schema: UserSchema,
    });
  };
}
