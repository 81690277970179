import { useTranslation } from "react-i18next";
import { Col, List, Row, Tabs } from "antd";
import { Loader } from "components/loader/Loader";
import { DateUtils } from "helpers/DateUtils";
import { useIncident } from "hooks/react-query";
import styles from "./Incidents.module.scss";
import { PageError } from "components/error/PageError";
import { IncidentType } from "hooks/react-query/resources/incident";

type IncidentsProps = {
  readonly vehicleId: string;
};

export const Incidents = ({ vehicleId }: IncidentsProps) => {
  const { t } = useTranslation();

  const getIncidents = useIncident().useGetOneByVehicleId(vehicleId);

  const renderElement = (element?: string) => {
    return element && element?.length > 0 ? (
      <Col span={12}>{element}</Col>
    ) : null;
  };

  const renderNoIncidents = () => {
    return (
      <div className={styles.no_incidents}>
        {t("functional.no_open_incidents")}
      </div>
    );
  };

  if (getIncidents.isLoading) {
    return (
      <div className={styles.loading}>
        <Loader centerInPage={false} />
        <p>{t("functional.loading")}</p>
      </div>
    );
  }

  if (getIncidents.isError) {
    return <PageError reloadPage={false} />;
  }

  if (getIncidents.data === undefined || getIncidents.data.length === 0) {
    return renderNoIncidents();
  }

  const renderMaterialIncidents = () => {
    const materialIncidents = getIncidents.data.filter(
      (incident) => incident.incidentDetail.type === IncidentType.MATERIAL,
    );

    if (materialIncidents.length === 0) {
      return renderNoIncidents();
    }

    return (
      <List
        dataSource={materialIncidents}
        bordered
        renderItem={(item) => (
          <div className={styles.incident_row}>
            <List.Item>
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  {item.id} -{" "}
                  {DateUtils.formatDate(item.createdAt, "dd-MM-yyyy")}
                </Col>
                <Col span={24}>{item.incidentDetail.defectType}</Col>
                {item.incidentDetail.materialFaultCodeBus ? (
                  <>
                    {renderElement(
                      item.incidentDetail.materialFaultCodeBus.category,
                    )}
                    {renderElement(
                      item.incidentDetail.materialFaultCodeBus.subCategory,
                    )}
                    {renderElement(
                      item.incidentDetail.materialFaultCodeBus.subSubCategory,
                    )}
                    {renderElement(
                      item.incidentDetail.materialFaultCodeBus
                        .subSubSubCategory,
                    )}
                    <Col span={12}>
                      {item.incidentDetail.materialFaultCodeBus.faultCode} (
                      {item.incidentDetail.materialFaultCodeBus.priority})
                    </Col>
                  </>
                ) : null}
                <Col span={24}>{item.text}</Col>
              </Row>
            </List.Item>
          </div>
        )}
      ></List>
    );
  };

  const renderEquipmentIncidents = () => {
    const equipmentIncidents = getIncidents.data.filter(
      (incident) => incident.incidentDetail.type === IncidentType.EQUIPMENT,
    );

    if (equipmentIncidents.length === 0) {
      return renderNoIncidents();
    }

    return (
      <List
        dataSource={equipmentIncidents}
        bordered
        renderItem={(item) => (
          <List.Item>
            <Row gutter={[0, 8]}>
              <Col span={24}>
                {item.id} - {DateUtils.formatDate(item.createdAt, "dd-MM-yyyy")}
              </Col>
              <Col span={24}>{item.incidentDetail.equipmentType}</Col>
              {item.incidentDetail.equipmentCode ? (
                <Col span={24}>{item.incidentDetail.equipmentCode}</Col>
              ) : null}
              <Col span={24}>{item.text}</Col>
            </Row>
          </List.Item>
        )}
      ></List>
    );
  };

  return (
    <Tabs
      items={[
        {
          label: t("functional.MATERIAL"),
          key: "material-incidents",
          children: renderMaterialIncidents(),
        },
        {
          label: t("functional.EQUIPMENT"),
          key: "equipment-incidents",
          children: renderEquipmentIncidents(),
        },
      ]}
    />
  );
};
