import { AnimatePresence, motion } from "framer-motion";
import { AutoSize } from "components/elements/autosize/AutoSize";
import { Button } from "components/elements/button/Button";
import styles from "./Header.module.scss";
import { useState } from "react";
import DropdownIcon from "assets/img/icons/svg/DropdownIcon.svg";

interface HeaderProps {
  className?: string;
  children: React.ReactNode;
  leftIcon?: string;
  leftIconEnabled?: boolean;
  onLeftIconClick?: () => void;
  headerTitle?: string;
  rightIcon?: string;
  rightIconEnabled?: boolean;
  onRightIconClick?: () => void;
  dropdownEnabled?: boolean;
  dropdownOpenByDefault?: boolean;
  dropdownChildren?: React.ReactNode;
}

export const Header = ({
  className,
  children,
  leftIcon,
  leftIconEnabled,
  onLeftIconClick,
  headerTitle,
  rightIcon,
  rightIconEnabled,
  onRightIconClick,
  dropdownEnabled,
  dropdownOpenByDefault,
  dropdownChildren,
}: HeaderProps) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(
    dropdownOpenByDefault || false,
  );

  return (
    <header className={`${styles["generic-header"]} ${className}`}>
      <div className={`${styles.content} ${styles["no-bottom-border-radius"]}`}>
        {leftIconEnabled && (
          <Button
            onClickEvent={onLeftIconClick}
            background="standard"
            className={`${styles.button} ${styles.button_left}`}
          >
            <img src={leftIcon} alt="Back" />
          </Button>
        )}

        {leftIconEnabled && <div className={styles.spacer}></div>}

        <div className={styles.container}>
          {!children && (
            <div className={styles.title}>
              <AutoSize>{headerTitle}</AutoSize>
            </div>
          )}

          {children}
        </div>

        {rightIconEnabled && <div className={styles.spacer}></div>}

        {rightIconEnabled && (
          <Button
            background="standard"
            className={`${styles.button} ${styles.button_right}`}
            onClickEvent={onRightIconClick}
          >
            <img src={rightIcon} alt="Info" />
          </Button>
        )}

        {!dropdownOpenByDefault && dropdownEnabled && (
          <Button
            background="standard"
            className={`${styles.button} ${styles.button_dropdown} ${dropdownOpen ? styles.dropdown_open : ""}`}
            onClickEvent={() => setDropdownOpen(!dropdownOpen)}
          >
            <img src={DropdownIcon} alt="Dropdown" />
          </Button>
        )}
      </div>
      {dropdownEnabled && (
        <>
          {dropdownOpenByDefault && dropdownChildren && (
            <div className={styles.dropdown}>{dropdownChildren}</div>
          )}
          {!dropdownOpenByDefault && dropdownChildren && (
            <AnimatePresence>
              {dropdownOpen && (
                <motion.div
                  initial={{ height: 0, overflow: "hidden" }}
                  animate={{ height: "auto" }}
                  exit={{ height: 0 }}
                  transition={{ duration: 0.3 }}
                  className={styles.dropdown}
                >
                  {dropdownChildren}
                </motion.div>
              )}
            </AnimatePresence>
          )}
        </>
      )}
    </header>
  );
};
