import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { ConcessionResource, type Concession } from "./resources/concession";

export const useConcession = () => {
  const concessionApi = new ConcessionResource();

  const useGetAll: () => UseQueryResult<Concession[], Error> = () =>
    useQuery({
      queryKey: ["concession"],
      queryFn: async () => {
        const response = await concessionApi.getAll();

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
    });

  return { useGetAll };
};
