/**
 * The refetch interval duration for the vehicle with details API requests, in milliseconds.
 * @constant
 * @returns 1800000 ms (30 minutes)
 */
export const VEHICLES_WITH_DETAILS_INTERVAL = 1_800_000;

/**
 * The refetch interval duration for the vehicle GPS API requests, in milliseconds.
 * @constant
 * @returns 1000 ms (1 second)
 */
export const VEHICLE_GPS_INTERVAL = 1_000;

/**
 * The refetch interval duration for the all vehicles GPS API requests, in milliseconds.
 * @constant
 * @returns 5000 ms (5 secondes)
 */
export const ALL_VEHICLES_GPS_INTERVAL = 5_000;

/**
 * The refetch interval duration for the vehicle soc API requests, in milliseconds.
 * @constant
 * @returns 20000 ms (20 seconds)
 */
export const VEHICLES_SOC_INTERVAL = 20_000;

/**
 * The refetch interval duration for the vehicle route API requests, in milliseconds.
 * @constant
 * @returns 30000 ms (30 seconds)
 */
export const VEHICLE_ROUTE_INTERVAL = 30_000;

/**
 * The coordinate of the center of the Netherlands
 * @constant
 * @returns array of latitude and longitude
 */
export const COORDINATES_CENTER_NETHERLANDS = [5.2913, 52.1326];

/**
 * The default zoom when returning to main map
 * @constant
 * @returns number of zoom
 */
export const DEFAULT_ZOOM_ON_RETURN = 8;
