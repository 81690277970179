import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { AutoSize } from "components/elements/autosize/AutoSize";
import { UserName } from "components/elements/button/UserName";
import { ROUTES } from "features/pages/Routes";
import { getEnvironmentVariable } from "helpers/EnvironmentVariableUtils";
import { useUser } from "hooks/react-query";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import styles from "./SideMenu.module.scss";

export const SideMenu = () => {
  const { logout } = useAuth0();
  const { sideMenuVisible } = useAppSelector((state) => state.AppStateReducer);
  const { setSideMenuVisible } = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const getUser = useUser().useGetUser();

  useEffect(() => {
    if (!sideMenuVisible) {
      setIsOpen(false);
      return;
    }

    setIsOpen(true);
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [sideMenuVisible]);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setSideMenuVisible(false);
    }
  };

  const onCloseClick = () => {
    setSideMenuVisible(false);
  };

  const dockerVersionValue = useMemo(() => {
    const version = getEnvironmentVariable("DOCKER_IMAGE_VERSION");
    const versionSplit = version.includes("-")
      ? version.split("-")[0]
      : version;

    return versionSplit;
  }, []);

  const onQKCAClick = () => {
    navigate(ROUTES.QUALITY_MEASUREMENTS.buildPath({}));
    onCloseClick();
  };

  return (
    <div className={styles.side_menu_container}>
      <div
        className={`${styles.background} ${isOpen ? styles.open : ""}`}
        onClick={onCloseClick}
      ></div>
      <div className={`${styles.side_menu} ${isOpen ? styles.open : ""}`}>
        <header className={styles.header}>
          <div className={styles.user_icon}>
            <i className="q-icons">W</i>
          </div>
          <div className={styles.user_details}>
            <AutoSize>
              {getUser.data && <UserName user={getUser.data} />}
            </AutoSize>
          </div>
          <button className={styles.close_icon} onClick={onCloseClick}>
            <i className="q-icons">Í</i>
          </button>
        </header>
        <div className={styles.menu_items}>
          <div className={styles.items}>
            <button
              className={styles.item}
              disabled={false}
              onClick={onQKCAClick}
            >
              <div className={styles.icon}>
                <i className="q-icons">œ</i>
              </div>
              <div className={styles.text}>
                <AutoSize>{t("side_menu.items.quality_measurements")}</AutoSize>
              </div>
            </button>
          </div>
          <div className={styles.items}>
            <button
              className={styles.item}
              onClick={() =>
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                })
              }
            >
              <div className={styles.icon}>
                <i className="q-icons" style={{ transform: "rotate(90deg)" }}>
                  h
                </i>
              </div>
              <div className={styles.text}>
                <AutoSize>{t("side_menu.items.logout")}</AutoSize>
              </div>
            </button>
          </div>
        </div>
        <footer>
          <AutoSize maxFontSizePx={10}>
            {t("side_menu.footer.version")} {dockerVersionValue}
          </AutoSize>
        </footer>
      </div>
    </div>
  );
};
