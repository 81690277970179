import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * Defines the interfaces and initial state for the QKCAReducer.
 *
 * @interface IQuestion - Defines the structure of a question, including its options and validation rules.
 * @interface IOption - Defines the structure of an option for a question.
 * @interface IQuestionnaireState - Defines the structure of the state for the QuestionnaireReducer.
 * @constant initialState - Defines the initial state for the QuestionnaireReducer.
 * @constant QuestionnaireReducer - Defines the reducer for the QKCA feature.
 */

interface IQuestionnaireState {
  header: {
    showBackArrow: boolean;
    headerTitle: string;
  };
  questionsView: {
    previousQuestionIndexes: number[];
    questionIndex: number;
    questionList: IQuestionnaire | null;
    previousButtonEnabled: boolean;
    nextButtonEnabled: boolean;
  };
  answerList: IQuestionnaireAnswer;
}

export interface IQuestionnaire {
  questionnaireId: string;
  version: number;
  validFrom: string;
  validTo: string;
  questions: IQuestion[];
  title: string;
}

export enum QUESTION_TYPE {
  RADIO = "RADIO",
  PICTURE = "PICTURE",
  DESCRIPTION = "DESCRIPTION",
  INPUT = "INPUT",
  TEXTAREA = "TEXTAREA",
  EMAIL = "EMAIL",
  TEL = "TEL",
  DATE = "DATE",
  TIME = "TIME",
  NUMBER = "NUMBER",
}

type QuestionBase = {
  questionId: string;
  text: string;
  order: number;
  explanation?: string;
};

export type IQuestion = QuestionBase &
  (
    | {
        type:
          | QUESTION_TYPE.INPUT
          | QUESTION_TYPE.TEXTAREA
          | QUESTION_TYPE.NUMBER
          | QUESTION_TYPE.EMAIL
          | QUESTION_TYPE.TEL
          | QUESTION_TYPE.DATE
          | QUESTION_TYPE.TIME;
        inputPlaceholder?: string;
        inputLengths?: {
          min: number;
          max: number;
        };
        zodCheckType?: "default" | "email" | "phone" | "regex";
        zodRegex?: string;
        parentQuestionId?: string;
        parentQuestionOptionIds?: string[];
        showWithParent?: boolean;
        required: boolean;
        fetchData?: {
          path: string;
          type: "GET" | "POST";
          urlParams?: {
            [key: string]: string;
          };
          trigger: "onQuestionMount" | "onQuestionInputChange";
        };
      }
    | {
        type: QUESTION_TYPE.DESCRIPTION;
        parentQuestionId?: string;
        parentQuestionOptionIds?: string[];
        showWithParent?: boolean;
      }
    | {
        type: QUESTION_TYPE.PICTURE;
        parentQuestionId?: string;
        parentQuestionOptionIds?: string[];
        showWithParent?: boolean;
        required: boolean;
        commentRequired: boolean;
      }
    | {
        type: QUESTION_TYPE.RADIO;
        options: IOption[];
        parentQuestionId?: string;
        parentQuestionOptionIds?: string[];
        showWithParent?: boolean;
        required: boolean;
        imageRequired: boolean;
        commentRequired: boolean;
        fetchData?: {
          path: string;
          type: "GET" | "POST";
          urlParams?: {
            [key: string]: string;
          };
          trigger: "onQuestionMount" | "onQuestionOptionSelect";
        };
      }
  );

interface IOption {
  optionId: string;
  order: number;
  text: string;
  value?: string;
}

export interface IQuestionnaireAnswer {
  questionnaireId: string;
  version: number;
  answers: IAnswer[];
}

export interface IAnswer {
  questionId: string;
  optionIds?: string[];
  explanation?: string;
  imageIds?: string[];
  order?: number;
}

const initialState: IQuestionnaireState = {
  header: {
    showBackArrow: true,
    headerTitle: "",
  },
  questionsView: {
    previousQuestionIndexes: [],
    questionIndex: 0,
    questionList: null,
    previousButtonEnabled: true,
    nextButtonEnabled: true,
  },
  answerList: {
    questionnaireId: "",
    version: 0,
    answers: [],
  },
};

export const QuestionnaireReducer = createSlice({
  name: "QuestionnaireReducer",
  initialState: initialState,
  reducers: {
    SetQuestionnaireHeaderState: (
      state,
      action: PayloadAction<Partial<IQuestionnaireState["header"]>>,
    ) => {
      state.header = {
        ...state.header,
        ...action.payload,
      };
    },
    SetQuestionnaireViewState: (
      state,
      action: PayloadAction<Partial<IQuestionnaireState["questionsView"]>>,
    ) => {
      state.questionsView = {
        ...state.questionsView,
        ...action.payload,
      };
    },
    SetQuestionnaireAnswersState: (
      state,
      action: PayloadAction<Partial<IQuestionnaireState["answerList"]>>,
    ) => {
      state.answerList = {
        ...state.answerList,
        ...action.payload,
      };
    },
    insertOrUpdateQuestionnaireAnswer: (
      state,
      action: PayloadAction<IAnswer>,
    ) => {
      const index = state.answerList.answers.findIndex(
        (answer) => answer.questionId === action.payload.questionId,
      );
      if (index === -1) {
        state.answerList.answers.push(action.payload);
      } else {
        state.answerList.answers[index] = {
          ...state.answerList.answers[index],
          ...action.payload,
        };
      }
    },
    removeQuestionnaireAnswer: (state, action: PayloadAction<string>) => {
      const index = state.answerList.answers.findIndex(
        (answer) => answer.questionId === action.payload,
      );
      if (index !== -1) {
        state.answerList.answers.splice(index, 1);
      }
    },
  },
});
