import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiState, useAsyncApi } from "helpers/useAsyncApi";

export enum IncidentType {
  MATERIAL = "MATERIAL",
  EQUIPMENT = "EQUIPMENT",
  DAMAGE_VEHICLE = "DAMAGE_VEHICLE",
}

const VehicleIncidentCategorySchema = z.object({
  category: z.string().optional(),
  subCategory: z.string().optional(),
  subSubCategory: z.string().optional(),
  subSubSubCategory: z.string().optional(),
  priority: z.enum(["A", "B", "C"]).optional(),
  faultCode: z.string().optional(),
});

export const IncidentSchema = z.object({
  createdAt: z.coerce.date(),
  id: z.number(),
  incidentDetail: z.object({
    damageFaultCodeBus: VehicleIncidentCategorySchema.optional(),
    defect: z.string().optional(),
    defectType: z.string().optional(),
    equipmentCode: z.string().optional(),
    equipmentType: z.string().optional(),
    materialFaultCodeBus: VehicleIncidentCategorySchema.optional(),
    type: z.nativeEnum(IncidentType).optional(),
  }),
  incidentType: z.string().optional(),
  updatedAt: z.coerce.date().optional(),
  text: z.string().optional(),
  vehicleIds: z.array(z.string()),
});

export type Incident = z.infer<typeof IncidentSchema>;

export class IncidentResource {
  public getOneByVehicleId = async (
    vehicleId?: string,
  ): Promise<IUseApiState<Incident[]>> => {
    return useAsyncApi<null, Incident[]>({
      endpoint: ENDPOINTS.QONTROL.INCIDENT.VEHICLE.buildPath({
        vehicleId,
      }),
      options: {
        method: "GET",
      },
      schema: z.array(IncidentSchema),
    });
  };
}
