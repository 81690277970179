import { IQuestionnaireAnswer } from "state/reducers/QuestionnaireReducer";

export namespace QualityMeasurementConceptsTable {
  export interface IQualityMeasurementConcepts {
    conceptId: string;
    previousQuestionIndexes: number[];
    questionIndex: number;
    answerList: IQuestionnaireAnswer;
    timestamp: string;
  }

  /**
   * This is the array of the properties of the qualityMeasurementConcept table.
   * This is used to create the table in the database, with type checking. (It will be converted to a comma-separated string in the Database.ts file)
   * @type {Array<keyof IQualityMeasurementConcepts>}
   */
  export const qualityMeasurementConceptTypes: Array<
    keyof IQualityMeasurementConcepts
  > = ["conceptId", "questionIndex", "answerList", "timestamp"];
}
