import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiOptions, useAsyncApi } from "helpers/useAsyncApi";
import { EndpointStatusEnum } from "./EndpointHelper";

const BlockActivitySchema = z.object({
  route: z.string(),
  tripNumber: z.number(),
  arrivalStop: z.string().optional(),
  arrivalTime: z.number().optional(),
  departureStop: z.string().optional(),
  departureTime: z.number().optional(),
});

export type BlockActivityType = z.infer<typeof BlockActivitySchema>;

export const BlockActivitiesEndpoint = async (blockId: string) => {
  try {
    const endpoint = ENDPOINTS.QONTROL.BLOCK.BLOCK_ACTIVITIES.buildPath({
      blockId,
    });
    const options: IUseApiOptions = {
      method: "GET",
    };
    const requestOptions = {
      endpoint,
      options,
      schema: z.array(BlockActivitySchema),
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await useAsyncApi<null, BlockActivityType[]>(
      requestOptions,
    );
    if (response.error || response.status !== 200) {
      return { status: EndpointStatusEnum.ERROR, data: null };
    }

    if (!response.data) {
      return { status: EndpointStatusEnum.NO_DATA, data: null };
    }

    return { status: EndpointStatusEnum.SUCCESS, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: EndpointStatusEnum.ERROR, data: null };
  }
};
