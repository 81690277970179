import { UseQueryResult, useQueries, useQuery } from "@tanstack/react-query";
import {
  VehicleResource,
  type Vehicle,
  type VehicleModel,
  VehicleWithDetails,
} from "./resources/vehicle";
import { VEHICLES_WITH_DETAILS_INTERVAL } from "constants/constants";

type VehicleModelQueryResult = Pick<
  UseQueryResult<VehicleModel[], Error>,
  "data" | "isLoading" | "isError" | "isSuccess"
>;

export const useVehicle = () => {
  const vehicleApi = new VehicleResource();

  const useGetAllVehiclesWithDetails: () => UseQueryResult<
    VehicleWithDetails[],
    Error
  > = () =>
    useQuery({
      queryKey: ["vehicles", "vehicles-with-details"],
      queryFn: async () => {
        const response = await vehicleApi.getAllVehiclesWithDetails();

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
      refetchInterval: VEHICLES_WITH_DETAILS_INTERVAL,
    });

  const useGetOne: (vehicleId?: string) => UseQueryResult<Vehicle, Error> = (
    vehicleId?: string,
  ) =>
    useQuery({
      queryKey: ["vehicles", vehicleId],
      queryFn: async () => {
        const response = await vehicleApi.getOne(vehicleId);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      },
      enabled: !!vehicleId,
    });

  const useGetAllVehicleModelsByConcessionIds: (
    concessionIds?: number[],
  ) => VehicleModelQueryResult = (concessionIds?: number[]) =>
    useQueries({
      queries: (concessionIds || []).map((concessionId) => ({
        queryKey: ["vehicle", "models", concessionId],
        queryFn: async () => {
          const response = await vehicleApi.getAllVehicleModelsByConcessionId(
            String(concessionId),
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.data || [];
        },
      })),
      combine: (results) => ({
        data: results
          .map((result) => result.data)
          .flat()
          .filter((data) => data !== undefined),
        isLoading: results.some((result) => result.isLoading),
        isError: results.some((result) => result.isError),
        isSuccess: results.every((result) => result.isSuccess),
      }),
    });

  return {
    useGetAllVehiclesWithDetails,
    useGetOne,
    useGetAllVehicleModelsByConcessionIds,
  };
};
