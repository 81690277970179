import { z } from "zod";
import { ENDPOINTS } from "features/pages/Endpoints";
import { IUseApiState, useAsyncApi } from "helpers/useAsyncApi";

const VehicleGpsSchema = z.object({
  accuracy: z.number(),
  latitude: z.number(),
  longitude: z.number(),
  receivedTimestamp: z.number(),
  source: z.string(),
  time: z.string(),
  vehicleId: z.string(),
});

const SocSchema = z.object({
  time: z.number().int(),
  value: z.number(),
});

const VehicleTripSchema = z.object({
  blockId: z.string().optional(),
  endStopId: z.string().optional(),
  endStopName: z.string().optional(),
  lastUpdated: z.string().optional(),
  punctionality: z.number().optional(),
  reinforcementSequenceNumber: z.string().optional(),
  routeId: z.string().optional(),
  tripNumber: z.string().optional(),
  vehicleId: z.string(),
});

const VehicleRouteTypesSchema = z.object({
  type: z.string().optional(),
  features: z
    .array(
      z.object({
        type: z.string().optional(),
        geometry: z
          .object({
            type: z.string().optional(),
            coordinates: z.array(z.array(z.array(z.number()))).optional(),
          })
          .optional(),
        properties: z.object({}).optional(),
      }),
    )
    .optional(),
});

export type VehicleGps = z.infer<typeof VehicleGpsSchema>;
export type Soc = z.infer<typeof SocSchema>;
export type VehicleSoc = { vehicleId: string } & Soc;
export type VehicleTrip = z.infer<typeof VehicleTripSchema>;
export type VehicleRoute = z.infer<typeof VehicleRouteTypesSchema>;

export class VehicleInfoResource {
  public getAllGps = async (): Promise<IUseApiState<VehicleGps[]>> =>
    useAsyncApi<string[], VehicleGps[]>({
      endpoint: ENDPOINTS.QONTROL.VEHICLE.INFO.GPSALLVEHICLES.buildPath({}),
      options: {
        method: "GET",
      },
      schema: z.array(VehicleGpsSchema),
    });

  public getOneGpsByVehicleId = async (
    vehicleId: string,
  ): Promise<IUseApiState<VehicleGps>> =>
    useAsyncApi<null, VehicleGps>({
      endpoint: ENDPOINTS.QONTROL.VEHICLE.INFO.GPS.buildPath({ vehicleId }),
      options: {
        method: "GET",
      },
      schema: VehicleGpsSchema,
    });

  public getAllSocByVehicleIds = async (
    vehicleIds?: string[],
  ): Promise<IUseApiState<Record<string, Record<string, Soc>>>> =>
    useAsyncApi<Record<string, string[]>, Record<string, Record<string, Soc>>>({
      endpoint: ENDPOINTS.QONTROL.VEHICLE.INFO.SOC.buildPath({}),
      options: {
        method: "POST",
      },
      data: vehicleIds?.reduce(
        (acc, vehicleId) => ({ ...acc, [vehicleId]: ["soc"] }),
        {},
      ),
      schema: z.record(z.record(SocSchema)),
    });

  public getAllTripByVehicleIds = async (
    vehicleIds?: string[],
  ): Promise<IUseApiState<VehicleTrip[]>> =>
    useAsyncApi<string[], VehicleTrip[]>({
      endpoint: ENDPOINTS.QONTROL.VEHICLE.INFO.TRIP.buildPath({}),
      options: {
        method: "POST",
      },
      data: vehicleIds,
      schema: z.array(VehicleTripSchema),
    });

  public useGetRouteInfoForVehicle = async (
    vehicleId: string,
  ): Promise<IUseApiState<VehicleRoute>> =>
    useAsyncApi<string[], VehicleRoute>({
      endpoint: ENDPOINTS.QONTROL.VEHICLE.INFO.ROUTE.buildPath({
        vehicleId,
      }),
      options: {
        method: "GET",
      },
      schema: VehicleRouteTypesSchema,
    });
}
